import React, { Component } from 'react';
import { getAll, deleteChart, updateOrder } from '../../services/charts';
import { Link } from "react-router-dom";
import { Button, Table } from 'react-bootstrap';
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit,
    faPlusCircle,
    faTrashAlt,
    faEye
} from "@fortawesome/free-solid-svg-icons/index";
import { MyTooltip } from '../../../../components/MyTooltip';
import { toast } from 'react-toastify';
import { DeleteModal } from '../../../../components/DeleteModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


class ChartList extends Component {

    constructor(props) {

        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);

        this.state = {
            data: [],
            blocking: true,
            editInput: {},
            editing: {},
            deleting: [],
            ordering: [],
            showModal: false,
        };
    }

    async componentDidMount() {

        await this.fetchChartsWithOrdering();
    }

    async fetchChartsWithOrdering() {

        try {

            const dashboardId = this.props.dashboardId;

            let chartsFetch = await getAll(dashboardId);

            let dataObj = chartsFetch.data;
            let order = dataObj.map((el) => {
                return {
                    id: el.id,
                    order: el.order
                }
            })

            this.setState({
                data: dataObj,
                ordering: order,
                blocking: false
            });
        }
        catch (ex) {
            this.setState({ blocking: false })
        }
    }


    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    deleteRecord = async () => {

        let id = this.state.deleting.id;

        try {

            await deleteChart(id);
            const data = [...this.state.data.filter(x => x.id !== id)];

            this.setState({
                data: data,
                showModal: false
            })

            toast.info("Chart deleted successfully.")
        }
        catch (error) {

            if (error && error.data && error.data.error) {
                toast.error(error.data.error);
            }
            this.setState({ blocking: false });
        }
    }

    onDragEnd = async result => {

        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        let reordered = this.state.ordering.slice();

        reordered.map((value) => {

            if (value.order >= destination.index && value.order < source.index) {
                value.order = value.order + 1;
                return;
            }

            if (value.order <= destination.index && value.order > source.index) {
                value.order = value.order - 1;
                return;
            }


            if (value.order == source.index) {
                value.order = destination.index;
                return;
            }

        });

        let data = [...this.state.data]
        let dataReordered = [...this.state.data]

        dataReordered.splice(source.index, 1);
        dataReordered.splice(destination.index, 0, data[draggableId - 1]);

        try {

            let dashboardId = this.props.dashboardId;

            let newOrder = dataReordered.map((el, index) => {
                return {
                    id: el.id,
                    order: index
                }
            })

            await updateOrder(dashboardId, newOrder)

            this.setState({ data: dataReordered, ordering: reordered });
        }
        catch (ex) { }
    };

    modalShow(id) {

        let data = this.state.data.slice();
        let deleting = [];

        data.map((value) => {
            if (value.id == id) {
                deleting.category = 'chart';
                deleting.title = value.displayName;
                deleting.id = value.id;
            }
        });
        this.setState({ showModal: true, deleting: deleting })
    }

    renderBodyTable() {

        const data = this.state.data;

        return data.map((item, index) => {

            const keyNumber = parseInt(index);
            const id = item.id;
            const title =
                <Link
                    to={{
                        pathname: `/admin/charts/edit/${id}`,
                    }}>
                    <MyTooltip tooltip={item.url}>
                        {item.displayName}
                    </MyTooltip>
                </Link>
            const refreshFrequency = item.refreshFrequencyInMinutes == 0
                ? "0 - Live"
                : item.refreshFrequencyInMinutes;
            let tags = item.tags.sort((a, b) => a.id - b.id).slice();

            tags = tags.length > 0
                ? tags.map(x => x.name).join(', ')
                : '-';

            const icon = <img src={`/api/staticresources/icons/${item.iconName}`}
                style={{ width: 30, height: 30 }} />;

            const action =
                <span>
                    <MyTooltip tooltip="Edit">
                        <Link
                            to={{
                                pathname: `/admin/charts/edit/${id}`
                            }}><Button
                                variant="outline-info" size="sm">
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </Link>
                    </MyTooltip>
                    <MyTooltip tooltip="Delete">
                        <Button onClick={() => this.modalShow(id)} variant="outline-danger" size="sm">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </MyTooltip>
                    <MyTooltip tooltip="Go to View">
                        <Link
                            to={{
                                pathname: `/engagements/${item.engagementId}/dashboards/${item.dashboardId}/charts/${item.id}`
                            }}><Button
                                variant="outline-primary" size="sm">
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                    </MyTooltip>

                </span>;


            return (
                <Draggable key={keyNumber + 1} draggableId={keyNumber + 1} index={keyNumber}>
                    {(provided) => (


                        <tr key={keyNumber + 1} ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <td>{id}</td>
                            <td>{title}</td>
                            <td>{refreshFrequency}</td>
                            <td>{item.openInNewTab ? "Yes" : "No"}</td>
                            <td>{tags}</td>
                            <td>{icon}</td>
                            <td style={{ minWidth: '100px' }}>{action}</td>
                        </tr>
                    )}
                </Draggable>
            );
        });
    }

    render() {

        return (
            <div>
                <div>
                    <DeleteModal show={this.state.showModal}
                        deleting={this.state.deleting}
                        onconfirmdelete={this.deleteRecord}
                        onHide={() => this.setState({ showModal: false })} />
                    <BlockUIContainer blocking={this.state.blocking}>
                        <br />
                        <DragDropContext
                            onDragEnd={this.onDragEnd}
                        >
                            <Link
                                to={{
                                    pathname: `/admin/charts/${this.props.dashboardId}/create`,
                                }}>
                                <Button variant="outline-primary" size="sm">
                                    <FontAwesomeIcon icon={faPlusCircle} /> Add new Chart
                                </Button></Link>
                            <br />
                            <br />
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Chart</th>
                                        <th>Refresh frequency (minutes)</th>
                                        <th>Open in a New Tab</th>
                                        <th>Assigned tags</th>
                                        <th>Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <Droppable droppableId="engagements">
                                    {(provided) => (

                                        <tbody ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {this.renderBodyTable()}

                                            {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </Table>
                        </DragDropContext>
                    </BlockUIContainer>
                </div>
            </div>
        );
    }
}


export { ChartList };