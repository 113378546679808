import * as React from 'react';
import { connect } from 'react-redux';

import styles from './DashboardsListComponent.module.scss';
import { DashboardTile } from '../dashboardTile/DashboardTile';
import { getAll } from '../../services/dashboards';
import { getById } from '../../services/engagements';
import { colors } from '../../../shared/shared'
import * as strHelper from '../../../../common/stringHelper';
import { NoEntries } from '../../../../components/NoEntriesComponent';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { breadcrumbsRequest } from '../../../../redux/actions/breadcrumbs';
import { selectDashboardTags } from '../../../../redux/actions/selectDashboardTags';
import { searchDashboardRequest } from '../../../../redux/actions/search';
import { selectToggleModeRequest } from '../../../../redux/actions/selectToggleMode';
import { setEngagementIconRequest } from '../../../../redux/actions/setEngagementIcon';

class DashboardsListComponent extends React.Component {

    constructor(props) {

        super(props)
        this.state = {

            isLoaded: false,
            data: []
        }
    }

    async componentDidMount() {
        
        this.props.resetTags();
        const engagementId = this.props.match.params.engagementId;
        
        try {                                 
            const values = await Promise.all([this.fetchDashboards(engagementId), this.fetchEngagement(engagementId)]);
            this.setState({
                isLoaded: true,
                data: values[0],
                engagement: values[1]
            })
            const { displayName } = this.state.engagement;
            this.props.changeBreadcrumbs({engagementName: displayName, dashboardName: "", chartName: ""})
            this.props.setEngagementIcon(this.state.engagement.iconName);
        }
        catch (ex) {
            this.setState({ isLoaded: true })
        }
    }

    componentWillUnmount() {
        this.props.resetSearchDashboard();
    }

    async fetchDashboards(engagementId) {

        let dashboardsFetch = await getAll(engagementId)
        return dashboardsFetch.data;
    }


    async fetchEngagement(engagementId) {

        const engagementFetch = await getById(engagementId);
        return engagementFetch.data;
    }

    navigateToCharts = (dashboardId) => {
        this.props.selectToggleMode(false);
        this.props.history.push(`${this.props.match.params.engagementId}/dashboards/${dashboardId}`)
    }

    renderDashboards() {

        let data = [...this.state.data];
        if (this.props.searchText != '' && this.props.searchText != undefined) {
            data = data.filter(x => strHelper.containsText(x, this.props.searchText));
        }

        if (data.length === 0) {
            return <NoEntries />
        }
       
        return data.map((el, index) => {

            let color = colors[index % colors.length];
            
            return <DashboardTile
                key={el.id}
                id={el.id}
                rectColor={color}
                title={el.displayName}
                chartsCount={el.chartsCount}
                onClick={() => this.navigateToCharts(el.id)}
            />
        })
    }

    render() {
        if (!this.state.isLoaded)
            return <LoadingIndicator />

        if (this.state.data.length == 1)
            return <Redirect to={`/engagements/${this.props.match.params.engagementId}/dashboards/${this.state.data[0].id}`} />

        return (
            <div id={styles['dashboards-container']}>
                <h4>{this.state.engagement.displayName.toUpperCase()} DASHBOARDS</h4>
                <br />
                <div>
                    {this.renderDashboards()}
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        searchText: state.searchDashboard.searchText,
        // iconName: state.engagementIcon
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetTags: () => {
            dispatch(selectDashboardTags([]))
        },
        resetSearchDashboard: () => {
            dispatch(searchDashboardRequest(""))
        },
        changeBreadcrumbs: (breadcrumbs) => {
            dispatch(breadcrumbsRequest(breadcrumbs))
        },
        selectToggleMode: (mode) => {
            dispatch(selectToggleModeRequest(mode))
        },
        setEngagementIcon: (iconName) => {
            dispatch(setEngagementIconRequest(iconName))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardsListComponent);