/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withRouter, NavLink } from "react-router-dom";
import webAppConfig from '../../../config';
import authenticationTypes from '../../../common/const/authenticationTypes';
import PropTypes from "prop-types";

import styles from './AdminPanelMaster.scss';

class AdminPanelMasterComponent extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isSystemAdmin: false,
            isEngagementAdminOnly: false,
            accessToComponent: false,
        }
    }

    async componentDidMount() {
        let userInfo = this.props.user
        if (userInfo.isSystemAdmin) {
            this.setState({ isSystemAdmin: true });
        } else if (Object.entries(userInfo.accessToEngagements).length > 0){
            this.setState({ isEngagementAdminOnly: true });
        }
        else {
            this.props.history.push('/admin/engagements');
        }
    }

    engagementsClicked = (e) => {
        e.preventDefault();
        this.props.history.push('/admin/engagements');
    }

    systemAdminClicked = (e) => {
        e.preventDefault();
        this.props.history.push('/admin/systemAdmins');
    }

    userAccountsClicked = (e) => {
        e.preventDefault();
        this.props.history.push('/admin/userAccounts');
    }

    render() {

        return (
            <div>
                <div   style={{ paddingLeft: 50 }}>
                    <a className="tile" href="" onClick={this.engagementsClicked}>
                        Go to Engagements
                    </a>
                    {
                        this.state.isSystemAdmin && webAppConfig.authenticationType === authenticationTypes.Custom
                            ?
                            <a className="tile"  href="" onClick={this.userAccountsClicked}>
                                Manage User Accounts
                            </a>
                            : null
                    }
                    {
                        this.state.isSystemAdmin ?
                            <a className="tile" href="" onClick={this.systemAdminClicked}>
                                Manage System Administrators
                            </a>
                            : null
                    }
                    {
                        this.state.isSystemAdmin ?
                            <NavLink to={'/admin/eventTracker'} className="tile" >Go to Event Tracker</NavLink>
                            :
                            null
                    }
                    {
                        this.state.isSystemAdmin ?
                            <NavLink to={'/admin/analytics/table'} className="tile" >Go to Analytics Table</NavLink>
                            :
                            null
                    }
                    {
                        this.state.isEngagementAdminOnly ?
                            <NavLink to={'/admin/analytics/table'} className="tile" >Go to Analytics Table</NavLink>
                            :
                            null
                    }
                    {
                        this.state.isSystemAdmin ?
                            <NavLink to={'/admin/analytics/dashboard/daily'} className="tile" >Go to Analytics Dashboard Daily</NavLink>
                            :
                            null
                    }
                    {
                        this.state.isSystemAdmin ?
                            <NavLink to={'/admin/analytics/dashboard/monthly'} className="tile" >Go to Analytics Dashboard Monthly</NavLink>
                            :
                            null
                    }
                    {
                        this.state.isSystemAdmin ?
                            <NavLink to={'/admin/chartUsefulStatistics/table'} className="tile" >Go to Chart Useful/NotUseful Statistics</NavLink>
                            :
                            null
                    }
                    {
                        this.state.isSystemAdmin ?
                            <NavLink to={'/admin/mobileSettings'} className="tile" >Go to Mobile Settings</NavLink>
                            :
                            null
                    }
                    {
                        this.state.isSystemAdmin ?
                            <NavLink to={'/admin/reportIssue'} className="tile" >Go to Report Issues</NavLink>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}

export const AdminPanelMaster = withRouter(AdminPanelMasterComponent);
