import React, { Component } from 'react';
import { getAll, add, remove } from "../../services/tags";
import { Button, Table, Form } from 'react-bootstrap';
import { faSave, faPlusCircle, faWindowClose, faTrashAlt } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { toast } from "react-toastify";

class TagList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            blocking: false,
            isAdding: false,
            newItem: {
                name: '',
                dashboardId: 0,
            },
            data: [],
        };
    }

    async componentDidMount() {
        
        await this.fetchTags();
    }

    async fetchTags() {

        this.setState({ blocking: true});
        try {
            
            let result = await getAll(this.props.dashboardId);
            this.setState({
                data: result.data,
                blocking: false
            })
        }
        catch (ex) { }
    }

    addNewTag = async () => {

        let newItem = this.state.newItem;
        newItem.dashboardId = this.props.dashboardId;

        let tagAlreadyExists = this.state.data.find(x => x.name.toUpperCase() == newItem.name.toUpperCase());
        if (tagAlreadyExists != null) {
            toast.warn("Tag already exists");
            return;
        }

        try {

            let result = await add(newItem);
            const data = [...this.state.data, result.data];

            this.setState({
                blocking: false,
                isAdding: false,
                data: data,
                newItem: {
                    name: '',
                    dashboardId: 0
                }
            })

            toast.info("Tag created successfully.");
        }
        catch(ex) {
            this.setState({ blocking: false });
        }
    }

    removeTag = async (id) => {

        this.setState({ blocking: true });
        try {

            await remove(id);

            const newData = [...this.state.data.filter(x => x.id !== id)];
            this.setState({ data: newData, blocking: false });

            toast.info("Tag removed successfully.");
        }
        catch(error) {
            this.setState({ blocking: false });

            if (error && error.data && error.data.error) {
                toast.error(error.data.error);
            }
        }
    }

    onNameChange = (name) => {

        let newItem = this.state.newItem;
        newItem.name = name;
        this.setState({ newItem: newItem });
    }

    renderAddNewItem() {

        if (!this.state.isAdding) {
            return null;
        }
        return (
            <tr>
                <td>
                    <Form.Control type="text" placeholder="Type a tag name..." size="sm"
                        onChange={e => { this.onNameChange(e.target.value); }}
                        value={this.state.newItem.name}>
                    </Form.Control>
                </td>
                <td>
                    <Button size="sm" onClick={() => this.addNewTag()} variant="outline-success">
                        <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button size="sm" onClick={() => this.setState({
                        isAdding: false, newItem: {
                            name: '',
                            dashboardId: 0,
                        },
                    })} variant="outline-primary">
                        <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                </td>
            </tr>
        );
    }

    renderBodyTable() {

        return (
            this.state.data.map((listValue, index) => {
                return (
                    <tr key={index}>
                        <td>{listValue.name}</td>
                        <td>
                            <Button size="sm" onClick={() => this.removeTag(listValue.id)} variant="outline-danger">
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </td>
                    </tr>
                );
            })
        );
    }

    render() {
        
        return (
            <BlockUIContainer blocking={this.state.blocking}>
                <br />
                <Button variant="outline-primary" size="sm" disabled={this.state.data.length >= 4} onClick={() => this.setState({ isAdding: true })}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Add new tag
                </Button>
                <br />
                <br />
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Tag</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderAddNewItem()}
                        {this.renderBodyTable()}
                    </tbody>
                </Table>
            </BlockUIContainer>
        );
    }
}

export { TagList };
