import { SET_ENGEGEMENT_ICON } from '../constants/ActionTypes';

const initState = null;

const setEngagementIconReducer = (state = initState, action) => {

    switch(action.type){
        case SET_ENGEGEMENT_ICON:
            return action.engagementIcon;
        default:
            return state;
    }
}

export default setEngagementIconReducer;