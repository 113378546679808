import React, { Component } from 'react';
import { getAll, assignToEngagement, unassignFromEngagement } from "../../services/engagementUsers";
import { Administrator, ReadonlyUser, getRoleById, ROLES } from '../../../../auth/userRoles';
import { Button, Table, Form, ToggleButton } from 'react-bootstrap';
import { faClipboard, faEdit, faSave, faPlusCircle, faWindowClose, faTrashAlt, faFileImport } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { toast } from "react-toastify";
import { MyTooltip } from '../../../../components/MyTooltip';
import { getErrorMessage } from '../../services/common';
import { UploadUsersFromCsvComponent } from '../../common/UploadUsersFromCsvComponent';
import moment from 'moment';

const PAGE_SIZE = 10;

class AccessToEngagementComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            editing: [],
            changes: {},
            isAdding: false,
            newItem: {
                userLogin: '',
                engagementId: 0,
                roleId: ROLES[ReadonlyUser]
            },
            users: [],
            pageOfItems: [],
            showImportFromCsv: false,
            hideImportButton: false
        };
    }

    async componentDidMount() {
        await this.refreshData();
    }

    toggleEdit = (userLogin) => {

        const { editing, changes } = this.state;
        editing[userLogin] = !editing[userLogin];
        changes[userLogin] = {};
        this.setState({ editing });
    }

    saveChanges = async (userLogin) => {
        this.setState({ blocking: true });

        const rowIndex = this.state.users.findIndex(x => x.userLogin.toUpperCase() === userLogin.toUpperCase());
        const users = [...this.state.users];
        const { changes } = this.state;
        try {
            await assignToEngagement(users[rowIndex].engagementId, changes[userLogin])
            users[rowIndex].roleId = changes[userLogin].roleId;
            users[rowIndex].lastModifiedDate = changes[userLogin].lastModifiedDate;
            this.toggleEdit(users[rowIndex].userLogin);
            this.setState({ blocking: false, users: users });

            toast.info("User assigned successfully.");
        }
        catch (ex) {
            this.setState({ blocking: false });
        }
        this.refreshData();
    }

    assignNewUser = async () => {
        let newItem = this.state.newItem;
        newItem.engagementId = this.props.engagementId;

        let userAlreadyExists = this.state.users.some(x => x.userLogin.toUpperCase() == newItem.userLogin.toUpperCase());
        if (userAlreadyExists) {
            toast.warn("User already exists");
            return;
        }

        try {

            await assignToEngagement(newItem.engagementId, newItem)
            const users = [...this.state.users, newItem];
            this.setState({
                blocking: false, isAdding: false, users: users,
                newItem: {
                    userLogin: '',
                    engagementId: 0,
                    roleId: ROLES[ReadonlyUser]
                },
            });

            toast.info("User assigned successfully.");
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    unassignUser = async (userLogin) => {

        this.setState({ blocking: true });

        const toDelete = this.state.users.find(x => x.userLogin === userLogin);
        if (toDelete === null)
            return;

        try {

            await unassignFromEngagement(toDelete.engagementId, toDelete);
            const newData = this.state.users.filter(x => x !== toDelete);
            this.setState({
                users: newData,
                blocking: false
            })

            toast.info("User unassigned successfully.");
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    existingItemOnRoleChange = (newRoleId, userLogin) => {
        const { changes } = this.state;

        const editedItem = this.state.users.find(x => x.userLogin === userLogin);
        changes[userLogin] = Object.assign({}, editedItem);
        changes[userLogin].roleId = newRoleId;

        this.setState({ changes: changes });
    }

    newItemOnRoleChange = (newRoleId) => {
        let newItem = this.state.newItem;
        newItem.roleId = newRoleId;
        this.setState({ newItem: newItem });
    }

    newItemOnUserLoginChange = (userLogin) => {
        let newItem = this.state.newItem;
        newItem.userLogin = userLogin.toUpperCase();
        this.setState({ newItem: newItem });
    }

    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }

    refreshData = async () => {
        try {
            this.setState({ blocking: true });
            let result = await getAll(this.props.engagementId)
            this.setState({ users: result.data, blocking: false, showImportFromCsv: false })
        }
        catch (ex) { }
    }

    fileSelected = () => {
        this.setState({ hideImportButton: true })
    }

    uploadDone = async () => {
        await this.refreshData();
        this.setState({ showImportFromCsv: false, hideImportButton: false })
    }

    uploadFailed = async () => {
        this.setState({ showImportFromCsv: false, hideImportButton: false })
    }

    renderAddNewItem() {
        if (!this.state.isAdding) {
            return (null);
        }
        return (
            <tr>
                <td></td>
                <td>
                    <Form.Control type="text" placeholder="Type a username..." size="sm"
                        onChange={e => { this.newItemOnUserLoginChange(e.target.value); }}
                        value={this.state.newItem.userLogin}>
                    </Form.Control>
                </td>
                <td>
                    <Form.Control as="select" size="sm" onChange={e => { this.newItemOnRoleChange(e.target.value); }} value={this.state.newItem.roleId}>
                        <option value={ROLES[Administrator]}>Administrator</option>
                        <option value={ROLES[ReadonlyUser]}>ReadonlyUser</option>
                    </Form.Control>
                </td>
                <td></td>
                <td></td>
                <td>
                    <MyTooltip tooltip="Save">
                        <Button disabled={this.state.newItem.userLogin == ''} onClick={() => this.assignNewUser()} variant="outline-success" size="sm">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </MyTooltip>
                    <MyTooltip tooltip="Cancel">
                        <Button onClick={() => this.setState({
                            isAdding: false, newItem: {
                                userLogin: '',
                                engagementId: 0,
                                roleId: ROLES[ReadonlyUser]
                            },
                        })} variant="outline-primary" size="sm">
                            <FontAwesomeIcon icon={faWindowClose} />
                        </Button>
                    </MyTooltip>
                </td>
            </tr>
        );
    }

    checkDefaultDate(createdDate) {
        return createdDate !== "0001-01-01T00:00:00" ? moment.utc(createdDate).local().format("DD-MM-YYYY HH:mm:ss") : "";
    }

    renderBodyTable() {
        
        return (
            this.state.users.map((listValue, index) => {
                
            let createdDate = this.checkDefaultDate(listValue.createdDate);
            let lastModifiedDate = listValue.lastModifiedDate ? moment.utc(listValue.lastModifiedDate).local().format("DD-MM-YYYY HH:mm:ss") : ""


                if (this.state.editing[listValue.userLogin]) {
                    return (                        
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{listValue.userLogin}</td>
                            <td>
                                <Form.Control as="select" size="sm"
                                    value={this.state.changes[listValue.userLogin].roleId ? this.state.changes[listValue.userLogin].roleId : listValue.roleId}
                                    onChange={e => { this.existingItemOnRoleChange(e.target.value, listValue.userLogin); }}>
                                    <option value={ROLES[Administrator]}>Administrator</option>
                                    <option value={ROLES[ReadonlyUser]}>ReadonlyUser</option>
                                </Form.Control>
                            </td>
                            <td>{createdDate}</td>
                            <td>{lastModifiedDate}</td>
                            <td>
                                <MyTooltip tooltip="Save">
                                    <Button size="sm" onClick={() => this.saveChanges(listValue.userLogin)} variant="outline-success">
                                        <FontAwesomeIcon icon={faSave} />
                                    </Button>
                                </MyTooltip>
                                <MyTooltip tooltip="Cancel">
                                    <Button size="sm" onClick={() => this.toggleEdit(listValue.userLogin)} variant="outline-primary">
                                        <FontAwesomeIcon icon={faWindowClose} />
                                    </Button>
                                </MyTooltip>

                            </td>
                        </tr>
                    );
                }

                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{listValue.userLogin}</td>
                        <td>{getRoleById(listValue.roleId)}</td>
                        <td>{createdDate}</td>
                        <td>{lastModifiedDate}</td>
                        <td>
                            <MyTooltip tooltip="Edit">
                                <Button size="sm" onClick={() => this.toggleEdit(listValue.userLogin)} variant="outline-info">
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            </MyTooltip>
                            <MyTooltip tooltip="Delete">
                                <Button size="sm" onClick={() => this.unassignUser(listValue.userLogin)} variant="outline-danger">
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </MyTooltip>
                        </td>
                    </tr>
                );

            })
        );
    }

    render() {
        return (
            <BlockUIContainer blocking={this.state.blocking}>
                <br />
                <div className="d-flex">
                    <div>
                        <Button variant="outline-primary mr-2" size="sm" onClick={() => this.setState({ isAdding: true })}>
                            <FontAwesomeIcon icon={faPlusCircle} /> Add new user
                        </Button>
                        <MyTooltip tooltip="CSV Template: User,Role USER_ID,ReadonlyUser USER_ID,Administrator">
                            <Button variant="outline-success" size="sm" hidden={this.state.hideImportButton}
                                onClick={() => this.setState({ showImportFromCsv: true })}>
                                <FontAwesomeIcon icon={faFileImport} /> Import from CSV
                            </Button>
                        </MyTooltip>
                        {this.state.showImportFromCsv ?
                            <UploadUsersFromCsvComponent
                                engagementId={this.props.engagementId}
                                fileSelected={this.fileSelected}
                                uploadDone={this.uploadDone}
                                uploadFailed={this.uploadFailed}
                                chooseFile={this.state.showImportFromCsv}
                            />
                            : null
                        }
                    </div>
                    <div className="ml-auto">
                        <p>Total Count: <b>{this.state.users.length}</b></p>
                    </div>
                </div>
                <br />
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>User</th>
                            <th>Role</th>
                            <th>Created</th>
                            <th>Last modified</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderAddNewItem()}
                        {this.renderBodyTable()}
                    </tbody>
                </Table>
                {/* <Pagination items={this.state.users} onChangePage={this.onChangePage} pageSize={PAGE_SIZE} /> */}
            </BlockUIContainer>
        );
    }
}

export { AccessToEngagementComponent };
