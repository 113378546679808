import React, { Component } from 'react';
import { getList, exportReportIssuesToCsv } from '../../services/reportIssue';
import { saveAs } from 'file-saver';
import  Table from './Table';

class ReportIssueListcomponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }

    async componentDidMount() {

       await this.fetchEvents()

    }

    async fetchEvents() {

        let result = (await getList()).data;
        this.setState({
            data: result
        })
    }

    exportToCsv = async () => {
        try {
            const response = await exportReportIssuesToCsv(this.state.data);
            saveAs(response.data, "Rapport-Events.csv");
        }
        catch (error) {
            this.setState({ blocking: false });
        }
    }

    render() {
        return (            
            <div style={{ marginBottom: 20 }}>
                <h4 style={{ color: 'gray', fontSize: 18 }}>Report Issues</h4>
                <Table />
            </div>
        )
    }
}

export { ReportIssueListcomponent }