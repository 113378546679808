import { httpClient } from '../../../common/httpClient';

const getAll = (dashboardId) => httpClient({
    url: `api/users/dashboard/${dashboardId}`,
    method: "get",
});

const assignToDashboard = (dashboardId, userDashboard) => httpClient({
    url: `api/users/dashboard/${dashboardId}/assign`,
    method: "post",
    data: userDashboard,
    headers: {
        "Content-Type": "application/json",
    }
})

const unassignFromDashboard = (dashboardId, userDashboard) => httpClient({
    url: `api/users/dashboard/${dashboardId}/unassign`,
    method: "delete",
    data: userDashboard,
    headers: {
        "Content-Type": "application/json",
    }
})

const uploadUsersToDashboard = (data) => httpClient({
    url: `api/users/dashboard/uploadUsers`,
    method: "post",
    data: data,
    headers: {
        "Content-Type": "multipart/form-data",
    }
})

export { getAll, assignToDashboard, unassignFromDashboard, uploadUsersToDashboard };

