export const PeriodConstants = {
    LastDay: "last-day",
    LastWeek: "last-week",
    LastMonth: "last-month",
    Custom: "custom",
    All: "all"
}

export const PeriodMapping = {
    "last-day": new Date(Date.now() + -1 * 24 * 3600 * 1000),
    "last-week": new Date(Date.now() + -7 * 24 * 3600 * 1000),
    "last-month": new Date(Date.now() + -29 * 24 * 3600 * 1000),
    "custom": new Date(Date.now() + -999 * 24 * 3600 * 1000),
    "all": new Date(Date.now() + -999 * 24 * 3600 * 1000),
};

