import * as types from '../constants/ActionTypes';

export const searchEngagementRequest = (searchText) => (
    {
        type: types.SEARCH_ENGAGEMENTS_REQUEST,
        searchText: searchText
    }
)

export const searchDashboardRequest = (searchText) => (
    {
        type: types.SEARCH_DASHBOARDS_REQUEST,
        searchText: searchText
    }
)

export const searchChartRequest = (searchText) => (
    {
        type: types.SEARCH_CHARTS_REQUEST,
        searchText: searchText
    }
)
