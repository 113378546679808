import { httpClient } from '../../../common/httpClient';

const getAll = (engagementId) => httpClient({
    url: `api/dashboards/${engagementId}/items`,
    method: "get",
});


const getById = (engagementId) => httpClient({
    url: `api/dashboards/${engagementId}`,
    method: "get"
})
export {getAll, getById}
