import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import axios from 'axios';
import webAppConfig from '../config'
import authenticationTypes from '../common/const/authenticationTypes'

export class CAMAuthService {

    static accessToken = 'access_token';
    static expiresAt = 'expires_at';
    static pathToRedirect = 'path_to_redirect';
    static CAMExpireTime = 3600 * 6 * 1000; // 6hours- in miliseconds


    login() {
        localStorage.setItem(CAMAuthService.pathToRedirect, window.location.pathname)
        
        const url = `${window.location.origin}/CAMAuth/redirect?url=${window.location.origin}${window.location.pathname}`;
        window.location.href = url;
    }

    renewToken() {
        // To implement.
    }

    logout() {
        localStorage.removeItem(CAMAuthService.accessToken);
        localStorage.removeItem(CAMAuthService.expiresAt);
        localStorage.remove('scopes');
    }

    completeAuthentication(codeVerifier) {
        const codeChallenge = generateCodeChallenge(codeVerifier);

        return axios.post(`${window.location.origin}/CAMAuth/token`,
            {
                codeChallenge,
                codeVerifier
            })
    }

    isAuthenticated() {
        // if (this.isDev()) {
          //  return true;
        // }

        const accessToken = localStorage.getItem(CAMAuthService.accessToken);
        //const accessToken = '36c1b3e99ed8d17279dbcf096625b8b81e6d2cd329afd1723c829c672f941997'

        if (!accessToken)
            return false;

        return this.tokenExpired();
    }

    setSession(data) {
        return Promise.resolve().then(function () {
            // let expiresAt = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());
            let expiresAt = new Date().getTime() + 100000;
            localStorage.setItem(CAMAuthService.accessToken, data.access_token);
            localStorage.setItem(CAMAuthService.expiresAt, expiresAt);
            // localStorage.setItem('scopes', JSON.stringify(authResult.scope || ""));
        });
    }

    tokenExists() {
        return localStorage.getItem(CAMAuthService.accessToken) != null && localStorage.getItem(CAMAuthService.expiresAt) != null;
    }

    tokenExpired() {
        if (!this.tokenExists()) {
            return true;
        }
        let expiresAt = JSON.parse(localStorage.getItem(CAMAuthService.expiresAt));
        return new Date().getTime() < expiresAt;
    }

    getAuthorizationHeaderValue() {
        const accessToken = localStorage.getItem(CAMAuthService.accessToken);
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return `Bearer ${accessToken}`;
    }

   

    isDev() {
        if (process.env.NODE_ENV === 'development') {
            if (this.tokenExpired()) {
                localStorage.setItem(CAMAuthService.accessToken, generateRandomString(35));
                let expiresAt = JSON.stringify((3599 * 1000) + new Date(2040, 12, 1).getTime());
                localStorage.setItem(CAMAuthService.expiresAt, expiresAt);
            }
            return true;
        }
        return false;
    }

   

    
}

function generateRandomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

// MIMMICING CAM CLIENT! 
function generateCodeChallenge(code_verifier) {
    return sha256(code_verifier).toString(Base64).replaceAll("+", "-").replaceAll("/","_").replace(/=+$/, '')
}