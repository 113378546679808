
const isEngagementRoute = (route) => {
    return route === '/engagements' && !isDashboardRoute(route);
}

const isDashboardRoute = (route) => {
    return route.indexOf('/engagements/') != -1
        && !isChartListRoute(route)
        && !isChartDetailsRoute(route);
}

const isChartListRoute = (route) => {
    return route.indexOf('/dashboards/') != -1
        && !isChartDetailsRoute(route);
}

const isChartDetailsRoute = (route) => {
    return route.indexOf('/charts/') != -1;
}

const getDashboardsRoute = (route) => {
    let allRouteItems = route.split('/');
    let destinationRouteItems = allRouteItems.slice(0, allRouteItems.indexOf('engagements') + 2);
    let destinationRoute = destinationRouteItems.join('/');

    return destinationRoute;
}

const getChartsListRoute = (route) => {
    let allRouteItems = route.split('/');
    let destinationRouteItems = allRouteItems.slice(0, allRouteItems.indexOf('dashboards') + 2);
    let destinationRoute = destinationRouteItems.join('/');

    return destinationRoute;
}

const getDashboardId = (route) => {
    let allRouteItems = route.split('/');
    let id = allRouteItems.slice(allRouteItems.indexOf('engagements') + 1, allRouteItems.indexOf('engagements') + 2);

    return id[0];
}

const getEngagementId = (route) => {
    let allRouteItems = route.split('/');
    let id = allRouteItems.slice(allRouteItems.indexOf('dashboards') + 1, allRouteItems.indexOf('dashboards') + 2);

    return id[0];
}

export {
    isEngagementRoute, isDashboardRoute, isChartListRoute, isChartDetailsRoute,
    getChartsListRoute, getDashboardsRoute,
    getDashboardId, getEngagementId
}