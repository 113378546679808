import { httpClient } from '../../../common/httpClient';



const getAll = (dashboardId) => httpClient({
    url: `api/tags/${dashboardId}/items`,
    method: "get",
});

const add = (newTag) => httpClient({
    url: `api/tags`,
    method: "post",
    data: newTag,
    headers: {
        "Content-Type": "application/json",
    }
})

const remove = (id) => httpClient({
    url: `api/tags/${id}`,
    method: "delete",
    headers: {
        "Content-Type": "application/json",
    }
})

export { getAll, add, remove }
