import { httpClient } from '../../../common/httpClient';

const DEFAULT_CONTENT_TYPE = "application/json";
const getAnalyticEntriesForExport = (viewModel) => httpClient({
    url: `api/analytics/export`,
    method: "post",
    data: viewModel,
    headers: {
        'Content-Type': DEFAULT_CONTENT_TYPE,
        'Accepts': DEFAULT_CONTENT_TYPE,
    }
});

const getDailyUsageTrend = (viewModel) => httpClient({
    url: `api/analytics/dashboard/dailyUsageTrend`,
    method: "post",
    data: viewModel,
    headers: {
        'Content-Type': DEFAULT_CONTENT_TYPE,
        'Accepts': DEFAULT_CONTENT_TYPE,
    }
});

const getMonthlyUsageTrend = (viewModel) => httpClient({
    url: `api/analytics/dashboard/monthlyUsageTrend`,
    method: "post",
    data: viewModel,
    headers: {
        'Content-Type': DEFAULT_CONTENT_TYPE,
        'Accepts': DEFAULT_CONTENT_TYPE,
    }
});

export {
    getAnalyticEntriesForExport,
    getDailyUsageTrend,
    getMonthlyUsageTrend
}