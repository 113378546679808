import React, { Component } from 'react';

class NoEntries extends Component {

    render() {
        return (
            <h4 style={{ color: 'gray'}}>There are no entries</h4>
        );
    }
}

export { NoEntries };