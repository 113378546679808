import { BREADCRUMBS_REQEST } from '../constants/ActionTypes';

const initState = {
    payload: null
}

const breadcrumbsReducer = (state = initState, action) => {
    switch(action.type) {
        case BREADCRUMBS_REQEST:
            return {...state, payload: action.payload}
        default:
            return state;
    }
}

export default breadcrumbsReducer;