import { TOGGLE_SIDEBAR_OPTION } from '../constants/ActionTypes'

const initState = 'close';

const toggleSidebarOptionReducer = (state = initState, action) => {

    switch(action.type){
        case TOGGLE_SIDEBAR_OPTION:
            return action.mode;  
        default:
            return state;
    }

}

export default toggleSidebarOptionReducer;