import * as React from 'react';
import styles from '../SideBarComponent.module.css';
import CancelButton from '../common/CancelButton';
import { connect } from 'react-redux';
import { toggleSidebarOptionRequest } from '../../../redux/actions/toggleSidebarOption';

class NotificationsComponent extends React.Component {

    renderNotifications() {

        const notifications = this.props.data;

        if (notifications.length <= 0)
            return <p className={styles['notification-none']}> You don't have any notifications</p>

        return notifications.map((el, index) => {

            let dateString = el.createdAt.split('T')[0];
            return (
                <div className={styles['notification-wrapper']} key={index}>
                    <h4>{el.title}</h4>
                    <p>{el.message}</p>
                    <p className={styles['notification-date']}>{dateString}</p>
                </div>
            )
        })
    }

    closeSideBar = async () => {
        this.props.toggleSidebarOption('close');
    }

    render() {

        return (
            <div>
                <h5 style={{ textAlign: "left" }}>
                    Notifications
                </h5>
                {this.renderNotifications()}
                <div style={{ position: 'absolute', marginTop: '220px', width: '82%' }}>
                    <CancelButton handleClick={this.closeSideBar} isCentered={true} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        sideBarOptionState: state.sideBarOptionState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSidebarOption: (mode) => {
            dispatch(toggleSidebarOptionRequest(mode))
        }
    }
}

export const Notifications = connect(mapStateToProps, mapDispatchToProps)(NotificationsComponent);
