import React, { Component } from 'react';
import { MyToastContainer } from '../components/MyToastContainer';
import { SideBar } from './sidebar/SideBarComponent';
import { TopBar } from './topbar/TopBarComponent';
import { connect } from 'react-redux';
import { toggleSidebarOptionRequest } from '../redux/actions/toggleSidebarOption';

import './Layout.css';

const mainDivStyle = {
    height: '100vh',
    backgroundColor: '#F6F8FA',
    overflow: 'auto'
};

const contentNormalStyle = {
    marginLeft: '160px',
    marginRight: '60px',
    maxWidth: '84vw'
}

const contentFullStyle = {
    marginLeft: '81px'
}


class Layout extends Component {
    static displayName = Layout.name;

    render() {

        let contentStyleClass = this.props.layoutMode == 'normal' ? '' : 'app-content-full-size';
        let topBar = this.props.layoutMode == 'normal'
            ? <TopBar />
            : <div className="top-bar-wrapper">
                <TopBar />
            </div>;
        if (this.props.layoutMode == 'topBarDisabled') {
            topBar = null;
        }

        return (

            <div id="layout-container">
                <SideBar />
                <div id="app-content" onClick={this.onContentClick} className={contentStyleClass}>
                    {topBar}
                    <MyToastContainer />
                    <div id="content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

    onContentClick = (e) => {
        if (this.props.sideBarOptionState != 'close') {
            this.props.toggleSidebarOption('close')
        }
    }
}

const mapStateToProps = state => {

    return {
        layoutMode: state.selectedLayoutMode,
        sideBarOptionState: state.sideBarOptionState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSidebarOption: (mode) => {
            dispatch(toggleSidebarOptionRequest(mode))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
