import { SHOW_NOTIFICATIONS_REQUEST } from '../constants/ActionTypes';

const initState = {
    list: [],
    notSeenCount: 0
}

const showNotificationsReducer = (state = initState, action) => {

    switch(action.type){
        case SHOW_NOTIFICATIONS_REQUEST:
            return action.payload;
        default:
            return state;
    }
}

export default showNotificationsReducer;