import React, { Component } from 'react';
import { NoAccess } from '../../../../pages/NoAccess';
import { ChartList } from '../../charts/chartList/ChartListComponent';
import { ROLES, Administrator } from '../../../../auth/userRoles';
import { isAdminOfEngagement } from '../../../../auth/authorizationService';
import { Tabs, Tab } from 'react-bootstrap';
import { getById } from '../../services/dashboards';
import { AccessToDashboardComponent } from '../accessToDashboard/AccessToDashboardComponent';
import { TagList } from '../tagList/TagListComponent';


class DashboardDetailsComponent extends Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            blocking: false,
            isMount: false,
            hasAccess: false,
            isDataLoaded: false,
            dashboard: {}
        };
    }

    async componentDidMount() {

        await this.fetchDashboard();
    }

    async fetchDashboard() {

        const { dashboardId } = this.props.params;
        try {

            let result = await getById(dashboardId);
            const hasAccess = isAdminOfEngagement(this.props.user, result.data.engagementId);

            if (hasAccess) {
    
                this.setState({
                    hasAccess: true,
                    dashboard: result.data,
                    isDataLoaded: true
                })
                return;
            } 

            this.setState({
                hasAccess: false,
                isDataLoaded: true
            });
        }
        catch(ex) {

            this.setState({
                hasAccess: false,
                isDataLoaded: true
            })
        }
    }

    render() {

        const dashboard = this.state.dashboard;

        if (!this.state.isDataLoaded) {
            return null;
        }

        if (!this.state.hasAccess) {
            return <NoAccess />
        }

        return (
            <div>
                <h4 style={{ color: 'gray', fontSize: 18 }}>Details of {dashboard.displayName}</h4>
                <br />
                <Tabs defaultActiveKey="charts">
                    <Tab eventKey="charts" title="Charts">
                        <ChartList dashboardId={dashboard.id} />
                    </Tab>
                    <Tab eventKey="tags" title="Tags">
                        <TagList dashboardId={dashboard.id} />
                    </Tab>
                    <Tab eventKey="users" title="Dashboard Permissions">
                        <AccessToDashboardComponent dashboardId={dashboard.id} />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export { DashboardDetailsComponent };
