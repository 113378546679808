import { httpClient } from '../../../common/httpClient';
import { BrowserDetector } from '../../../common/browserDetector';

const browserDetector = new BrowserDetector();

const sendReportedMessage = (data) =>{    
    var formData = new FormData();
    formData.append('attachment', data.attachment);
    formData.append('message', data.message);
    formData.append('pageUrl', window.location.href);
    formData.append('webBrowserType', browserDetector.getBrowserType());
 httpClient({    
    url: `api/report/reportIssue`,
    method: "post",    
    data: formData,
    headers: {
        "Content-Type": "multipart/form-data"        
    }
});
}
export { sendReportedMessage };