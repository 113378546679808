import axios from "axios";

const DEFAULT_CONTENT_TYPE = "application/json";

const registerUser = (registerUserModel) => {
    const config = {
        headers: {
            'Content-Type': DEFAULT_CONTENT_TYPE
        }
    }

    return axios.post('/api/account/register', registerUserModel, config);
}

const getSecurityQuestions = () => axios.get('/api/account/securityquestions');


export { 
    registerUser,
    getSecurityQuestions
}