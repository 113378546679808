import React from 'react'
import { Form, Button, Table } from 'react-bootstrap';
import { notifyUsers } from '../../services/upload';
import { MyTooltip } from '../../../../components/MyTooltip';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';

class SendNotificationToMobileApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
        }
    }


    confirmSending = () => {
        confirmAlert({
            title: 'Confirm to proceed',
            message: 'Are you sure to sent notifications?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.sendNotification()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    sendNotification = async () => {
        try {
            await notifyUsers();
            toast.info("Notification has been sent");
        } catch (e) {
            
        }

    }


    render() {
        return (

            <div>
                <MyTooltip tooltip="Notify all users about new version of application" paddingLeft={0}>
                    <Button variant="outline-primary" size="sm" onClick={this.confirmSending}>
                        Send notification
                    </Button>
                </MyTooltip>
            </div>

        )
    }
}

export { SendNotificationToMobileApp }