import { httpClient } from '../../../common/httpClient';

const DEFAULT_CONTENT_TYPE = "application/json";

const changePassword = (changePasswordViewModel) => httpClient({
    url: `api/account/changePassword`,
    method: "post",
    data: changePasswordViewModel,
    headers: {
        'Content-Type': "application/json",
        'Accepts': "application/json",
    }
});

export {
    changePassword
}