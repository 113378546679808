import * as React from 'react';
import PropTypes from 'prop-types';

import styles from './ChartsLivePreviewComponent.module.scss';
import { ChartTile } from '../chartTile/ChartTile';
import { IFrameComponent } from '../../../../components/IFrameComponent';
import { tagColors } from '../../../shared/shared'
import LoadingIndicatorPreview from './LoadingIdicatorPreview';

class ChartsLivePreviewComponent extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            isLoaded: false,
            data: this.props.data,
            selectedChart: this.props.data[0]
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            data: nextProps.data,
            isLoaded: this.props.data[0].id == nextProps.data[0].id,
            selectedChart: nextProps.data[0]
        })
    }

    onChartLoad = () => {

        this.setState({
            isLoaded: true
        })
    }

    selectChart(chart) {

        this.setState({
            selectedChart: chart,
            isLoaded: false,
        });
    }

    mapColorToTags() {

        const tagColorMap = [];
        this.props.tags.map((el, index) => {
            const color = tagColors[index % tagColors.length];
            tagColorMap.push({ tagId: el.id, color: color });
        });

        return tagColorMap;
    }

    getTagColor(tagColorMap, tagId) {

        const obj = tagColorMap.find(x => x.tagId == tagId);

        return obj != undefined ? obj.color : 'gray';
    }

    goToFullView(id) {
        this.props.history.push(`${this.props.match.params.dashboardId}/charts/${id}`)
    }


    renderChart(el, firstColor, secondColor) {

        return <ChartTile
            title={el.displayName}
            firstTagColor={firstColor}
            secondTagColor={secondColor}
            icon={el.iconName}
            onClick={() => { this.selectChart(el) }}
            fullViewOnClick={() => { this.goToFullView(el.id) }}
            isPreview={true}
            isSelected={this.state.selectedChart.id == el.id}
        />
    }

    renderSideCharts() {

        let data = this.state.data.slice();

        let toRender = data.splice(0, 6);

        const tagColorMap = this.mapColorToTags();
        return toRender.map((el, index) => {

            const color = el.tags.length > 0 ? this.getTagColor(tagColorMap, el.tags[0].id) : 'gray';
            const secondColor = el.tags.length > 1 ? this.getTagColor(tagColorMap, el.tags[1].id) : 'transparent';

            return (
                <div className={styles['chart-side-tile-preview-wrapper']}
                    key={el.id}
                >
                    {this.renderChart(el, color, secondColor)}
                </div>

            )
        })
    }

    renderBottomCharts() {

        let data = this.state.data.slice();

        if (data.length < 7)
            return null;

        let toRender = data.slice(6);

        const tagColorMap = this.mapColorToTags();
        return toRender.map((el, index) => {

            const color = el.tags.length > 0 ? this.getTagColor(tagColorMap, el.tags[0].id) : 'gray';
            const secondColor = el.tags.length > 1 ? this.getTagColor(tagColorMap, el.tags[1].id) : 'transparent';

            return (
                <div className={styles['chart-bottom-tile-preview-wrapper']}
                    key={el.id}
                >
                    {this.renderChart(el, color, secondColor)}
                </div>
            )
        })
    }

    render() {

        return (

            <div className="row row-eq-height col-xs-12">
                <div id={styles['chart-preview-side']}>
                    {this.renderSideCharts()}
                </div>
                {!this.state.isLoaded ? <LoadingIndicatorPreview /> : null}
                <div id={styles['chart-preview-main']} style={{ opacity: this.state.isLoaded ? '1' : '0' }}>
                    <IFrameComponent size='full'
                        url={this.state.selectedChart.url}
                        id={this.state.selectedChart.id}
                        onLoad={this.onChartLoad}
                        color='none'
                    />
                </div>
                <div id={styles['chart-preview-bottom']} className="col-xs-12 col-lg-12">
                    {this.renderBottomCharts()}
                </div>
            </div>
        )
    }
}

ChartsLivePreviewComponent.propTypes = {

    data: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired
}

export { ChartsLivePreviewComponent }