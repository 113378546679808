import React, { Component } from 'react';
import { Form, Button, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { getAll, saveNew, updateDashboard, deleteDashboard, updateOrder } from '../../services/dashboards';
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSave, faPlusCircle, faTrashAlt, faWindowClose, faEye } from "@fortawesome/free-solid-svg-icons/index";
import { MyTooltip } from '../../../../components/MyTooltip';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { DeleteModal } from '../../../../components/DeleteModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getErrorMessage } from '../../services/common';


class DashboardList extends Component {

    constructor(props) {
        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.onChangeUpdate = this.onChangeUpdate.bind(this);
        this.onChangeNew = this.onChangeNew.bind(this);

        this.state = {
            data: {},
            blocking: true,
            editInput: {},
            editing: {},
            newDashboard: {},
            newDashboardActive: false,
            deleting: [],
            engagementId: null,
            ordering: [],
            showModal: false,
        };
    }

    async componentDidMount() {

        await this.fetchDashboardsWithOrdering()
    }

    async fetchDashboardsWithOrdering() {

        try {
            const engagementId = this.props.engagementId;

            let dashboardsFetch = await getAll(engagementId);

            let dataObj = dashboardsFetch.data;
            let order = dataObj.map((el) => {
                return {
                    id: el.id,
                    order: el.order
                }
            })

            this.setState({
                data: dataObj,
                ordering: order,
                blocking: false
            });
        }
        catch (ex) {
            this.setState({ blocking: false })
        }
    }

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    editToggle(id) {

        let dashboard = this.state.data.filter(c => c.id == id)[0];

        const editInput = Object.assign({}, this.state.editInput);
        editInput[id] = !editInput[id];
        const editing = this.state.editing;

        editing[id] = {
            displayName: dashboard.displayName,
            isLivePreviewEnabled: dashboard.isLivePreviewEnabled,
            isSummaryEnabled: dashboard.isSummaryEnabled,
            summaryLink: dashboard.summaryLink,
        }

        this.setState({ editInput: editInput, editing: editing });
    }

    saveNewRecord = async (dashboard) => {

        if (!this.isDashboardValid(dashboard))
            return;

        let data = [...this.state.data];
        let engagementId = this.props.engagementId;
        const displayName = dashboard.displayName;
        const isLivePreviewEnabled = dashboard.isLivePreviewEnabled;
        const isSummaryEnabled = dashboard.isSummaryEnabled;
        const summaryLink = dashboard.summaryLink;

        try {

            let result = await saveNew(engagementId, displayName, isLivePreviewEnabled, isSummaryEnabled, summaryLink)
            data.push(result.data);
            this.setState({ data: data, newDashboardActive: false })

            toast.info("Dashboard added successfully.")
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    updateRecord = async (id) => {

        this.state.editing[id].id = id;

        if (!this.isDashboardValid(this.state.editing[id]))
            return;

        let newDisplayName = this.state.editing[id].displayName;
        let isLivePreviewEnabled = this.state.editing[id].isLivePreviewEnabled;
        let summaryLink = this.state.editing[id].summaryLink;
        let isSummaryEnabled = this.state.editing[id].isSummaryEnabled;
        let engagementId = this.props.engagementId;

        try {

            let data = [...this.state.data];
            let dashboard = data.find(x => x.id == id);

            dashboard.displayName = newDisplayName;
            dashboard.isLivePreviewEnabled = isLivePreviewEnabled
            dashboard.isSummaryEnabled = isSummaryEnabled
            dashboard.summaryLink = summaryLink

            await updateDashboard(engagementId, id, newDisplayName, isLivePreviewEnabled, isSummaryEnabled, summaryLink);

            this.setState({ data: data })
            this.editToggle(id);

            toast.info("Dashboard updated successfully.")
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    isDashboardValid = (dashboard) => {

        for (let existingDashboard of this.state.data) {

            if (dashboard.displayName.toUpperCase().trim() === existingDashboard.displayName.toUpperCase().trim() && dashboard.id !== existingDashboard.id) {

                toast.error('Dashboard with this name already exists!')
                return false;
            }
        }
        return true;
    }

    deleteRecord = async () => {

        let id = this.state.deleting.id;

        try {

            await deleteDashboard(id);
            const data = [...this.state.data.filter(x => x.id !== id)];

            this.setState({
                data: data,
                showModal: false
            })

            toast.info("Dashboard deleted successfully.")
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    onChangeUpdate(id, variable, type, e) {

        const editing = this.state.editing;
        let value = e.target.value;

        if (type == 'checkbox')
            value = e.target.checked

        editing[id][variable] = value;
        this.setState({ editing: editing })
    }

    onChangeNew(variable, type, e) {

        let value = e.target.value;

        if (type == 'checkbox')
            value = e.target.checked;

        let newDashboard = Object.create(this.state.newDashboard);
        newDashboard[variable] = value;

        this.setState({ newDashboard: newDashboard })
    }

    onDragEnd = async result => {

        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }


        let reordered = this.state.ordering.slice();

        reordered.map((value) => {

            if (value.order >= destination.index && value.order < source.index) {
                value.order = value.order + 1;
                return;
            }

            if (value.order <= destination.index && value.order > source.index) {
                value.order = value.order - 1;
                return;
            }


            if (value.order == source.index) {
                value.order = destination.index;
                return;
            }

        });

        let data = [...this.state.data]
        let dataReordered = [...this.state.data]

        dataReordered.splice(source.index, 1);
        dataReordered.splice(destination.index, 0, data[draggableId - 1]);


        try {

            let engagementId = this.props.engagementId;
            let newOrder = dataReordered.map((el, index) => {
                return {
                    id: el.id,
                    order: index
                }
            })

            await updateOrder(engagementId, newOrder)

            this.setState({ data: dataReordered, ordering: reordered });
        }
        catch (ex) { }
    };

    checkNew() {

        if (this.state.newDashboardActive) {
            return (
                <tr>
                    <td>
                    </td>
                    <td>
                        <Form.Control type="text" placeholder="Type dashboard name..." size="sm"
                            onChange={e => this.onChangeNew('displayName', 'text', e)}
                            value={this.state.newDashboard.displayName}>
                        </Form.Control>
                    </td>
                    <td>
                        <input type='checkbox'
                            onChange={e => this.onChangeNew('isLivePreviewEnabled', 'checkbox', e)}
                            value={this.state.newDashboard.isLivePreviewEnabled}
                        />
                    </td>
                    <td>
                        <Form.Control type="text" placeholder="Type summary link..." size="sm"
                            onChange={e => this.onChangeNew('summaryLink', 'text', e)}
                            value={this.state.newDashboard.summaryLink}>
                        </Form.Control>
                    </td>
                    <td>
                        <input type='checkbox'
                            onChange={e => this.onChangeNew('isSummaryEnabled', 'checkbox', e)}
                            value={this.state.newDashboard.isSummaryEnabled}
                        />
                    </td>
                    <td>
                        <div>
                            <MyTooltip tooltip="Save">
                                <Button onClick={() => this.saveNewRecord(this.state.newDashboard)}
                                    variant="outline-success" size="sm">
                                    <FontAwesomeIcon icon={faSave} />
                                </Button>
                            </MyTooltip>
                            <MyTooltip tooltip="Cancel">
                                <Button onClick={() => this.setState({ newDashboardActive: false, newDashboard: {} })}
                                    variant="outline-primary" size="sm">
                                    <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                            </MyTooltip>
                        </div>
                    </td>
                </tr>)
        }
    }

    modalShow(id) {

        let data = this.state.data.slice();
        let deleting = [];

        data.map((value) => {
            if (value.id == id) {
                deleting.category = 'dashboard';
                deleting.title = value.displayName;
                deleting.id = value.id;
            }
        });

        this.setState({ showModal: true, deleting: deleting })
    }

    renderBodyTable() {

        let data = this.state.data;

        return (Object.keys(data).map((index) => {

            let keyNumber = parseInt(index);
            let action;
            let summaryCheck;
            let summaryLink;
            let livePreview;
            let id = data[index].id;

            let displayName = <MyTooltip tooltip="Go to charts">
                <Link
                    to={{
                        pathname: `/admin/dashboards/${id}`,
                    }}>
                    {data[index].displayName}
                </Link>
            </MyTooltip>

            if (!this.state.editInput[id]) {

                livePreview = <div>
                    <input type="checkbox" checked={data[index].isLivePreviewEnabled} readOnly />
                </div>
                summaryCheck = <div>
                    <input type="checkbox" checked={data[index].isSummaryEnabled} readOnly />
                </div>
                summaryLink = <a href={data[index].summaryLink}> {data[index].summaryLink} </a>
                action = <div>
                    <MyTooltip tooltip="Edit">
                        <Button onClick={() => this.editToggle(id)} variant="outline-info" size="sm">
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </MyTooltip>
                    <MyTooltip tooltip="Delete">
                        <Button onClick={() => this.modalShow(id)} variant="outline-danger" size="sm">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </MyTooltip>
                    <MyTooltip tooltip="Go to View">
                        <Link
                            to={{
                                pathname: `/engagements/${data[index].engagementId}/dashboards/${data[index].id}`
                            }}><Button
                                variant="outline-primary" size="sm">
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                    </MyTooltip>
                </div>
            }
            else {

                displayName =
                    <Form.Control type="text" size="sm"
                        onChange={e => {
                            this.onChangeUpdate(id, 'displayName', 'text', e);
                        }}
                        value={this.state.editing[id] ? this.state.editing[id].displayName : data[index].displayName}>
                    </Form.Control>
                livePreview =
                    <input type="checkbox"
                        checked={this.state.editing[id].isLivePreviewEnabled}
                        onChange={e => {
                            this.onChangeUpdate(id, 'isLivePreviewEnabled', 'checkbox', e);
                        }}

                    />
                summaryLink = <Form.Control type="text" size="sm"
                    onChange={e => {
                        this.onChangeUpdate(id, 'summaryLink', 'text', e);
                    }}
                    value={this.state.editing[id] ? this.state.editing[id].summaryLink : data[index].summaryLink}>
                </Form.Control>

                summaryCheck =
                    <input type="checkbox"
                        checked={this.state.editing[id].isSummaryEnabled}
                        onChange={e => {
                            this.onChangeUpdate(id, 'isSummaryEnabled', 'checkbox', e);
                        }}

                    />

                action = <div>
                    <MyTooltip tooltip="Save">
                        <Button onClick={() => this.updateRecord(id)} variant="outline-success" size="sm">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </MyTooltip>
                    <MyTooltip tooltip="Cancel">
                        <Button onClick={() => this.editToggle(id)} variant="outline-primary" size="sm">
                            <FontAwesomeIcon icon={faWindowClose} />
                        </Button>
                    </MyTooltip>
                </div>
            }

            return (
                <Draggable key={keyNumber + 1} draggableId={keyNumber + 1} index={keyNumber}>
                    {(provided) => (


                        <tr key={keyNumber + 1} ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <td>{id}</td>
                            <td>{displayName}</td>
                            <td>{livePreview}</td>
                            <td>{summaryLink}</td>
                            <td>{summaryCheck}</td>
                            <td>
                                {action}
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        })
        );
    }

    render() {

        return (
            <div>
                <DeleteModal show={this.state.showModal}
                    deleting={this.state.deleting}
                    onconfirmdelete={this.deleteRecord}
                    onHide={() => this.setState({ showModal: false })} />
                <BlockUIContainer blocking={this.state.blocking}>
                    <br />
                    <Button onClick={() => this.setState({ newDashboardActive: true, newDashboard: {} })}
                        variant="outline-primary"
                        size="sm">
                        <FontAwesomeIcon icon={faPlusCircle} /> Add new dashboard</Button>
                    <br />
                    <br />
                    <DragDropContext
                        onDragEnd={this.onDragEnd}
                    >
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Dashboard</th>
                                    <th>Live Preview</th>
                                    <th>Summary Link</th>
                                    <th>Summary on/off</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <Droppable droppableId="dashboards">
                                {(provided) => (

                                    <tbody ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {this.checkNew()}
                                        {this.renderBodyTable()}

                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </Table>
                    </DragDropContext>
                </BlockUIContainer>
            </div>
        );
    }
}

export { DashboardList };
