import React, { Component, useRef } from 'react';
import { Form, Button, Card, Container } from 'react-bootstrap';
import { getAllIcon, getById, updateChart, saveNew, uploadChartFile, updateChartURL } from '../../services/charts';
import { getAll as getAllTags } from '../../services/tags';
import { toast } from 'react-toastify';
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import Select from 'react-select'
import Switch from "react-switch";
import { MyTooltip } from '../../../../components/MyTooltip';
import { getErrorMessage } from '../../services/common';

class ChartDetailsComponent extends Component {

    constructor(props) {

        super(props);
        this.state = {
            blocking: false,
            editing: {
                isLive: true,
                isStaticFile: false,
                refreshFrequencyInMinutes: 0,
                dashboardId: props.params.dashboardId,
                openInNewTab: false
            },
            icons: [],
            tagsToChoose: [],
            assignedTagsIds: [],
            fileUpload: { file: null, isSuccess: false, result: {} }
        };
    }

    async componentDidMount() {

        this.setState({ blocking: true })

        if (this.props.isEditing)
            await this.fetchChartData();
        else
            await this.fetchTagsAndIcons();
    }


    async fetchTagsAndIcons() {

        try {

            let allResponses = await Promise.all([getAllTags(this.props.params.dashboardId), getAllIcon()]);
            let tagsToChoose = allResponses[0];
            let icons = allResponses[1];

            this.setState({
                icons: icons.data,
                tagsToChoose: tagsToChoose.data,
                blocking: false
            });
        }
        catch (ex) {
            this.setState({ blocking: false });
        }
    }

    async fetchChartData() {

        let editing = Object.assign({}, this.state.editing);
        let icons = this.state.icons.slice();
        let tagsToChoose = this.state.tagsToChoose;
        let assignedTagsIds = this.state.assignedTagsIds;

        try {

            let allResponses = await Promise.all([getById(this.props.params.chartId), getAllIcon()]);
            const chartResponse = allResponses[0];
            const iconsResponse = allResponses[1];

            let tagResponse = await getAllTags(chartResponse.data.dashboardId);

            editing = chartResponse.data;
            icons = iconsResponse.data;
            tagsToChoose = tagResponse.data;
            assignedTagsIds = editing.tags.map(x => x.id);

            this.setState({
                editing: editing,
                icons: icons,
                assignedTagsIds: assignedTagsIds,
                tagsToChoose: tagsToChoose,
                hasAccess: true,
                blocking: false
            });
        }
        catch (ex) {
            this.setState({ blocking: false });
        }
    }

    onChange = (id, e) => {

        let editing = Object.assign({}, this.state.editing);
        editing[id] = e.target.value;
        this.setState({ editing: editing });
    }

    onChangeSelect = (e) => {

        let editing = Object.assign({}, this.state.editing);
        editing['iconName'] = e.value;
        this.setState({ editing: editing })
    }

    updateEditingState(object) {
        let editing = Object.assign({}, this.state.editing);
        for (let property in object) {
            editing[`${property}`] = object[property];
        }
        this.setState({ editing: editing });
    }

    onChangeTagsSelect = (e) => {

        try {
            let assignedTagsIds = e.map(x => x.value);

            this.setState({ assignedTagsIds: assignedTagsIds })

            if (assignedTagsIds.length > 2) {
                toast.warn("Only two tags can be assigned!")
                return;
            }
        }
        catch (error) {

            this.setState({ blocking: false });
            this.logServerError(error)
        }
    }

    onSwitchChange = (checked) => {

        this.setState(prevState => ({
            editing: {
                ...prevState.editing,
                isLive: checked,
                refreshFrequencyInMinutes: 0,
            }
        }))
    }

    onOpenInNewTabSwitchChange = (checked) => {

        this.setState(prevState => ({
            editing: {
                ...prevState.editing,
                openInNewTab: checked,

            }
        }))
    }

    onIsStaticFileSwitchChange = (checked) => {

        this.setState(prevState => ({
            editing: {
                ...prevState.editing,
                isStaticFile: checked,
                url: prevState.fileUpload.file == null
                    ? prevState.editing.url
                    : ''
            }
        }))
    }

    saveChart = async () => {

        if (!this.isChartValid(this.state.editing))
            return;

        this.setState({ blocking: true });

        const dashboardId = this.props.params.dashboardId;
        try {
            let editing = Object.assign({}, this.state.editing);
            if (this.state.editing.isStaticFile) {
                editing['url'] = 'https://dummny_url/';
            }

            let result = await saveNew(dashboardId, editing, this.state.assignedTagsIds)
            let chart = result.data;
            if (this.state.fileUpload.file !== null) {
                await this.uploadFile(chart.id, chart.dashboardId);

                let updateObj = {
                    id: chart.id,
                    dashboardId: chart.dashboardId,
                    url: this.state.fileUpload.result.internalUrl,
                    isStaticFile: true
                }
                chart.url = updateObj.url;
                chart.isStaticFile = true;
                await updateChartURL(updateObj);
                this.updateEditingState({ isStaticFile: true, url: updateObj.url });
            }

            this.setState({ blocking: false, editing: chart });
            toast.info("Chart created successfully.")
            this.props.history.push(`/admin/charts/edit/${chart.id}`);
        }
        catch (error) {
            this.setState({ blocking: false });
            const message = getErrorMessage(error);
            toast.error(message);
        }
    }

    updateChart = async () => {

        if (!this.isChartValid(this.state.editing))
            return;
        try {

            if (this.state.fileUpload.file !== null) {
                await this.uploadFile(
                    this.state.editing.id,
                    this.state.editing.dashboardId
                );
                this.updateEditingState({
                    isStaticFile: true,
                    url: this.state.fileUpload.result.internalUrl
                });
            }

            await updateChart(this.state.editing, this.state.assignedTagsIds);
            toast.info("Chart edited successfully.")
        }
        catch (error) {
            this.setState({ blocking: false });
            const message = getErrorMessage(error);
            toast.error(message);
        }
    }

    uploadFile = async (chartId, dashboardId) => {
        const formData = new FormData();
        formData.append('file', this.state.fileUpload.file);
        formData.append('chartId', chartId)
        formData.append('dashboardId', dashboardId);

        try {
            const result = await uploadChartFile(formData);
            this.setState({
                fileUpload: {
                    file: null,
                    isSuccess: true,
                    result: result.data
                }
            });
        }
        catch (error) {
            this.setState({
                fileUpload: {
                    file: null,
                    isSuccess: false,
                    result: error
                }
            });
        }
    }

    frequencyValidationFailed = () => {
        return !this.state.editing.isLive && this.state.editing.refreshFrequencyInMinutes <= 0;
    }

    isChartValid = (chart) => {

        if (chart.displayName === null || chart.displayName === undefined) {

            toast.error('Chart name cannot be empty!');
            return false;
        }

        if (!chart.isStaticFile && (chart.url === null || chart.url === undefined)) {

            toast.error('Chart URL cannot be empty!');
            return false;
        }

        if (!chart.isStaticFile && !chart.url.startsWith('https') && !chart.url.startsWith('http')) {

            toast.error('Chart URL must start with "http" or "https"')
            return false;
        }

        if (chart.iconName === null || chart.iconName === undefined) {

            toast.error('Please select icon for chart.')
            return false;
        }

        return true;
    }

    logServerError(error) {

        let message = null;
        for (let err in error.data) {

            message = error.data[err][0];
            break;
        }
        if (message === null || message === undefined)
            return;

        toast.error(message);
    }

    render() {

        let options = [];
        let tagOptions = []
        let defaultValue = '';
        let tagDefaultValue = [];
        this.state.icons.map((ico, index) => {

            options[index] = {

                value: ico,
                label: <span>{ico} <img alt='icon' src={`/api/staticresources/icons/${ico}`} style={{ width: 30, height: 30 }} /></span>
            }

            if (this.props.isEditing && this.state.editing['iconName'] === ico) {
                defaultValue = options[index]
            }
        });

        this.state.tagsToChoose.map((tag, index) => {

            tagOptions[index] = {

                value: tag.id,
                label: tag.name
            }

            if (this.props.isEditing) {

                let tagAssigned = this.state.editing.tags.find(x => x.id === tag.id);
                if (tagAssigned != null) {
                    tagDefaultValue.push(tagOptions[index]);
                }
            }

        });

        let headerName = this.props.isEditing ? "Edit Chart" : "New Chart"

        const frequencyValidationMessage = this.frequencyValidationFailed() ?
            <div style={{
                width: '100%',
                marginTop: '.25rem',
                fontSize: '80%',
                color: '#dc3545'
            }} >
                Refresh Frequency must be greater than 0.
            </div>
            : null

        return (

            <BlockUIContainer blocking={this.state.blocking}>

                <Container style={{ width: '50%' }}>
                    <br />

                    <Card className="text-center">
                        <Card.Header>{headerName}</Card.Header>
                        <Card.Body>
                            <Form.Group controlId="displayName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" size="sm"
                                    onChange={e => {
                                        this.onChange('displayName', e);
                                    }}
                                    defaultValue={this.state.editing.displayName}
                                    placeholder="Chart Name"
                                >
                                </Form.Control>
                            </Form.Group>
                            <div>

                                <Form.Label>Static File (image, pdf)</Form.Label>

                                <MyTooltip tooltip="Static file instead of URL">
                                    <div>
                                        <Switch
                                            onChange={this.onIsStaticFileSwitchChange}
                                            checked={this.state.editing.isStaticFile}
                                            className="react-switch"
                                        />
                                    </div>
                                </MyTooltip>
                            </div>
                            {this.state.editing.isStaticFile
                                ?
                                <Form>
                                    <Form.Control style={{ width: '100%' }} type="file" accept=".pdf,.png,.jpg,.jpeg"
                                        onChange={(e) => this.setState({
                                            fileUpload: {
                                                file: e.target.files[0],
                                                isSuccess: false,
                                                result: {}
                                            }
                                        })}
                                    />
                                </Form>
                                : null
                            }
                            {!this.state.editing.isStaticFile
                                ?
                                <Form.Group controlId="url">
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control type="text" size="sm"
                                        onChange={e => {
                                            this.onChange('url', e);
                                        }}
                                        defaultValue={this.state.editing.url}
                                        placeholder="URL"
                                    >
                                    </Form.Control>
                                </Form.Group>
                                : null
                            }
                            <br />
                            {this.state.editing.isStaticFile
                                ?
                                <div>
                                    <a href={this.state.editing.url} target="_blank" rel="noopener noreferrer">
                                        {this.state.editing.url}
                                    </a>
                                    <br />
                                </div>
                                : null
                            }
                            <Form.Label>Tags</Form.Label>
                            <Select isMulti
                                onChange={e => {
                                    this.onChangeTagsSelect(e);
                                }}
                                defaultValue={tagDefaultValue}
                                className="basic-multi-select"
                                options={tagOptions} />

                            <Form.Label>Icon</Form.Label>
                            <Select onChange={e => {
                                this.onChangeSelect(e);
                            }} defaultValue={defaultValue} options={options} />

                            <div>

                                <Form.Label>IsLive</Form.Label>

                                <MyTooltip tooltip="Chart refreshing type">
                                    <div>
                                        <Switch
                                            onChange={this.onSwitchChange}
                                            checked={this.state.editing.isLive}
                                            className="react-switch"
                                        />
                                    </div>
                                </MyTooltip>
                            </div>
                            {this.state.editing.isLive ?
                                null
                                :
                                <Form.Group controlId="refreshFrequencyInMinutes">
                                    <Form.Label>Refresh Frequency (minutes)</Form.Label>

                                    <Form.Control type="number" size="sm"
                                        required
                                        onChange={e => {
                                            this.onChange('refreshFrequencyInMinutes', e);
                                        }}
                                        defaultValue={this.state.editing.refreshFrequencyInMinutes}
                                        placeholder="15"
                                    >
                                    </Form.Control>
                                    {frequencyValidationMessage}
                                </Form.Group>
                            }
                            <div>

                                <Form.Label>Open in a New Tab</Form.Label>

                                <MyTooltip tooltip="Chart refreshing type">
                                    <div>
                                        <Switch
                                            onChange={this.onOpenInNewTabSwitchChange}
                                            checked={this.state.editing.openInNewTab}
                                            className="react-switch"
                                        />
                                    </div>
                                </MyTooltip>
                            </div>
                            <br />
                            <div style={{ display: 'inline' }}>
                                <a href={`/admin/dashboards/${this.state.editing.dashboardId}`} style={{ marginRight: 15 }} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push(`/admin/dashboards/${this.state.editing.dashboardId}`);
                                }}>Go Back</a>
                                <Button disabled={this.state.assignedTagsIds.length > 2} onClick={() => {

                                    if (this.props.isEditing)
                                        this.updateChart();
                                    else
                                        this.saveChart();

                                }} variant="primary">Save</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
            </BlockUIContainer >
        );
    }
}

export { ChartDetailsComponent }