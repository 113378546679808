import * as React from 'react';
import { switchSlideshowModeRequest } from '../../../../redux/actions/switchSlideshowMode';
import { connect } from 'react-redux';
import { selectLayoutModeRequest } from '../../../../redux/actions/selectLayoutMode';
import styles from './ChartsSlideshowComponent.module.scss';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'react-bootstrap';
import { MyTooltip } from '../../../../components/MyTooltip';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { toast } from 'react-toastify';
import { breadcrumbsRequest } from '../../../../redux/actions/breadcrumbs';
import { searchChartRequest } from '../../../../redux/actions/search'

class ChartsSlideshowComponent extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            isLoaded: false,
            currentIndex: 0,
            timespan: 10000,
            isPaused: false,
            showTimingModal: false,
            userTimingInput: 10
        }

        this.timeout = null;
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            isLoaded: false,
            data: nextProps.data
        })
    }

    openNextChart = () => {

        if (this.props.data.length === 1) {
            return
        }   
        
        else {
        
            let newIndex = this.state.currentIndex + 1

            if (newIndex >= this.props.data.length)
                newIndex = 0;

            this.setState({
                isLoaded: false,
                currentIndex: newIndex,
            })
        }
    }

    openPreviousChart = () => {

        let newIndex = this.state.currentIndex - 1

        if (newIndex < 0)
            newIndex = this.props.data.length - 1

        this.setState({
            currentIndex: newIndex,
        })
    }

    setSlideshowPause = () => {

        const current = this.state.isPaused;

        if (current) {

            this.state.isPaused = false;
            this.startSlideshowCountdown();
            this.setState({ isPaused: false })
        }
        else {

            clearTimeout(this.timeout);
            this.setState({ isPaused: true })
        }

    }

    handleModalOpen = () => {
        this.setState({ showTimingModal: true })
    }

    handleModalClose = () => {
        this.setState({ showTimingModal: false })
    }

    handleTimingChange = (e) => {

        const value = e.target.value
        this.setState({
            userTimingInput: value
        })
    }

    setTiming = () => {

        if (this.state.userTimingInput== 0)
        {
           toast.error('Timings cannot be set to 0');
           return false;
        } 

        const newValue = this.state.userTimingInput * 1000;

        this.state.timespan = newValue;
        clearTimeout(this.timeout);
        this.startSlideshowCountdown();
        this.setState({
            timespan: newValue
        })
    }

    validateTiming = () => {
        return this.state.userTimingInput > 0 && this.state.userTimingInput <= 60;
        }

    startSlideshowCountdown = () => {

        clearTimeout(this.timeout);

        if (this.state.isPaused)
            return;

        this.timeout = setTimeout(() => {

            this.openNextChart()

        }, this.state.timespan)
    }

    onChartLoaded = () => {

        this.setState({ isLoaded: true })
        this.startSlideshowCountdown();
    }

    closeSlideshow = () => {

        clearTimeout(this.timeout)
        this.props.switchSlideshowMode(false)
        this.props.selectLayoutMode('normal')
        this.props.resetSearchChart();
    }

    render() {

        let chartToShow = this.props.data[this.state.currentIndex];

        return (
            <div className={styles['charts-slideshow-container']}>
                <div className={styles['charts-slideshow-wrapper']}>
                    <Modal show={this.state.showTimingModal} onHide={this.handleModalClose}>
                        <Modal.Header>
                            <Modal.Title>Please provide timing in seconds</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control type="number" size="sm" min="1" max="60" value={this.state.userTimingInput} onChange={(e) => this.handleTimingChange(e)} title="Timings must be greater than 0 and less than or equal to 60 seconds!"></Form.Control>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" disabled={!this.validateTiming()} onClick={() => { this.setTiming(); this.handleModalClose() }}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className={styles['charts-slideshow-content']}>
                        <div className={styles['charts-slideshow-topbar']}>
                            <h3>{this.state.isLoaded ? chartToShow.displayName : null}</h3>

                            <div className={styles['charts-slideshow-options-wrapper']}>

                                <div className={styles['charts-slideshow-option']} onClick={this.openPreviousChart}>
                                    <MyTooltip tooltip="Previous" placement='bottom'>
                                        <div className={styles['charts-slideshow-option-inner']}>
                                            <i className="icon-arrow-left"></i>
                                        </div>
                                    </MyTooltip>
                                </div>

                                <div className={styles['charts-slideshow-option']} onClick={this.openNextChart}>
                                    <MyTooltip tooltip="Next" placement='bottom'>
                                        <i className="icon-arrow-right"></i>
                                    </MyTooltip>
                                </div>

                                <div className={styles['charts-slideshow-option']} onClick={this.handleModalOpen}>
                                    <MyTooltip tooltip="Timing" placement='bottom'>
                                        <i className="icon-time"></i>
                                    </MyTooltip>
                                </div>

                                <div className={`${styles['charts-slideshow-option']} ${this.state.isPaused ? styles['paused'] : null}`} onClick={this.setSlideshowPause}>
                                    <MyTooltip tooltip={this.state.isPaused ? "Start" : "Pause"} placement='bottom'>
                                        <i className="icon-baseline-pause_circle_outline-24px"></i>
                                    </MyTooltip>
                                </div>

                                <div className={`${styles['charts-slideshow-option']}`} onClick={this.closeSlideshow}>
                                    <MyTooltip tooltip="Exit" placement='bottom'>
                                        <i className="icon-exit2"></i>
                                    </MyTooltip>
                                </div>
                            </div>

                        </div>
                        {!this.state.isLoaded ? <LoadingIndicator /> : null}
                        <iframe style={{ opacity: this.state.isLoaded ? '1' : '0' }}
                            src={chartToShow.url} onLoad={this.onChartLoaded}>
                        </iframe>
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount() {

        const breadcrumbs = this.props.data[0]
       
        this.props.changeBreadcrumbs({
            engagementName: breadcrumbs.engagementName,
            dashboardName: breadcrumbs.dashboardName,
            displayName: " "
        })
        this.closeSlideshow();
    }
}
ChartsSlideshowComponent.propTypes = {

    data: PropTypes.array.isRequired
}

const mapDispatchToProps = (dispatch) => {

    return {
        switchSlideshowMode: (mode) => {
            dispatch(switchSlideshowModeRequest(mode))
        },
        selectLayoutMode: (mode) => {
            dispatch(selectLayoutModeRequest(mode))
        },
        resetSearchChart: () => {
            dispatch(searchChartRequest(""))
        },
        changeBreadcrumbs: (breadcrumbs) => {
            dispatch(breadcrumbsRequest(breadcrumbs))
        }
    }
}

export default connect(null, mapDispatchToProps)(ChartsSlideshowComponent)