import { httpClient } from '../../../common/httpClient';

const getById = (chartId) => httpClient({
    url: `api/admincharts/${chartId}`,
    method: "get",
});

const getAll = (dashboardId) => httpClient({
    url: `api/admincharts/${dashboardId}/items`,
    method: "get",
});

const deleteChart = (id) => httpClient({
    url: `api/admincharts/` + id,
    method: "delete",
    data: {
        Id: id
    },
    headers: {
        "Content-Type": "application/json",
    }
});

const saveNew = (dashboardId, data, tagIds) => httpClient({
    url: `api/admincharts/`,
    method: "post",
    data: {
        ...data,
        DashboardId: dashboardId,
        tagIds: tagIds
    },
    headers: {
        "Content-Type": "application/json",
    }
});

const updateChart = (data, tagIds) => httpClient({
    url: `api/admincharts/${data.id}`,
    method: "put",
    data: {
        ...data,
        tagIds: tagIds
    },
    headers: {
        "Content-Type": "application/json",
    }
});

const getAllIcon = () => httpClient({
    url: `api/staticresources/icons`,
    method: "get",
});



const updateOrder = (dashboardId, newOrder) => httpClient({
    url: `api/admincharts/updateOrder`,
    method: "put",
    data: {
        dashboardId: dashboardId,
        chartsOrder: newOrder
    },
    headers: {
        "Content-Type": "application/json",
    }
})

const uploadChartFile = (data) => httpClient({
    url: `api/admincharts/upload`,
    method: "post",
    data: data,
    headers: {
        "Content-Type": "multipart/form-data",
    }
})

const updateChartURL = (data) => httpClient({
    url: `api/admincharts/url/${data.id}`,
    method: "put",
    data: {
        ...data
    },
    headers: {
        "Content-Type": "application/json",
    }
});

const getChartsForDashboardIds = (dashboardIds) => {
    let url = 'api/admincharts/forDashboardIds';
    if (dashboardIds.length >= 1) {
        url += `?dashboardIds=${dashboardIds[0]}`;
        for (let i = 1; i < dashboardIds.length; i++) {
            url += `&dashboardIds=${dashboardIds[i]}`;
        }
    }
    return httpClient({
        url: url,
        method: "get",
        headers: {
            "Content-Type": "application/json",
        }
    })
};

export { getAll, getById, deleteChart, saveNew, updateChart, getAllIcon, updateOrder, uploadChartFile, updateChartURL, getChartsForDashboardIds };