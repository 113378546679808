import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {Switch} from 'react-router-dom';
import {DashboardDetailsComponent} from './dashboardDetails/DashboardDetailsComponent';
import withRestrictedAccess from "../../../components/withRestrictedAccess";
import {Administrator, ROLES} from "../../../auth/userRoles";

class AdminDashboardsComponent extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Switch>
                <Route path="/admin/dashboards/:dashboardId"
                       render={({match}) => <DashboardDetailsComponent {...this.props}
                                                                       params={{dashboardId: match.params.dashboardId}}/>}/>

            </Switch>
        )
    }
}

export default withRestrictedAccess(ROLES[Administrator])(AdminDashboardsComponent)