import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getDailyUsageTrend } from '../../services/analytics';
import { BlockUIContainer } from '../../../../components/BlockUIContainer';

am4core.useTheme(am4themes_animated);

class DailyUsageTrend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            engagements: [],
            blocking: true
        };
    }

    async componentDidMount() {
        await this.loadChart(this.props.filters);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props) === JSON.stringify(prevProps)) {
            return;
        }
        
        this.setState({ blocking: true });
        
        if (this.chart) {
            this.chart.dispose();
        }
        await this.loadChart(this.props.filters);
    }

    async loadChart(filters) {
        let remotedata = (await getDailyUsageTrend(filters)).data;
        let data = [];
        for (let i = 0; i < remotedata.length - 1; i++) {
            data.push({
                date: remotedata[i].date,
                name: "name" + i,
                value: remotedata[i].count
            });
        }

        this.setState({ blocking: false });
        await this.buildChart(data);
    }

    async buildChart(data) {
        let self = this;
        let chart = am4core.create(this.props.id, am4charts.XYChart);

        chart.paddingRight = 20;
        chart.data = data;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";

        series.tooltipText = "{valueY.value}";
        chart.cursor = new am4charts.XYCursor();

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        chart.scrollbarX = scrollbarX;
        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.events.on("hit", function(event) {

            let dateFrom = event.target.dataItem.dates.dateX
            let dateFromStr = self.getDateString(dateFrom);

            let dateTill = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
            let dateTillStr = self.getDateString(dateTill);


            let externalOrInternal;
            if(self.props.filters.externalUsersOnly) externalOrInternal = "external";
            if(self.props.filters.internalUsersOnly) externalOrInternal = "internal";

            let uniqueUsers = self.props.filters.uniqueUsersCountOnly;
            
            let drilldownFilter = { 
                dateFrom: dateFromStr,
                dateTill: dateTillStr,
                externalOrInternal: externalOrInternal,
                engagementId: self.props.filters.engagementId,
                unique: uniqueUsers
            }

            window.open("/admin/analytics/table/" + JSON.stringify(drilldownFilter), "_blank");
          });

        this.chart = chart;
        this.chart.validateData();
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <BlockUIContainer blocking={this.state.blocking}>
                <div>
                    <div id={this.props.id} style={{ width: "100%", height: "500px" }}></div>
                </div>
            </BlockUIContainer>

        );
    }

    getDateString(date){
        let result = date.getFullYear() + "-";
        if(date.getMonth() + 1 < 10){
            result += "0" + (date.getMonth() + 1);
        } else{
            result += (date.getMonth() + 1);
        }
        
        result += "-";
        if(date.getDate() < 10) {
            result += "0" + date.getDate();
        } else{
            result += date.getDate();
        }
        return result;
    }
}

export default DailyUsageTrend;