import { httpClient } from '../../../common/httpClient';

const getAll = () => httpClient({
    url: `api/users/systemadmin`,
    method: "get",
});

const add = (user) => httpClient({
    url: `api/users/systemadmin`,
    method: "post",
    data: { user: user },
    headers: {
        "Content-Type": "application/json",
    }
})

const remove = (user) => httpClient({
    url: `api/users/systemadmin`,
    method: "delete",
    data: { user: user },
    headers: {
        "Content-Type": "application/json",
    }
})

export { getAll, add, remove };

