import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { searchEngagementRequest, searchDashboardRequest, searchChartRequest } from '../../redux/actions/search';
import * as routeHelper from '../../common/routeHelper';
import { Breadcrumbs } from '../breadcrumbs/BreadcrumbsComponent';
import styles from './TopBarComponent.module.css';
import { selectPreviewModeRequest } from '../../redux/actions/selectPreviewMode';
import { switchSlideshowModeRequest } from '../../redux/actions/switchSlideshowMode';
import { selectLayoutModeRequest } from '../../redux/actions/selectLayoutMode';
import { selectChartRequest } from '../../redux/actions/selectChart';
import { selectToggleModeRequest } from '../../redux/actions/selectToggleMode';
import { MyTooltip } from '../../components/MyTooltip';
import { setUseful, setNotUseful } from '../../modules/portal/services/charts';
import { toast } from 'react-toastify';

class TopBarComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            searchVisible: false,
            previewModalVisible: false,
            breadCrumbsItems: [],
            autoHideSearchTimer: 0
        };

        this.searchInputRef = React.createRef();
        this.onEnableSlideshow = this.onEnableSlideshow.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    componentWillReceiveProps(nextProps) {
        const payload = nextProps.breadcrumbsValue.payload;

        if (payload) {
            this.prepareBreadcrumbItems(payload)
        }
    }

    onRouteChanged() {
        let path = this.props.location.pathname

        if (routeHelper.isEngagementRoute(path)) {
            this.setState({ searchValue: this.props.searchEngagementValue });
        }
        else if (routeHelper.isDashboardRoute(path)) {
            this.setState({ searchValue: this.props.searchDashboardValue });
        }
        else if (routeHelper.isChartListRoute(path)) {
            this.setState({ searchValue: this.props.searchChartValue });
        }
        else {
            this.setState({ searchValue: '' });
        }

        if (this.searchInputRef.current != null)
            this.searchInputRef.current.focus();
    }


    homeClicked = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    }

    autoHideSearch() {
        const autoHideSearchTimer = setTimeout(() => {
            if (this.state.searchValue == '' && this.state.searchVisible) {
                this.setState(prevState => ({
                    searchVisible: !prevState.searchVisible
                }));
            }
        }, 5000)

        if (this.state.autoHideSearchTimer)
            clearTimeout(this.state.autoHideSearchTimer);

        this.setState({ autoHideSearchTimer })
    }

    onSearchClicked = (e) => {
        e.preventDefault();

        this.setState(prevState => ({
            searchVisible: !prevState.searchVisible
        }));

        setTimeout(() => this.searchInputRef.current.focus(), 100)

        if (!this.state.isSearchVisible) {
            const text = '';
            this.props.searchEngagement(text);
            this.props.searchDashboard(text);
            this.props.searchChart(text);

            this.setState({ searchValue: text });
        }
        this.autoHideSearch();

    }

    onSearchChanged = (e) => {

        const text = e.target.value;
        this.setState({ searchValue: text });

        let path = this.props.location.pathname
        setTimeout(() => {
            if (routeHelper.isEngagementRoute(path)) {
                this.props.searchEngagement(text);
            }
            else if (routeHelper.isDashboardRoute(path)) {
                this.props.searchDashboard(text);
            }
            else if (routeHelper.isChartListRoute(path)) {
                this.props.searchChart(text);
            }
            this.autoHideSearch();
        }, 300)
    }

    isSearchInputVisible() {
        return this.isSearchAvailableForCurrentScreen() && this.state.searchVisible
    }

    isSearchAvailableForCurrentScreen() {
        let path = this.props.location.pathname

        if (this.props.isSlideshowEnabled)
            return false;

        return routeHelper.isEngagementRoute(path)
            || routeHelper.isDashboardRoute(path)
            || routeHelper.isChartListRoute(path)

    }

    isPreviewAvailableForCurrentScreen() {

        if (this.props.selectedDashboardValue.payload == null)
            return false;

        if (!this.props.selectedDashboardValue.payload.allowsPreview)
            return false;

        if (this.props.isSlideshowEnabled)
            return false;

        return true;

    }

    isSlideshowAvailableForCurrentScreen() {

        if (this.props.selectedDashboardValue.payload == null)
            return false;

        if (this.props.isSlideshowEnabled)
            return false;

        return true;
    }

    setUsefulChartColor = () => {
        const { isUseful } = this.props.selectedChartValue.payload;

        if (isUseful)
            return `${styles['icon-link']} ${styles['thumb']}`

        if (isUseful === false)
            return `${styles['icon-link']}`

        else
            return `${styles['icon-link']}`
    }

    chartNotUsefulThumbColor = () => {
        if (this.props.selectedChartValue.payload) {
            const { isUseful } = this.props.selectedChartValue.payload;

            if (isUseful === false)
                return `${styles['icon-link']} ${styles['selected-thumb']}`;
        }
        return `${styles['icon-link']}`;
    }

    onPreviewToggle = () => {

        if (this.props.selectedPreviewModeValue === 'preview') {
            this.props.selectPreviewMode('full')
            this.props.selectToggleMode(false);
        }
        else {
            this.props.selectPreviewMode('preview')
            this.props.selectToggleMode(true);
        }
    }

    onEnableSlideshow() {

        this.props.switchSlideshowMode(true);
        this.props.selectLayoutMode('topBarDisabled')
    }

    onPrevChart = () => {
        const { dashboardId, engagementId, prevId } = this.props.selectedChartValue.payload;

        if (!prevId)
            return;

        this.props.history.push(`/engagements/${engagementId}/dashboards/${dashboardId}/charts/${prevId}`)
    }

    onNextChart = () => {
        const { dashboardId, engagementId, nextId } = this.props.selectedChartValue.payload;

        if (!nextId)
            return;

        this.props.history.push(`/engagements/${engagementId}/dashboards/${dashboardId}/charts/${nextId}`)
    }

    prepareBreadcrumbItems(breadcrumbs) {
        let items = [];

        let path = this.props.location.pathname
        if (routeHelper.isDashboardRoute(path)) {
            items.push({ url: path, title: breadcrumbs.engagementName, iconClass: '', isActive: true });
        }
        else if (routeHelper.isChartListRoute(path)) {
            items.push({ url: routeHelper.getDashboardsRoute(path), title: breadcrumbs.engagementName, iconClass: '', isActive: false });
            items.push({ url: path, title: breadcrumbs.dashboardName, iconClass: '', isActive: true });

            if (this.props.selectedToggleModeValue === true) {
                this.props.selectPreviewMode('preview');
            }
            else {
                this.props.selectPreviewMode('full');
            }
        }
        else if (routeHelper.isChartDetailsRoute(path)) {
            items.push({ url: routeHelper.getDashboardsRoute(path), title: breadcrumbs.engagementName, iconClass: '', isActive: false });
            items.push({ url: routeHelper.getChartsListRoute(path), title: breadcrumbs.dashboardName, iconClass: '', isActive: false });
            items.push({ url: path, title: breadcrumbs.chartName, iconClass: '', isActive: true });
        }

        this.setState({ breadCrumbsItems: items });
    }

    setChartUsefulnes = async (isChartUseful) => {

        const chart = { ...this.props.selectedChartValue.payload };
        const { id } = this.props.selectedChartValue.payload;

        try {
            if (isChartUseful) {
                await setUseful(id);
                chart.isUseful = chart.isUseful ? null : true;

                this.props.selectChart(chart);
                toast.info(`Thank you for your feedback`)
            }
            else {
                await setNotUseful(id);
                chart.isUseful = chart.isUseful === false ? null : false

                this.props.selectChart(chart);
                toast.info(`Thank you for your feedback`)
            }
        }

        catch (error) {
            if (error && error.data && error.data.error) {
                toast.error(error.data.error);
                return
            }

            toast.error('Something went wrong');
        }
    }

    onPrint = () => {
        // const chart = { ...this.props.selectedChartValue.payload };
        // if (chart == null)
        //     return null;

        // const url = chart.url;

        // var proxyIframe = document.createElement('iframe');
        // var body = document.getElementsByTagName('body')[0];
        // body.appendChild(proxyIframe);

        // proxyIframe.style.width = '100%';
        // proxyIframe.style.height = '100%'
        // proxyIframe.style.display = 'none';

        // var contentWindow = proxyIframe.contentWindow;
        // contentWindow.document.open();
        // contentWindow.document.write('<iframe id="proxyIframe" src="' + url + '" width="1800" onload="setTimeout(() => print(), 1500);" height="1000" frameborder="0" marginheight="0" marginwidth="0">');
        // setTimeout(() => proxyIframe.remove(), 3000);
        // contentWindow.document.close();
        const iframeWin = document.getElementById("chart-iframe").contentWindow;
        iframeWin.postMessage('print', '*')// childwin is the targetWindow
        setTimeout(() => {
            window.print();
        }, 500)
    }

    renderSearchButton() {

        let searchInput = <input ref={this.searchInputRef} type="text" onChange={this.onSearchChanged} value={this.state.searchValue}
            hidden={!this.isSearchAvailableForCurrentScreen() || !this.isSearchInputVisible()} />

        let path = this.props.location.pathname
        if (routeHelper.isChartDetailsRoute(path))
            return null;

        return <span className={styles['search-link']}>
            {searchInput}
            <i onClick={this.onSearchClicked} >
                <MyTooltip tooltip="Search" placement='bottom'>
                    <i className="icon-Vector">
                    </i>
                </MyTooltip>
            </i>
        </span>
    }

    renderEngagementLogo() {

        const CircleImage = ({ imageUrl }) => {
            return (
              <div className={styles['circle-image']} style={{backgroundColor: this.state.logoColor}}>
                <div className={styles['circle-squer']} >
                    <img src={imageUrl} alt="Icon" className={styles['circle-img']} />
                </div>
              </div>
            );
          };

        let iconName = this.props.iconName;        
        let testDashboard = this.state.breadCrumbsItems[0]
        // if(testDashboard != null)
        // console.log('breadcumber: ', JSON.parse(testDashboard));
        let icon = iconName ?
        <img src={`/api/staticresources/icons/${iconName}/EngagementIcons`}
        style={{ width: 30, height: 30 }} />
        : null;
        // <CircleImage imageUrl={`/api/staticresources/icons/${iconName}/EngagementIcons`} />
        // : 
        // <div className={styles['single-engagement-logo']} style={{backgroundColor: this.state.logoColor}}>
        //     <p className={styles['single-engagement-logo-text']}>
        //         {/* {testDashboard.slice(0,2).toUpperCase()} */}
        //     </p>
        // </div>



        let path = this.props.location.pathname;
        if (routeHelper.isEngagementRoute(path))
            return null;

        return <span className={styles['search-link']}>      
            {icon}
        </span>
    }

    /* renderExportButton() {
        let path = this.props.location.pathname
        if (!(routeHelper.isChartListRoute(path) || routeHelper.isChartDetailsRoute(path)))
            return null;

        return (
            <span className={`${styles['icon-link']} ${styles['export-icon-container']}`} onClick={this.onExport}>
                <MyTooltip tooltip="Export" placement='bottom'>
                    <i className='icon-Vector-1'>
                    </i>
                </MyTooltip>
            </span>
        )
    } */


    renderPrintButton() {
        let path = this.props.location.pathname
        if (!routeHelper.isChartDetailsRoute(path))
            return null;

        return (
            <span className={`${styles['icon-link']} ${styles['print-icon-container']}`} onClick={this.onPrint}>
                <MyTooltip tooltip="Print chart content" placement='bottom'>
                    <i className='icon-printer'>
                    </i>
                </MyTooltip>
            </span>
        )
    }


    renderSlideshowOptions() {

        if (!this.isSlideshowAvailableForCurrentScreen())
            return null;

        return (
            <span className={`${styles['icon-link']} ${styles['slideshow-icon-container']}`} onClick={this.onEnableSlideshow}>
                <MyTooltip tooltip="Slideshow" placement='bottom'>
                    <i className='icon-Vector-2'>
                    </i>
                </MyTooltip>
            </span>
        )
    }

    renderPreviewOptions() {

        if (!this.isPreviewAvailableForCurrentScreen())
            return null;

        const icon = this.props.selectedPreviewModeValue === 'preview'
            ? 'icon-Vector-4'
            : 'icon-Vector-3';

        return (
            <span className={`${styles['icon-link']} ${styles['preview-icon-container']}`} onClick={this.onPreviewToggle}>
                <MyTooltip tooltip="Toggle" placement='bottom'>
                    <i className={icon}>
                    </i>
                </MyTooltip>
            </span>
        )
    }

    renderPrevNextButtons() {
        let path = this.props.location.pathname
        if (!routeHelper.isChartDetailsRoute(path))
            return null;

        return <>
            <span className={`${styles['icon-link']}`} onClick={this.onPrevChart}>
                <MyTooltip tooltip='Previous chart' placement='bottom'>
                    <i className='icon-arrow-left'>
                    </i>
                </MyTooltip>
            </span>
            <span className={`${styles['icon-link']}`} onClick={this.onNextChart}>
                <MyTooltip tooltip='Next chart' placement='bottom'>
                    <i className='icon-arrow-right'>
                    </i>
                </MyTooltip>
            </span>
        </>
    }

    renderIsChartUseful() {
        const path = this.props.location.pathname
        if (!routeHelper.isChartDetailsRoute(path))
            return null;

        return <>
            <div className={styles['useful-container']}>
                <span>Is this Chart useful?</span>
            </div>
        </>
    }

    chartUsefulThumbColor = () => {
        if (this.props.selectedChartValue.payload) {
            const { isUseful } = this.props.selectedChartValue.payload;

            if (isUseful)
                return `${styles['icon-link']} ${styles['selected-thumb']}`;

            return `${styles['icon-link']}`;
        }
        return `${styles['icon-link']}`;
    }

    renderUsefulNotUsefulButtons() {

        let path = this.props.location.pathname
        if (!routeHelper.isChartDetailsRoute(path))
            return null;

        return <>
            <span className={this.chartUsefulThumbColor()} onClick={() => this.setChartUsefulnes(true)}>
                <i className='icon-outline-thumb_up_alt-24px'>
                </i>
            </span>
            <span className={this.chartNotUsefulThumbColor()} onClick={() => this.setChartUsefulnes(false)}>
                <i className='icon-outline-thumb_down_alt-24px'>
                </i>
            </span>
            <div className={`${styles['divider']}`}></div>
        </>
    }

    render() {
        const isAdminPanel = this.props.location.pathname.indexOf('admin') != -1;
        if (isAdminPanel) {
            return <div className={styles['main-container']}>
                <div className={styles['content-container']}></div>
            </div>
        }

        return (
            <div className={styles['main-container']}>
                <div className={styles['content-container']}>
                    <Breadcrumbs items={this.state.breadCrumbsItems} />
                    <div id={styles['topbar-options-container']}>
                        {this.renderIsChartUseful()}
                        {this.renderUsefulNotUsefulButtons()}
                        {this.renderSearchButton()}
                        {/* {this.renderExportButton()} */}
                        {this.renderPrintButton()}
                        {this.renderSlideshowOptions()}
                        {this.renderPreviewOptions()}
                        {this.renderPrevNextButtons()}
                        {this.renderEngagementLogo()}
                    </div>
                </div>
            </ div>
        );
    }
}

const mapStateToProps = state => {
    return {
        searchEngagementValue: state.searchEngagement.searchText,
        searchDashboardValue: state.searchDashboard.searchText,
        searchChartValue: state.searchChart.searchText,
        selectedDashboardValue: state.selectedDashboard,
        selectedPreviewModeValue: state.selectedPreviewMode,
        isSlideshowEnabled: state.switchSlideshowMode,
        selectedChartValue: state.selectedChart,
        selectedToggleModeValue: state.selectedToggleMode,
        breadcrumbsValue: state.breadcrumbs,
        iconName: state.engagementIcon
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchEngagement: (text) => {
            dispatch(searchEngagementRequest(text))
        },
        searchDashboard: (text) => {
            dispatch(searchDashboardRequest(text))
        },
        searchChart: (text) => {
            dispatch(searchChartRequest(text))
        },
        selectPreviewMode: (mode) => {
            dispatch(selectPreviewModeRequest(mode))
        },
        selectLayoutMode: (mode) => {
            dispatch(selectLayoutModeRequest(mode))
        },
        switchSlideshowMode: (enabled) => {
            dispatch(switchSlideshowModeRequest(enabled))
        },
        selectChart: (chart) => {
            dispatch(selectChartRequest(chart))
        },
        selectToggleMode: (mode) => {
            dispatch(selectToggleModeRequest(mode))
        }
    }

}

export const TopBar = withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBarComponent))