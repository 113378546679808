import * as React from 'react';
import SubmitButton from '../common/SubmitButton';
import CancelButton from '../common/CancelButton';
import { sendReportedMessage } from '../../../modules/portal/services/reportIssue';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { toggleSidebarOptionRequest } from '../../../redux/actions/toggleSidebarOption';
import webAppConfig from '../../../config';
import authenticationTypes from '../../../common/const/authenticationTypes';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

class ReportIssueComponent extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isBlocked: false,
            textareaMessage: '',
            attachment: null
        }
    }

    handleTextareaChange = (e) => {
        const value = e.target.value
        this.setState({
            textareaMessage: value
        })
    }

    handleFileChange = (e) => {
        const value = e.target.files[0];
        this.setState({
            attachment: value
        })
    }

    sendMessage = async () => {

        if (this.state.isBlocked)
            return;

        this.setState({ isBlocked: true })
        try {
            await sendReportedMessage({
                message: this.state.textareaMessage,
                pageUrl: window.location.href,
                attachment: this.state.attachment
            })
            toast.info("Message sent");
            this.props.onFinish()
        }
        catch (error) {
            if (error && error.data && error.data.error) {
                toast.error(error.data.error);
            }
        }
        finally {
            this.setState({ isBlocked: false })
        }
    }

    closeSideBar = async () => {
        this.props.toggleSidebarOption('close');
    }

    renderManualEmailSending() {
        const href = `mailto:${this.props.mailConfig.reportAnIssueMailbox}?subject=Found an issue by user: ${this.props.username}`

        return (
            <div>
                <h5>
                    Do you want to Report an Issue/Improvement?
                </h5>
                <p>Please reachout to:</p>
                <a href={href}>{this.props.mailConfig.reportAnIssueMailbox}</a>
            </div>
        );
    }

    renderAttachmentButton() {
        const currentUser = this.props.username.split('-');            
        if (currentUser[0].toLocaleLowerCase() === "X".toLocaleLowerCase())
            return;
    
        return (
            <input 
            type="file"
            onChange={(e) => this.handleFileChange(e)}
            style={{ paddingTop: 30, display: 'inline' }}
            // className="form-control-file btn btn-outline-secondary btn-sm mr-2"
        />
        )
    }

    render() {        
        if (webAppConfig.authenticationType === authenticationTypes.Custom)
            return this.renderManualEmailSending();            
        return (
            <div>
                <h5>
                    Do you want to Report an Issue/Improvement?
                </h5>
                <textarea
                    placeholder='Write it down'
                    value={this.state.textareaMessage}
                    onChange={(e) => this.handleTextareaChange(e)}
                />                
                {this.renderAttachmentButton()                }
                {
                    this.state.isBlocked
                        ? <LoadingIndicator />
                        : <div style={{ marginTop: '30px' }}>
                            <div style={{ display: 'inline' }}>
                                <CancelButton handleClick={this.closeSideBar} />
                                <SubmitButton handleClick={this.sendMessage} />
                            </div>
                        </div>
                }
            </div>
        )
    }
}

ReportIssueComponent.propTypes = {
    onFinish: PropTypes.func,
    mailConfig: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        sideBarOptionState: state.sideBarOptionState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSidebarOption: (mode) => {
            dispatch(toggleSidebarOptionRequest(mode))
        }
    }
}

export const ReportIssue = connect(mapStateToProps, mapDispatchToProps)(ReportIssueComponent);

