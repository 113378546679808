import axios from "axios";

const DEFAULT_CONTENT_TYPE = "application/json";

const getRandomSecurityQuestion =
    (username, email) =>
        axios.get(`/api/account/forgotPassword?username=${username}&email=${email}`);

const resetPassword = (resetPasswordViewModel) => {
    const config = {
        headers: {
            'Content-Type': DEFAULT_CONTENT_TYPE
        }
    }

    return axios.post('/api/account/forgotPassword', resetPasswordViewModel, config);
}

export {
    getRandomSecurityQuestion,
    resetPassword
}
