import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { AdminEngagements } from "./engagements";
import { AdminDashboards } from './dashboards';
import { AdminCharts } from './charts';
import { SystemAdministratorsComponent } from './systemAdministrators/SystemAdministratorsComponent';
import { UploadComponent } from './upload/UploadComponent';
import { AdminPanelMaster } from './master/AdminPanelMasterComponent';
import withRestrictedAccess from '../../components/withRestrictedAccess'
import { Administrator, ROLES } from "../../auth/userRoles";
import { EventTrackerComponent } from './eventTracker/EventTrackerComponent';
import { UserAccountsComponent } from './userAccounts/UserAccountsComponents';
import AnalyticsMasterComponent from './analytics/AnalyticsMasterComponent';
import ChartUsefulStatisticsMasterComponent from './chartUsefulStatistics/ChartUsefulStatisticsMasterComponent';
import { MobileSettingsComponent } from './mobileSettings/MobileSettingsComponent';
import { ReportIssueListcomponent } from './reportIssue/reportIssueList/ReportIssueListComponent';

import webAppConfig from '../../config';
import authenticationTypes from '../../common/const/authenticationTypes';

class AdminPanelComponent extends Component {

    render() {
        return (
            <Switch>
                <Route exact path='/admin' render={props => <AdminPanelMaster {...this.props} />} />
                <Route path='/admin/engagements' render={props => <AdminEngagements {...props} />} />
                <Route path='/admin/dashboards' render={props => <AdminDashboards {...props} />} />
                <Route path='/admin/charts' render={props => <AdminCharts {...props} />} />
                <Route path='/admin/systemAdmins' render={props => <SystemAdministratorsComponent {...props} />} />
                {webAppConfig.authenticationType === authenticationTypes.Custom &&
                    <Route path='/admin/userAccounts' render={props => <UserAccountsComponent {...props} />} />
                }
                <Route path='/admin/upload' render={props => <UploadComponent {...props} />} />
                <Route path='/admin/eventTracker' render={props => <EventTrackerComponent {...props} />} />
                <Route path='/admin/analytics' render={props => <AnalyticsMasterComponent {...props} />} />
                <Route path='/admin/chartUsefulStatistics' render={props => <ChartUsefulStatisticsMasterComponent {...props} />} />
                <Route path='/admin/mobileSettings' render={props => <MobileSettingsComponent {...props} />} />
                <Route path='/admin/reportIssue' render={props => <ReportIssueListcomponent {...props} />} />
            </Switch>
        )
    }
}

export const AdminPanel = withRestrictedAccess(ROLES[Administrator])(AdminPanelComponent)

