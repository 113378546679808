import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from 'moment';
import DailyUsageTrend from './DailyUsageTrend';
import { Row, Col, Form, FormGroup } from 'react-bootstrap';
import { getAll } from '../../services/engagements';
import Switch from "react-switch";

am4core.useTheme(am4themes_animated);

const defaultDateFormat = 'YYYY-MM-DD';

class AnalyticsDasboardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: {
                startDate: this.getDefaultStartDate(),
                endDate: this.getDefaultEndDate(),
                uniqueUsersCountOnly: false,
                engagementId: '',
            },
            engagements: []
        };
    }

    async componentDidMount() {
        let engagements = (await getAll()).data;
        this.setState({ engagements: engagements });
    }

    getDefaultStartDate() {
        let x = new Date()
        x.setDate(x.getDay() - 30);
        return moment(x).format(defaultDateFormat);
    }

    getDefaultEndDate() {
        return moment().format(defaultDateFormat);
    }


    onFiltersChange = async (e) => {
        const type = e.target.name
        const value = e.target.value;

        let currentFilters = JSON.parse(
            JSON.stringify(this.state.filters)
        )
        currentFilters[type] = value;

        this.setState({
            filters: currentFilters
        })
    }

    onUniqueUsersOnlySwitchChange = (checked) => {
        let currentFilters = JSON.parse(
            JSON.stringify(this.state.filters)
        )

        currentFilters['uniqueUsersCountOnly'] = checked;

        this.setState({
            filters: currentFilters
        })
    }

    renderEngagementOptions() {
        return this.state.engagements.map(x => {
            return <option key={x.id} value={x.id}>{`${x.id} - ${x.displayName} `}</option>
        })
    }

    render() {
        let internalUsersOnlyFilter = { ...this.state.filters, internalUsersOnly: true }
        let externalUsersOnlyFilter = { ...this.state.filters, externalUsersOnly: true }
        return (
            <div>
                <h4>Daily Usage Trend</h4>
                <Row>
                    <Col xs={12} lg={3} md={6}>
                        <Form.Group>
                            <Form.Label>EngagementId</Form.Label>
                            <Form.Control as="select"
                                name="engagementId"
                                onChange={this.onFiltersChange}
                                value={this.state.filters.engagementId}
                            >
                                <option value="">All</option>
                                {this.renderEngagementOptions()}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={3} md={6}>
                        <Form.Group>
                            <Form.Label>Since date</Form.Label>
                            <Form.Control type="date"
                                name="startDate"
                                value={this.state.filters.startDate}
                                onChange={this.onFiltersChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={3} md={6}>
                        <Form.Group>
                            <Form.Label>Till date</Form.Label>
                            <Form.Control type="date"
                                name="endDate"
                                value={this.state.filters.endDate}
                                onChange={this.onFiltersChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={3} md={6}>
                        <Form.Group>
                            <Form.Label>Unique Users Only</Form.Label>
                            <div>
                                <Switch
                                    onChange={this.onUniqueUsersOnlySwitchChange}
                                    checked={this.state.filters.uniqueUsersCountOnly}
                                    className="react-switch"
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <h6>All users</h6>
                <DailyUsageTrend key={JSON.stringify(this.state.filters)} id="dailyTrendUsageAll" filters={this.state.filters} />
                <h6>Internal users only</h6>
                <DailyUsageTrend key={JSON.stringify(internalUsersOnlyFilter)} id="dailyTrendUsageInternal" filters={internalUsersOnlyFilter} />
                <h6>External users only</h6>
                <DailyUsageTrend key={JSON.stringify(externalUsersOnlyFilter)} id="dailyTrendUsageExternal" filters={externalUsersOnlyFilter} />
            </div>
        );
    }
}

export default AnalyticsDasboardComponent;