import React, { Component } from 'react';
import Table from './Table';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { PeriodConstants } from './helpers';
import { Form} from 'react-bootstrap';
import { NavLink } from "react-router-dom";

import styles from './AnalyticsTableComponent.scss';
class AnalyticsTableComponent extends Component {

    constructor(props) {
        super(props);
        let drilldownUsersExternalOrInternal;
        let engagementId;
        let unique;

        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        var startDateString = this.formatDate(startDate);

        let endDate = new Date();
        var endDateString = this.formatDate(endDate);

        let drilldownFilters = props.params.drilldownFilters;
        
        if(drilldownFilters){
            let filters = JSON.parse(drilldownFilters);

            startDateString = this.formatDate(new Date(filters.dateFrom));
            endDateString = this.formatDate(new Date(filters.dateTill));
            drilldownUsersExternalOrInternal = filters.externalOrInternal;
            engagementId = filters.engagementId;
            unique = filters.unique;
        }

        this.state = {
            period: "last-day", //NOT USING THE ONE FROM DRILLDOWNFILTERS YET - TO FIRE CHANGE/EVENT WITH COMPONENTDIDMOUNT
            startDate: startDateString,
            endDate: endDateString,
            drilldownFilters: drilldownFilters,
            drilldownUsersExternalOrInternal: drilldownUsersExternalOrInternal,
            engagementId: engagementId,
            unique: unique
        };

    }

    onChange = (value) => {
        this.setState({ period: value });
    }

    componentDidMount() {
        if(this.props.params.drilldownFilters){
            this.setState({ period: "custom" });
        }
    }

    render() {
        return (
            <div>
                <h4 style={{ color: 'gray', fontSize: 18 }}>Application Usage Analytics</h4>
                <br />
                <RadioGroup onChange={this.onChange} value={this.state.period} horizontal>
                    <RadioButton value={PeriodConstants.LastDay} pointColor='#0070ad' rootColor="#c7c7c7">
                        Last 24 Hours
                    </RadioButton>
                    <RadioButton value={PeriodConstants.LastWeek} pointColor='#0070ad' rootColor="#c7c7c7">
                        Last 7 Days
                    </RadioButton>
                    <RadioButton value={PeriodConstants.LastMonth} pointColor='#0070ad' rootColor="#c7c7c7">
                        Last 30 Days
                    </RadioButton>
                    <RadioButton value={PeriodConstants.Custom} pointColor='#0070ad' rootColor="#c7c7c7">
                        Custom
                    </RadioButton>
                    <RadioButton value={PeriodConstants.All} pointColor='#0070ad' rootColor="#c7c7c7">
                        All
                    </RadioButton>
                </RadioGroup>
                {this.state.period == "custom" &&
                <div>
                    <div className="col-md-3" style={{ display: 'inline-block' }}>
                        <Form.Group >
                            <Form.Label>Since date</Form.Label>
                            <Form.Control type="date"
                                name="startDate"
                                value={this.state.startDate}
                                onChange={this.onStartDateChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3"  style={{ display: 'inline-block' }}>
                        <Form.Group >
                        <Form.Label>Till date</Form.Label>
                            <Form.Control type="date"
                                name="endDate"
                                value={this.state.endDate}
                                onChange={this.onEndDateChange}
                            />
                        </Form.Group>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        {this.state.drilldownFilters && 
                        <NavLink className="tile" onClick={this.clearFilters} to={`/admin/analytics/table`}>
                            CLEAR FILTERS 
                        </NavLink>
                        }
                    </div>
                </div>
                }
                
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <Table 
                        period={this.state.period}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate} 
                        drilldownUsersExternalOrInternal={this.state.drilldownUsersExternalOrInternal}
                        engagementId={this.state.engagementId}
                        unique={this.state.unique}
                        />
                </div>
            </div>);
    }

    clearFilters(){
        this.setState({
            drilldownFilters: undefined,
            period: "last-day"
        });

    }
    onStartDateChange = (e) =>{
        const val = e.target.value;
        this.setState({startDate: val})
    }

    onEndDateChange = (e) =>{
        const val = e.target.value;
        this.setState({endDate: val})
    }

    formatDate(d){
        let day; let month; let year;
        
        if(d.getDate() < 10 ){
            day = "0" + d.getDate();
        } else {
            day = d.getDate();
        }

        if(d.getMonth()+1 < 10){
            month = "0" + (d.getMonth()+1);
        } else{
            month = d.getMonth()+1;
        }

        year = d.getFullYear();
        return year  + "-" + month + "-" + day
    }
}

export default AnalyticsTableComponent;