import client from "./axiosAuthClient";

const getCurrentUser = () => client({
    url: `api/users/current`,
    method: "get",
    headers: {
        "Content-Type": "application/json",
    }
});

const login = (username, password) => client({
    url: `api/account/generateToken`,
    method: "post",
    data: {
        userName: username,
        password: password
    },
    headers: {
        "Content-Type": "application/json",
    }
});

const logout = () => {
    localStorage.removeItem('sso.token');
}

export { getCurrentUser, login, logout }