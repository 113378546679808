import React, { Component,useState } from 'react';
// import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getMonthlyUsageTrend } from '../../services/analytics';
import { BlockUIContainer } from '../../../../components/BlockUIContainer';

am4core.useTheme(am4themes_animated);

class MonthlyUsageTrend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            engagements: [],
            blocking: true,
            showModal: false
        };

    }

    
    async componentDidMount() {
        await this.loadChart(this.props.filters);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props) === JSON.stringify(prevProps)) {
            return;
        }

        this.setState({ blocking: true });

        if (this.chart) {
            this.chart.dispose();
        }
        await this.loadChart(this.props.filters);
    }

    async loadChart(filters) {
        let externalUsers = (await getMonthlyUsageTrend({ ...filters, internalUsersOnly: false, externalUsersOnly: true })).data;
        let internalUsers = (await getMonthlyUsageTrend({ ...filters, internalUsersOnly: true, externalUsersOnly: false })).data;

        // console.log(externalUsers);
        // console.log(internalUsers);
        let data = [];
        for (let i = 0; i <= externalUsers.length - 1; i++) {
            data.push({
                category: externalUsers[i].date,
                external: externalUsers[i].count,
                internal: internalUsers[i].count
            });
        }

        this.setState({ blocking: false });
        await this.buildChart(data);
    }

    async buildChart(data) {
        var self = this; //for showModal in inner function
        let chart = am4core.create(this.props.id, am4charts.XYChart);

        chart.legend = new am4charts.Legend()
        chart.legend.position = 'top'
        chart.legend.paddingBottom = 20
        chart.legend.labels.template.maxWidth = 140;

        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        xAxis.dataFields.category = 'category'
        xAxis.renderer.cellStartLocation = 0.1
        xAxis.renderer.cellEndLocation = 0.9
        xAxis.renderer.grid.template.location = 0;

        var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;



        function createSeries(value, name) {
            let series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueY = value
            series.dataFields.categoryX = 'category'
            series.name = name

            series.events.on("hidden", arrangeColumns);
            series.events.on("shown", arrangeColumns);
            series.columns.template.events.on("hit", function(event) {

                let monthDateStr = self.getDateFromMonthValue(event.target.dataItem.categoryX);

                let dateFrom = new Date(monthDateStr);
                let dateTill = new Date(dateFrom.getFullYear(), dateFrom.getMonth()+1, dateFrom.getDate());
                let dateTillStr = self.getDateString(dateTill);
                
                let externalOrInternal = value;
                let uniqueUsers = self.props.filters.uniqueUsersCountOnly;
                let drilldownFilter = { 
                    dateFrom: monthDateStr,
                    dateTill:dateTillStr,
                    externalOrInternal: externalOrInternal,
                    engagementId: self.props.filters.engagementId,
                    unique: uniqueUsers
                }

                //self.showModal();
                window.open("/admin/analytics/table/" + JSON.stringify(drilldownFilter), "_blank");
              });


            let bullet = series.bullets.push(new am4charts.LabelBullet())
            bullet.interactionsEnabled = false
            bullet.dy = -10;
            bullet.label.text = '{valueY}'
            bullet.label.fill = am4core.color('#000')
            series.columns.template.tooltipText = "{name} : {valueY}";
            return series;
        }

        chart.data = data;

        createSeries("internal", "Internal Users");
        createSeries("external", "External Users");

        function arrangeColumns() {

            let series = chart.series.getIndex(0);

            let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                let delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    let middle = chart.series.length / 2;

                    let newIndex = 0;
                    chart.series.each(function (series) {
                        if (!series.isHidden && !series.isHiding) {
                            series.dummyData = newIndex;
                            newIndex++;
                        }
                        else {
                            series.dummyData = chart.series.indexOf(series);
                        }
                    })
                    let visibleCount = newIndex;
                    let newMiddle = visibleCount / 2;

                    chart.series.each(function (series) {
                        let trueIndex = chart.series.indexOf(series);
                        let newIndex = series.dummyData;

                        let dx = (newIndex - trueIndex + middle - newMiddle) * delta

                        series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                        series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                    })
                }
            }
        }
        this.chart = chart;
        this.chart.validateData();
    }






    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        

        return (
            <BlockUIContainer blocking={this.state.blocking}>
                <div>
                    <div id={this.props.id} style={{ width: "100%", height: "500px" }}></div>
                </div>
            </BlockUIContainer>
        );
    }

    getDateFromMonthValue(monthString){
        let spliced = monthString.split(" ");

        return spliced[1] + "-" + spliced[0] + "-" + "01";
    }

    getDateString(date){
        let result = date.getFullYear() + "-";
        if(date.getMonth() + 1 < 10){
            result += "0" + (date.getMonth() + 1);
        } else{
            result += (date.getMonth() + 1);
        }
        
        result += "-";
        if(date.getDate() < 10) {
            result += "0" + date.getDate();
        } else{
            result += date.getDate();
        }
        return result;
    }
}

export default MonthlyUsageTrend;