import { USER_SIGNED_IN } from '../constants/ActionTypes'

const authenticateUserReducer = (state = false, action) => {

    switch (action.type) {
        case USER_SIGNED_IN:
            return action.isSigned;
        default:
            return state;
    }
}

export default authenticateUserReducer;