import * as React from 'react';
import { ChartTile } from '../chartTile/ChartTile';
import { ChartsLivePreviewComponent } from '../chartsLivePreview/ChartsLivePreviewComponent';
import { getAll, getAllTags } from '../../services/charts';
import { colors, tagColors } from '../../../shared/shared'
import { connect } from 'react-redux';

import styles from './ChartsListComponent.module.scss'
import { selectDashboardRequest } from '../../../../redux/actions/selectDashboard';
import { selectPreviewModeRequest } from '../../../../redux/actions/selectPreviewMode'
import { getById } from '../../services/dashboards';
import { getById as getEngagementById } from '../../services/engagements';
import * as strHelper from '../../../../common/stringHelper';
import { NoEntries } from '../../../../components/NoEntriesComponent';
import ChartsSlideshowComponent from '../chartsSlideshow/ChartsSlideshowComponent';
import { switchSlideshowModeRequest } from '../../../../redux/actions/switchSlideshowMode';
import { selectLayoutModeRequest } from '../../../../redux/actions/selectLayoutMode';
import { selectDashboardTags } from '../../../../redux/actions/selectDashboardTags';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { NoAccess } from '../../../../pages/NoAccess';
import { searchChartRequest } from '../../../../redux/actions/search';
import { selectChartRequest } from '../../../../redux/actions/selectChart';
import { breadcrumbsRequest } from '../../../../redux/actions/breadcrumbs';
import { setEngagementIconRequest } from '../../../../redux/actions/setEngagementIcon';

class ChartsListComponent extends React.Component {

    constructor(props) {

        super(props)

        this.state = {

            isLoaded: false,
            isForbidden: false,
            tags: [],
            data: [],
            appliedTags: [],
            allowsPreview: true,
            dashboardId: this.props.match.params.dashboardId
        }
    }

    async componentDidMount() {
        try {

            const payload = {...this.props.selectDashboardTagsValue.payload} 
            const dashboardId = this.props.match.params.dashboardId;
            const engagementId = this.props.match.params.engagementId;

            const values = await Promise.all([this.fetchEngagement(engagementId), this.fetchDashboard(dashboardId), this.fetchCharts(dashboardId), this.fetchTags(dashboardId)]);

            const engagement = values[0];
            const dashboard = values[1];
            const charts = values[2];
            const tags = values[3];
    
            const appliedTagsFromPayload = Object.keys(payload).map((key) => {
                return payload[key];
            });

            this.setState({
                isLoaded: true,
                tags: tags,
                data: charts,
                tagColorMap: this.mapColorToTags(),
                allowsPreview: dashboard.isLivePreviewEnabled,
                dashboardId: dashboard.id,
                dashboard: dashboard,
                engagement: engagement,
                appliedTags: appliedTagsFromPayload
            })
            this.props.selectDashboard({ id: dashboard.id, allowsPreview: dashboard.isLivePreviewEnabled })

            this.props.setEngagementIcon(this.state.engagement.iconName);

            this.props.changeBreadcrumbs({
                engagementName: dashboard.engagementName, 
                dashboardName: dashboard.displayName, 
                chartName: ""})
        }
        catch (ex) {
            if (ex.status === 403) {
                this.setState({ isForbidden: true, isLoaded: true })
            }
        }
    }

    async fetchEngagement(engagementId) {
        const engagementFetch = await getEngagementById(engagementId);
        return engagementFetch.data;
    }

    async fetchDashboard(dashboardId) {

        const dashboardFetch = await getById(dashboardId);
        return dashboardFetch.data;
    }

    async fetchCharts(dashboardId) {

        const chartsFetch = await getAll(dashboardId);
        return chartsFetch.data;
    }

    async fetchTags(dashboardId) {

        const tagsFetch = await getAllTags(dashboardId);
        return tagsFetch.data;
    }

    mapColorToTags() {

        const tagColorMap = [];
        this.state.tags.map((el, index) => {
            const color = tagColors[index % tagColors.length];
            tagColorMap.push({ tagId: el.id, color: color });
        });

        return tagColorMap;
    }

    getTagColor(tagColorMap, tagId) {

        const obj = tagColorMap.find(x => x.tagId == tagId);

        return obj != undefined ? obj.color : 'gray';
    }


    applyTag(el) {
   
        let appliedTagsCopy = Object.assign([], this.state.appliedTags)

        if (appliedTagsCopy.map(c => c.id).includes(el.id))
            appliedTagsCopy = appliedTagsCopy.filter(c => c.id !== el.id);
        else
            appliedTagsCopy.push(el);

        this.setState({
            appliedTags: appliedTagsCopy
        })

        this.props.selectDashboardTagsMode(appliedTagsCopy);
    }

    filterData() {

        let data = Object.assign([], this.state.data)

        if (this.props.searchText != '' && this.props.searchText != undefined) {
            data = data.filter(x => strHelper.containsText(x, this.props.searchText));
        }

        let filteredData = [];

        if (this.state.appliedTags.length == 0)
            return data;

        this.state.appliedTags.forEach((tag) => {

            let matchingCharts = data.filter(c => c.tags.map(x => x.id).includes(tag.id))

            matchingCharts.forEach((el) => {

                if (!filteredData.map(x => x.id).includes(el.id))
                    filteredData.push(el)
            })
        })

        return filteredData;
    }

    navigateToChartDetail(chartId) {

        this.props.history.push(`${this.props.match.params.dashboardId}/charts/${chartId}`)        
    }

    renderCharts(data) {

        if (data.length === 0) {
            return <NoEntries />
        }

        if (this.props.selectedPreviewModeValue == 'preview')
            return <ChartsLivePreviewComponent {...this.props} tags={this.state.tags} data={data} />

        const tagColorMap = this.mapColorToTags();

        return data.map((el, index) => {

            const color = el.tags.length > 0 ? this.getTagColor(tagColorMap, el.tags[0].id) : 'gray';
            const secondColor = el.tags.length > 1 ? this.getTagColor(tagColorMap, el.tags[1].id) : 'transparent';

            return (
                <ChartTile
                    key={el.id}
                    title={el.displayName}
                    firstTagColor={color}
                    secondTagColor={secondColor}
                    icon={el.iconName}
                    onClick={() => this.navigateToChartDetail(el.id)}
                />
            )
        })
    }

    renderTags() {

        let reversedTagsArray = [...this.state.tags].reverse()
        
        return reversedTagsArray.map((el, index) => {

            let isSelected = false;

            let color = tagColors[(reversedTagsArray.length -1 - index) % colors.length]

            if (this.state.appliedTags.map(el => el.id).includes(el.id))
                isSelected = true;

            let name = el.name.length > 14
                ? el.name.slice(0, 12) + '..'
                : el.name

            return (
                <div
                    key={el.id}
                    className={`${styles['charts-tag-button']} ${isSelected ? styles['charts-tag-selected'] : null}`}
                    style={{ backgroundColor: color }}
                    onClick={() => this.applyTag(el)}
                >
                    {isSelected ?
                        <span className={styles['tag-selected']}>
                            <i className="icon-outline-check-24px"></i>
                        </span>
                        : null
                    }
                    <span className={`${isSelected ? styles['tag-selected-name'] : null}`}>
                        {name}
                    </span>
                </div>
            )
        })
    }

    render() {

        if (!this.state.isLoaded)
            return <LoadingIndicator />

        if (this.state.isLoaded && this.state.isForbidden) {
            return <NoAccess />
        }
        
        let data = this.filterData(this.state.data);

        if (this.props.isSlideshowEnabled) {

            if (data.length > 0)
                return <ChartsSlideshowComponent data={data} />

            this.props.switchSlideshowMode(false);
            this.props.selectLayoutMode('normal');
        }

        return (
            <div id={styles['charts-container']}>
                <div id={styles['charts-header']}>     
                    <h4>{this.state.dashboard.displayName.toUpperCase()} KPIs </h4>
                    <div className={styles['tags-container']}>
                        {this.renderTags()}
                    </div>
                </div>
                <div id={styles['charts-content']}>
                    {this.renderCharts(data)}
                </div>
                <br />
            </div>
        )

    }

    componentWillUnmount() {

        this.props.selectDashboard(null);
        this.props.selectPreviewMode('full');
        this.props.resetSearchChart();
    }
}

const mapStateToProps = state => {

    return {
        selectedPreviewModeValue: state.selectedPreviewMode,
        searchText: state.searchChart.searchText,
        isSlideshowEnabled: state.switchSlideshowMode,
        selectDashboardTagsValue: state.selectedDashboardTags
    }
}


const mapDispatchToProps = dispatch => {
    return {
        selectDashboard: (dashboard) => {
            dispatch(selectDashboardRequest(dashboard))
        },
        selectPreviewMode: (mode) => {
            dispatch(selectPreviewModeRequest(mode))
        },
        switchSlideshowMode: (enabled) => {
            dispatch(switchSlideshowModeRequest(enabled))
        },
        selectLayoutMode: (mode) => {
            dispatch(selectLayoutModeRequest(mode))
        },
        selectDashboardTagsMode: (tags) => {
            dispatch(selectDashboardTags(tags))
        },
        resetSearchChart: () => {
            dispatch(searchChartRequest(""))
        },
        selectChart: (chart) => {
            dispatch(selectChartRequest(chart))
        },
        changeBreadcrumbs: (breadcrumbs) => {
            dispatch(breadcrumbsRequest(breadcrumbs))
        },
        setEngagementIcon: (iconName) => {
            dispatch(setEngagementIconRequest(iconName))
        }
    }
}

export const ChartsList = connect(mapStateToProps, mapDispatchToProps)(ChartsListComponent);