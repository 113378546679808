import axios from "axios";
import qs from "qs";
import decode from 'jwt-decode'

const TOKEN_URL = 'connect/token';
const DEFAULT_CONTENT_TYPE = 'application/x-www-form-urlencoded'
const TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

const login = (username, password, rememberMe = false) => {
    const config = {
        headers: {
            'Content-Type': DEFAULT_CONTENT_TYPE
        }
    }
    const requestBody = {
        grant_type: 'password',
        username: username,
        password: password,
        scope: 'offline_access',
        max_age: rememberMe === true ? null : 90
    }

    return axios.post(TOKEN_URL, qs.stringify(requestBody), config);
}

const logout = () => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    window.location.replace('/account/login');
}

const refreshToken = () => {
    const config = {
        headers: {
            'Content-Type': DEFAULT_CONTENT_TYPE
        }
    }
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    const requestBody = {
        grant_type: REFRESH_TOKEN,
        refresh_token: refreshToken
    }

    //MUST RETURN PROMISE
    return axios.post(TOKEN_URL, qs.stringify(requestBody), config)
}

function saveAccessToken(result) {
    localStorage.setItem(TOKEN, result.data.access_token);
}

function saveRefreshToken(result) {
    localStorage.setItem(REFRESH_TOKEN, result.data.refresh_token);
}

function tokenExists() {
    return localStorage.getItem(TOKEN) != null && localStorage.getItem(REFRESH_TOKEN);
}

function tokenExpired() {
    if (!tokenExists()) {
        return true;
    }

    let jwt = decode(getToken());
    const current_time = new Date().getTime() / 1000;
    if (current_time > jwt.exp) {
        return true;
    }

    return false;
}

function getToken() {
    return localStorage.getItem(TOKEN);
}

function getAccessToken() {
    return `Bearer ${getToken()}`;
}

export {
    login,
    logout,
    refreshToken,
    getToken,
    getAccessToken,
    tokenExists,
    tokenExpired,
    saveAccessToken,
    saveRefreshToken
};
