import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import EngagementsListComponent from './engagementsList/EngagementsListComponent';


class EngagementsComponent extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        return <Switch>
            <Route path="/" exact render={() => <EngagementsListComponent {...this.props} />} />
        </Switch>

    }
}

export { EngagementsComponent }