import React from 'react'
import { getUploadedFiles } from '../../services/upload';
import { Form, Button, Table } from 'react-bootstrap';
import { SendNotificationToMobileApp } from './SendNotificationToMobileAppComponent';

class UploadFilesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            blocking: false,
            uploadedFilesList: []
        }
    }

    componentDidMount() {
        this.refreshFiles();
    }

    refreshFiles = () => {
        this.toggleBlocking();
        getUploadedFiles().then(result => {
            this.setState({ blocking: false, uploadedFilesList: result.data });
        }).catch(e => {
            this.setState({ blocking: false });
        });
    }

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    renderBodyTable() {
        return (
            this.state.uploadedFilesList.map((listValue, index) => {

                let date = new Date(listValue.uploadedAt).toLocaleString();
                return (
                    <tr key={index}>
                        <td>{listValue.id}</td>
                        <td>{listValue.fileName}</td>
                        <td>{listValue.version}</td>
                        <td>{listValue.description}</td>
                        <td>{listValue.platform}</td>
                        <td>{listValue.uploadedBy}</td>
                        <td>{date}</td>
                    </tr>
                );
            })
        );
    }

    render() {
        return (

            <div>
                <SendNotificationToMobileApp />
                <br />
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>File name</th>
                            <th>Version</th>
                            <th>Description</th>
                            <th>Platform</th>
                            <th>Uploaded by</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderBodyTable()}
                    </tbody>
                </Table>
            </div>

        )
    }
}

export { UploadFilesList }