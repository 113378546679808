import React, { Component } from 'react';
import PropTypes from 'prop-types';


import styles from './DashboardTile.module.scss'

class DashboardTile extends Component {
    
    constructor(props) {

        super(props);
        this.state = {

            title: this.props.title,
            chartsCount: this.props.chartsCount
        };
    }

    render() {
        
        let title = this.state.title;

        return (
            <div className={styles['dashboard-tile-container']} onClick={this.props.onClick}>
                <div className={styles['dashboard-tile-rect']} style={{ backgroundColor: this.props.rectColor }}></div>
                <div className={styles['dashboard-tile-content']}>
                    <div className={styles['dashboard-tile-title']}>
                        {title}
                    </div>
                    <div className={styles['dashboard-tile-subtext']}>
                        KPIs: {this.state.chartsCount}
                    </div>
                </div>

            </div>

        )
    }

}

DashboardTile.propTypes = {
    
    onClick: PropTypes.func.isRequired,
    rectColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    chartsCount: PropTypes.number.isRequired
}

export { DashboardTile };
