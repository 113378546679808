import React from "react";
import { getCurrentUser } from "../auth/userManager";
import { NoAccess } from "../pages/NoAccess";

import { LoadingIndicator } from './LoadingIndicator';

const withRestrictedAccess = (...rolesAllowed) => Component =>
    class WithRestrictedAccess extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                user: {},
                userInfoLoaded: false,
                accessToComponent: false,
                ssoExpired: false
            };
        }

        async componentDidMount() {
            try {
                let userInfo = await getCurrentUser();

                this.setState({
                    userInfoLoaded: true,
                    user: userInfo.data
                });

                let accessToComponent = false;

                if (userInfo.data.isSystemAdmin) {
                    accessToComponent = true;
                }
                for (let allowedRole of rolesAllowed) {

                    if (accessToComponent)
                        break;

                    const engagements = userInfo.data.accessToEngagements;
                    Object.entries(engagements).forEach(([key, value]) => {
                        if (value === allowedRole) {
                            accessToComponent = true;
                        }
                    });

                    if (accessToComponent)
                        break;

                    const dashboards = userInfo.data.accessToDashboards;
                    Object.entries(dashboards).forEach(([key, value]) => {
                        if (value === allowedRole) {
                            accessToComponent = true;
                        }
                    });
                }

                this.setState({
                    accessToComponent: accessToComponent,
                    userInfoLoaded: true,
                });

            } catch (error) {
                const obj = error.response === undefined
                    ? { userInfoLoaded: true, ssoExpired: true }
                    : { userInfoLoaded: true, accessToComponent: false };

                this.setState(obj);
            }
        }

        render() {
            if (!this.state.userInfoLoaded) {
                return <div></div>
            }

            if (this.state.ssoExpired) {
                return <LoadingIndicator />
            }

            if (!this.state.accessToComponent) {
                return <NoAccess />
            }
            else {
                return (
                    <Component {...this.props} user={this.state.user} />
                );
            }
        }
    };

export default withRestrictedAccess;
