import React, { Component } from 'react';
import { getCurrentUser } from "../../../auth/userManager";
import { NoAccess } from '../../../pages/NoAccess';
import { EventsListComponent } from './eventsList/EventsListComponent';
import { Container, Row, Col, Form, FormGroup, Table, Modal, Button } from 'react-bootstrap';
import { getEvents, exportEventsToCsv } from '../services/eventTracker'
import moment from 'moment';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';

class EventTrackerComponent extends Component {

    constructor(props) {

        super(props)

        this.state = {

            hasAccess: false,
            isLoaded: false,
            events: [],
            eventTypes: [],
            entitiesIds: [],
            filterValues: {},
            filters: {
                entityType: '1',
                entityId: "",
                eventType: "",
                startDate: "",
                endDate: ""
            },
            parentEntityType: null
        }

        this.onFiltersChange = this.onFiltersChange.bind(this);
    }


    async componentDidMount() {

        let userInfo = await getCurrentUser();


        if (userInfo.data.isSystemAdmin) {
            this.setState({
                hasAccess: true,
                isLoaded: true,
                filters: {
                    entityType: '1',
                    entityId: "",
                    eventType: "",
                    startDate: this.getDefaultStartDate(),
                    endDate: ""
                },
            });
            await this.fetchEvents(this.state.filters)
        }
        else {
            this.setState({
                isLoaded: true
            });
        }

    }

    getDefaultStartDate() {
        let x = new Date()
        x.setDate(x.getDay() - 30);
        return moment(x).format('YYYY-MM-DD');
    }

    async fetchEvents(filters) {

        let result = (await getEvents(filters)).data;

        this.setState({
            events: result.events,
            eventTypes: result.eventTypes,
            entitiesIds: result.entitiesIds,
            filterValues: result.filterValues,
            entityType: result.entityType,
            parentEntityType: result.parentEntityType,
            isLoaded: true
        })
    }


    async onFiltersChange(e) {

        let type = e.target.name
        let value = e.target.value

        let currentFilters = JSON.parse(
            JSON.stringify(this.state.filters)
        )

        currentFilters[type] = value;

        if (type === "entityType") {

            currentFilters.entityId = ""
            currentFilters.eventType = ""
        }

        await this.fetchEvents(currentFilters);
        this.setState({
            filters: currentFilters
        })

    }

    exportToCsv = async () => {
        try {
            const response = await exportEventsToCsv(this.state.filters);
            saveAs(response.data, "Rapport-Events.csv");
        }
        catch (error) {
            this.setState({ blocking: false });
        }
    }

    renderSpecificEntityInput() {

        let specificEntityInput;

        //All or System Admins
        if (this.state.filters.entityType == 0 ||
            this.state.filters.entityType == 4)
            return null;

        let label = "";

        switch (this.state.filters.entityType) {

            case '1':
                label = "Enagement #: "
                break;
            case '2':
                label = "Dashboard #: "
                break;
            case '3':
                label = "Chart #: "
                break;
            case '5':
                label = "Enagement #: "
                break;
            default:
                break;
        }

        specificEntityInput = (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control as="select"
                    name="entityId"
                    onChange={this.onFiltersChange}
                >
                    <option value="">All</option>
                    {this.renderSpecificEntityIds()}
                </Form.Control>
            </Form.Group>
        )

        return specificEntityInput;
    }


    renderSpecificEntityIds() {
        const obj = this.state.filterValues;
        return Object.keys(obj).map((key, index) => {
            return <option key={key} value={key}>{`${key} - ${obj[key]} `}</option>
        })
    }


    renderEventTypes() {

        return this.state.eventTypes.map((el) => {
            return <option key={el} value={el}> {el} </option>
        })
    }


    render() {

        if (!this.state.isLoaded)
            return null;

        if (!this.state.hasAccess)
            return <NoAccess />


        return (

            <div id="event-tracker-container">
                <h4 style={{ color: 'gray', fontSize: 18 }}>Event Tracker</h4>

                <Button variant="outline-success" size="sm"
                    onClick={this.exportToCsv}>
                    <FontAwesomeIcon icon={faFileExport} /> Export to CSV
                </Button>
                <br />
                <br />
                <div>
                    <Form>
                        <Row>
                            <Col xs={12} lg={3} md={6}>
                                <Form.Group>
                                    <Form.Label>Events related to</Form.Label>
                                    <Form.Control as="select"
                                        name="entityType"
                                        onChange={this.onFiltersChange}
                                        value={this.state.filters.entityType}
                                    >
                                        <option value={1}>Engagements</option>
                                        <option value={2}>Dashboards</option>
                                        <option value={3}>Charts</option>
                                        <option value={4}>System Admin</option>
                                        <option value={5}>Permissions To Engagement</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={2} md={6}>
                                {this.renderSpecificEntityInput()}
                            </Col>
                            <Col xs={12} lg={3} md={6}>
                                <Form.Group>
                                    <Form.Label>Event type</Form.Label>
                                    <Form.Control as="select"
                                        name="eventType"
                                        onChange={this.onFiltersChange}
                                        value={this.state.filters.eventType}
                                    >
                                        <option value="">All</option>
                                        {this.renderEventTypes()}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={2} md={6}>
                                <Form.Group>
                                    <Form.Label>Since date</Form.Label>
                                    <Form.Control type="date"
                                        name="startDate"
                                        value={this.state.filters.startDate}
                                        onChange={this.onFiltersChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={2} md={6}>
                                <Form.Group>
                                    <Form.Label>Till date</Form.Label>
                                    <Form.Control type="date"
                                        name="endDate"
                                        value={this.state.filters.endDate}
                                        onChange={this.onFiltersChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <br />
                <EventsListComponent
                    selectedFilter={this.state.filters.entityType}
                    events={this.state.events}
                    entityType={this.state.entityType}
                    parentEntityType={this.state.parentEntityType}
                />
            </div>
        )
    }
}
export { EventTrackerComponent }