import { httpClient } from '../../../common/httpClient';

export const getEvents = (filters) => httpClient({
    url: `api/eventTracker`,
    method: "get",
    params: filters
});

export const exportEventsToCsv = (filters) => httpClient({
    url: `api/eventTracker/export`,
    method: "post",
    data: filters,
    responseType: 'blob',
});