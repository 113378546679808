import { httpClient } from '../../../common/httpClient';

const getAll = () => httpClient({
    url: `api/adminengagements`,
    method: "get",
});


const getById = (id) => httpClient({
    url: `api/adminengagements/${id}`,
    method: "get",
});

const saveNew = (newEngagement) => httpClient({
    url: `api/adminengagements`,
    method: "post",
    data: {
        title: newEngagement.title,
        displayName: newEngagement.title,
        isVisibleOnMobile: newEngagement.isVisibleOnMobile,
        iconName: newEngagement.iconName
    },
    headers: {
        "Content-Type": "application/json",
    }
});


const updateEngagement = (id, title, isVisibleOnMobile, iconName) => httpClient({
    url: `api/adminengagements/` + id,
    method: "put",
    data: {
        id: id,
        title: title,
        displayName: title,
        isVisibleOnMobile: isVisibleOnMobile,
        iconName: iconName
    },
    headers: {
        "Content-Type": "application/json",
    }
});


const deleteEngagement = (id) => httpClient({
    url: `api/adminengagements/` + id,
    method: "delete",
    data: {
        id: id

    },
    headers: {
        "Content-Type": "application/json",
    }
});

const deleteEngagementIcon = (id) => httpClient({
    url: `api/files/engagementIcon`,
    method: "delete",
    data: {
        id: id
    },
    headers: {
        "Content-Type": "application/json",
    }
});

const updateOrder = (engagementId, newOrder) => httpClient({
    url: `api/adminengagements/updateOrder`,
    method: "put",
    data: {
        engagementId: engagementId,
        engagementsOrder: newOrder
    },
    headers: {
        "Content-Type": "application/json",
    }
})

const copyEngagement = (data) => httpClient({
    url: `api/adminengagements/copy`,
    method: "post",
    data: data,
    headers: {
        "Content-Type": "application/json",
    }
})

export { getAll, getById, saveNew, updateEngagement, deleteEngagement, deleteEngagementIcon, updateOrder, copyEngagement };
