import React, { Component, useRef } from 'react';
import { getAll, saveNew, updateEngagement, deleteEngagement, deleteEngagementIcon, updateOrder } from '../../services/engagements';
import { Link, Redirect } from "react-router-dom";
import { Form, Button, Table, Modal } from 'react-bootstrap';
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit,
    faSave,
    faPlusCircle,
    faTrashAlt,
    faWindowClose,
    faEye,
    faCopy,
    faUpload
} from "@fortawesome/free-solid-svg-icons/index";
import { MyTooltip } from '../../../../components/MyTooltip';
import { toast } from 'react-toastify';
import { DeleteModal } from '../../../../components/DeleteModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getErrorMessage } from '../../services/common';
import { CopyEngagementComponent } from '../copyEngagement/CopyEngagementCompontent';
import { uploadEngagementIcon, uploadFile } from '../../services/upload';
import styles from './EngagementListComponent.module.css';


class EngagementListComponent extends Component {

    constructor(props) {
        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);

        this.state = {
            data: {},
            blocking: true,
            editInput: {},
            editing: {},
            newEngagement: {
                title: '',
                isVisibleOnMobile: true,
                file: null,
                iconName: null                
            },
            newEngagementActive: false,
            deleting: [],
            ordering: [],
            showModal: false,
            showIconModal: false,
            showCopyEngagementModal: false,
            file: null
        };        
    }

    async componentDidMount() {

        await this.fetchEngagementsWithOrdering();
    }

    async fetchEngagementsWithOrdering() {

        try {
            let engagementsFetch = await getAll();

            let dataObj = engagementsFetch.data;
            let order = dataObj.map((el) => {
                return {
                    id: el.id,
                    order: el.order
                }
            })

            this.setState({
                data: dataObj,
                ordering: order,
                blocking: false
            });
        }
        catch (ex) {

            this.setState({ blocking: false });
        }
    }


    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    editToggle(id) {

        const editInput = { ...this.state.editInput };
        editInput[id] = !editInput[id];

        let engagement = this.state.data.find(c => c.id === id);
        const editing = this.state.editing;
        editing[id] = {
            title: engagement.title,
            isVisibleOnMobile: engagement.isVisibleOnMobile,
            iconName: engagement.iconName,
            file: engagement.file
        };
        this.setState({ editInput: editInput, editing });
    }

    saveNewRecord = async () => {

        if (!this.isEngagementValid(this.state.newEngagement.title))
            return;
        
        if (!this.isFileValid(this.state.newEngagement.iconName))
            return; 

        let data = this.state.data.slice();

        try {
            if (this.state.newEngagement.iconName != null){
                this.fileUpload(this.state.newEngagement.file);
            }
            let result = await saveNew(this.state.newEngagement);
            data.push(result.data);
            this.setState({ data: data, newEngagementActive: false, newEngagement: { title: '', isVisibleOnMobile: true, file: null, iconName: null } });
            toast.info("Engagement added successfully.")
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    updateRecord = async (id) => {

        const new_title = this.state.editing[id].title;
        const isVisibleOnMobile = this.state.editing[id].isVisibleOnMobile;
        const iconName = this.state.editing[id].iconName;
        const file = this.state.editing[id].file;

        if (!this.isEngagementValid(new_title))
            return;

        if (!this.isFileValid(iconName))
            return; 
        try {
            if (iconName != null){
                this.fileUpload(file);
            }
            await updateEngagement(id, new_title, isVisibleOnMobile, iconName);
            let data = this.state.data.slice();

            data.map((value, index) => {
                if (value.id === id) {
                    data[index].title = new_title;
                    data[index].isVisibleOnMobile = isVisibleOnMobile;
                    data[index].iconName = iconName;
                    data[index].file = file;
                }
            });

            this.setState({ data: data });
            this.editToggle(id);
            toast.info("Engagement updated successfully.")
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    isEngagementValid = (title) => {

        for (let engagement of this.state.data) {

            if (engagement.title.toUpperCase().trim() === title.toUpperCase().trim() && !this.state.editInput[engagement.id]) {
                toast.error("Engagement with this name already exists!")
                return false;
            }
        }
        return true;
    }

    isFileValid = (iconName) => {

        if (iconName === null)
            return true;

        for (let engagement of this.state.data) {

            if (engagement.iconName != null && engagement.iconName === iconName && !this.state.editInput[engagement.id]) {
                toast.error("File with this name already exists!")
                return false;
            }
        }
        return true;
    }

    deleteRecord = async () => {

        let id = this.state.deleting.id;

        try {

            await deleteEngagementIcon(id);
            await deleteEngagement(id);
            const data = [...this.state.data.filter(x => x.id !== id)]

            this.setState({
                data: data,
                showModal: false
            })

            toast.info("Engagement deleted successfully.")
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    deleteIcon = async () => {

        let id = this.state.deleting.id;
        const editing = this.state.editing;

        try {
            await deleteEngagementIcon(id);

            let data = this.state.data.slice();

            data.map((value, index) => {
                if (value.id === id) {
                    data[index].iconName = null;
                    data[index].file = null;                    
                }
            });

            editing[id].iconName = null;
            editing[id].file = null;

            this.setState({
                showIconModal: false,
                data: data,
                editing: editing
            })

            toast.info("Icon deleted successfully.")
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }

    }

    onChange = (id, e) => {

        const editing = this.state.editing;
        editing[id]['title'] = e.target.value;
        this.setState({ editing: editing })
    }

    onFileChange (e) {
        let value = e.target.files[0];

        let newEngagement = { ...this.state.newEngagement };
        newEngagement['file'] = value;
        newEngagement['iconName'] = value.name.replace(/\.[^/.]+$/, '');
        
        this.setState({ newEngagement: newEngagement })
    };

    onChangeUpdate(id, variable, type, e) {

        const editing = this.state.editing;
        let value = e.target.value;

        if (type == 'checkbox')
            value = e.target.checked

        editing[id][variable] = value;
        this.setState({ editing: editing })
    }

    onFileChangeUpdate (id, e) {

        const editing = this.state.editing;
        let value = e.target.files[0];

        if(value && value.type === 'image/png') {
            editing[id]['file'] = value;
            editing[id]['iconName'] = value.name.replace(/\.[^/.]+$/, '');
        }
        else{
            toast.info("Please select a PNG file.");
            return;
        }
        
        this.setState({ editing: editing });        
    };

    onChangeNew(variable, type, e) {

        let value = e.target.value;

        if (type === 'checkbox')
            value = e.target.checked;

        let newEngagement = { ...this.state.newEngagement };
        newEngagement[variable] = value;
        
        this.setState({ newEngagement: newEngagement })
    }

    onDragEnd = async result => {

        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        let reordered = this.state.ordering.slice();
        reordered.map((value) => {

            if (value.order >= destination.index && value.order < source.index) {
                value.order = value.order + 1;
                return value;
            }

            if (value.order <= destination.index && value.order > source.index) {
                value.order = value.order - 1;
                return value;
            }

            if (value.order === source.index) {
                value.order = destination.index;
                return value;
            }
            return value;
        });

        let data = [...this.state.data]
        let dataReordered = [...this.state.data]

        dataReordered.splice(source.index, 1);
        dataReordered.splice(destination.index, 0, data[draggableId - 1]);

        try {

            let engagementId = this.state.data[source.index].id
            let newOrder = dataReordered.map((el, index) => {
                return {
                    id: el.id,
                    order: index
                }
            })

            await updateOrder(engagementId, newOrder);

            this.setState({ data: dataReordered, ordering: reordered });
        }
        catch (ex) { }
    }

    checkNew() {

        if (this.state.newEngagementActive) {
            return (
                <tr>
                    <td>
                    </td>

                    <td>
                        <Form.Control type="text" placeholder="Type engagement name..." size="sm"
                            onChange={e => {
                                this.onChangeNew('title', 'text', e)
                            }}
                            value={this.state.newEngagement.title}>
                        </Form.Control>
                    </td>

                    <td>
                        <input type='checkbox'
                            checked={this.state.newEngagement.isVisibleOnMobile}
                            disabled
                            onChange={e => this.onChangeNew('isVisibleOnMobile', 'checkbox', e)}
                            value={this.state.newEngagement.isVisibleOnMobile}
                        />
                    </td>

                    <td>
                        <MyTooltip tooltip="Save">
                            <Button onClick={() => this.saveNewRecord()}
                                variant="outline-success" size="sm">
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        </MyTooltip>
                        <MyTooltip tooltip="Cancel">
                            <Button onClick={() => this.setState({ newEngagementActive: false, newEngagement: { title: '', isVisibleOnMobile: false, iconName: null, file: null } })}
                                variant="outline-primary" size="sm">
                                <FontAwesomeIcon icon={faWindowClose} />
                            </Button>
                        </MyTooltip>
                    </td>
                    <td>
                        <MyTooltip tooltip="Upload">
                                <Form.Label 
                                    htmlFor='fileUpload'
                                    className={`${styles['custom-outline-primary']} ${styles['btn-sm']}`}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faUpload}/>                                                 
                                    </Form.Label>
                                    {this.state.newEngagement.iconName}
                                <Form.Control 
                                    className={`${styles['hidden']}`}
                                    type="file"
                                    accept=".png"
                                    onChange={this.onFileChange}
                                    id='fileUpload' 
                                />
                        </MyTooltip>
                    </td>
                </tr>)
        }
    }

    onFormSubmit(e) {
        e.preventDefault() // Stop form submit

        this.toggleBlocking();

        const file = this.state.file;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', this.state.description);
        formData.append('version', this.state.version);

        // uploadFile(formData).then((response) => {
        //     toast.info("File uploaded successfully");
        //     this.setState({ shouldRefreshList: true, blocking: false, file: null });
        // }).catch(error => {
        //     if (error.response && error.response.data && error.response.data.error) {
        //         toast.error(error.response.data.error[0]);
        //     }
        //     this.setState({ blocking: false, file: null });
        // });
    }

    fileUpload(file) {
        const url = '/api/files/engagementIcon';
        const formData = new FormData();
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return uploadEngagementIcon(formData);
    }

    modalShow(id) {

        let data = this.state.data.slice();
        let deleting = [];

        data.map((value) => {
            if (value.id == id) {
                deleting.category = 'engagement';
                deleting.title = value.title;
                deleting.id = value.id;
            }
        });

        this.setState({ showModal: true, deleting: deleting })
    }

    modalIconShow(id) {

        let data = this.state.data.slice();
        let deleting = [];

        data.map((value) => {
            if (value.id == id) {
                deleting.category = 'icon';
                deleting.title = value.iconName;
                deleting.id = value.id;
            }
        });

        this.setState({ showIconModal: true, deleting: deleting })
    }

    renderBodyTable() {

        let data = this.state.data;        

        return (Object.keys(data).map((index) => {

            let keyNumber = parseInt(index);
            let action;
            let icons;            
            let id = data[index].id;
            let title =
                <MyTooltip tooltip="Go to dashboards">
                    <Link
                        to={{
                            pathname: `/admin/engagements/${id}`,
                        }}>
                        {data[index].title}
                    </Link>
                </MyTooltip>
            let isVisibleOnMobile =
                <div>
                    <input type="checkbox" checked={data[index].isVisibleOnMobile} readOnly />
                </div>

            let icon = <img src={`/api/staticresources/icons/${data[index].iconName}/EngagementIcons`}
                            className={`${styles['table-img']}`}/>;

            const iconAction = data[index].iconName ?
                <span>
                    {icon}
                </span>                        
                : null;

            if (!this.state.editInput[id]) {

                const deleteButton = this.props.user && this.props.user.isSystemAdmin ?
                    <MyTooltip tooltip="Delete">
                        <Button onClick={() => this.modalShow(id)} variant="outline-danger" size="sm">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </MyTooltip>
                    : null;   

                action = this.props.user && this.props.user.isSystemAdmin ?
                    <span>
                        <MyTooltip tooltip="Edit">
                            <Button onClick={() => this.editToggle(id)} variant="outline-info" size="sm">
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </MyTooltip>
                        {deleteButton}
                        <MyTooltip tooltip="Go to View">
                            <Link
                                to={{
                                    pathname: `/engagements/${data[index].id}`
                                }}><Button
                                    variant="outline-primary" size="sm">
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Link>
                        </MyTooltip>
                    </span>
                    : null;

                    icons = this.props.user && this.props.user.isSystemAdmin ?
                    <span>
                        {iconAction}
                    </span>
                    : null;    

            } else {

                title =
                    <Form.Control type="text" size="sm"
                        onChange={e => {
                            this.onChangeUpdate(id, 'title', 'text', e);
                        }}
                        placeholder="Type engagement name..."
                        value={this.state.editing[id] ? this.state.editing[id].title : data[index].title}>
                    </Form.Control>

                isVisibleOnMobile =
                    <input type="checkbox"
                        checked={this.state.editing[id].isVisibleOnMobile}
                        onChange={e => {
                            this.onChangeUpdate(id, 'isVisibleOnMobile', 'checkbox', e);
                        }}
                    />

                action =
                    <span>
                        <MyTooltip tooltip="Save">
                            <Button onClick={() => this.updateRecord(id)} variant="outline-success" size="sm">
                                <FontAwesomeIcon icon={faSave} /></Button>
                        </MyTooltip>
                        <MyTooltip tooltip="Cancel">
                            <Button onClick={() => this.editToggle(id)} variant="outline-primary" size="sm">
                                <FontAwesomeIcon icon={faWindowClose} />
                            </Button>
                        </MyTooltip>
                    </span>


                const deleteIcon =
                        <MyTooltip tooltip="Delete">
                            <Button onClick={() => this.modalIconShow(id)} variant="outline-danger" size="sm">
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </MyTooltip>

                const uploadFile =
                        <MyTooltip tooltip="Upload">
                            <Form.Label
                                htmlFor={`fileUpload[${id}]`}
                                className={`${styles['custom-outline-primary']} ${styles['btn-sm']}`}
                                >
                                    <FontAwesomeIcon 
                                        icon={faUpload}
                                    />                                             
                            </Form.Label>
                            {this.state.editing[id].iconName}
                            <Form.Control 
                                type="file" 
                                accept=".png" 
                                onChange={e => {
                                    this.onFileChangeUpdate(id, e);
                                }} 
                                id={`fileUpload[${id}]`}
                                className={`${styles['hidden']}`}
                                variant="primary"/>
                        </MyTooltip>
                
                icons = !data[index].iconName ?
                        <span>
                            {uploadFile}
                        </span>
                        : <span>
                            {deleteIcon}
                        </span>   
            }         

            const tableBody = this.props.user && this.props.user.isSystemAdmin ?
                <Draggable key={keyNumber + 1} draggableId={keyNumber + 1} index={keyNumber}>
                    {(provided) => (

                        <tr key={keyNumber + 1} ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <td>{id}</td>
                            <td>{title}</td>
                            <td>{isVisibleOnMobile}</td>
                            <td>{action}</td>
                            <td>{icons}</td>
                        </tr>
                    )}
                </Draggable>
                :
                <tr key={keyNumber + 1}>
                    <td>{id}</td>
                    <td>{title}</td>
                    <td>{iconAction}</td>
                </tr>

            return tableBody
        })
        );
    }

    renderTableHead() {
        const tableHead = this.props.user && this.props.user.isSystemAdmin ?

            <tr>
                <th>Id</th>
                <th>Title</th>
                <th>Visible in Mobile App</th>
                <th>Action</th>
                <th>Icon</th>
            </tr>
            :
            <tr>
                <th>Id</th>
                <th>Title</th>
                <th>Icon</th>
            </tr>

        return tableHead
    }

    render() {

        const addNewEngagement = this.props.user && this.props.user.isSystemAdmin ?
            <div>
                <DeleteModal show={this.state.showModal}
                    deleting={this.state.deleting}
                    onconfirmdelete={(this.deleteRecord)}
                    onHide={() => this.setState({ showModal: false })} />
                <DeleteModal show={this.state.showIconModal}
                    deleting={this.state.deleting}
                    onconfirmdelete={this.deleteIcon}
                    onHide={() => this.setState({ showIconModal: false })} />
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showCopyEngagementModal}
                    onHide={() => this.setState({ showCopyEngagementModal: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Copying Engagement
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CopyEngagementComponent {...this.props} />
                    </Modal.Body>
                </Modal>
                <br />
                <Button onClick={() => this.setState({ newEngagementActive: true })} variant="outline-primary mr-2" size="sm">
                    <FontAwesomeIcon icon={faPlusCircle} /> Add new engagement
                </Button>
                <Button onClick={() => this.setState({ showCopyEngagementModal: true })} variant="outline-success" size="sm">
                    <FontAwesomeIcon icon={faCopy} /> Copy engagement
                </Button>
                <br />
            </div>
            : <div></div>

        const tableHead = this.renderTableHead();

        const table = this.props.user && this.props.user.isSystemAdmin ?
            <BlockUIContainer blocking={this.state.blocking}>
                {addNewEngagement}
                <br />

                <DragDropContext
                    onDragEnd={this.onDragEnd}>
                    <Table bordered hover>
                        <thead>
                            {tableHead}
                        </thead>
                        <Droppable droppableId="engagements">
                            {(provided) => (

                                <tbody ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {this.checkNew()}
                                    {this.renderBodyTable()}

                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
            </BlockUIContainer>
            :
            <Table bordered hover>
                <thead>
                    {tableHead}
                </thead>
                <tbody>
                    {this.renderBodyTable()}
                </tbody>
            </Table>

        if (this.state.data.length === 1 && !this.props.user.isSystemAdmin)
            return <Redirect to={`engagements/${this.state.data[0].id}`} />

        return (

            <div>
                <h4 style={{ color: 'gray', fontSize: 18 }}>List of engagements</h4>
                <div>
                    {table}
                </div>
            </div>
        );
    }
}

export { EngagementListComponent };