import React, { Component } from 'react';
import { toast } from 'react-toastify';
import FormValidator from '../../../common/formValidator';
import SubmitButton from '../common/SubmitButton';
import { changePassword } from '../../../modules/account/services/changePasswordService';


class ChangePassword extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'oldPassword',
                method: 'isEmpty',
                validWhen: false,
                message: 'Old Password is required.'
            },
            {
                field: 'password',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password is required.'
            },
            {
                field: 'password',
                method: this.passwordMustMeetRequirements,
                validWhen: true,
                message: 'Password must contains at least 6 characters, one uppercase and one digit.'
            },
            {
                field: 'passwordConfirmation',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password confirmation is required.'
            },
            {
                field: 'passwordConfirmation',
                method: this.passwordMatch,   // notice that we are passing a custom function here
                validWhen: true,
                message: 'Password and password confirmation do not match.'
            },
        ]);

        this.state = {
            validation: this.validator.valid(),
            oldPassword: '',
            password: '',
            passwordConfirmation: '',
            isPasswordChanged: false,
            isError: false,
            errorMessage: 'An error occured. Please try again.',
        };

        this.oldPasswordRef = React.createRef();

        this.submitted = false;
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.oldPasswordRef.current != null)
                this.oldPasswordRef.current.focus();
        }, 500)
    }

    handleChangePasswordButtonClick = () => {
        const validation = this.validator.validate(this.state);
        this.setState({ validation });

        this.submitted = true;

        if (validation.isValid) {
            this.invokeChangePassword();
        }
    }

    invokeChangePassword = () => {

        const viewModel = {
            oldPassword: this.state.oldPassword,
            NewPassword: this.state.password,
            NewPassswordConfirmation: this.state.passwordConfirmation
        }

        changePassword(viewModel).then((result) => {
            toast.info("Password changed successfully.");
            this.setState({ isPasswordChanged: true });
        }).catch((err) => {
            console.log(err);
            this.setState({ isError: true, errorMessage: err.data });
        });
    }

    handleInputChange = event => {
        event.preventDefault();

        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation)

    passwordMustMeetRequirements = (password, state) => {
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
        return regex.test(password);
    }

    render() {
        let validation = this.submitted
            ? this.validator.validate(this.state)
            : this.state.validation

        return (
            <div>
                <h5>
                    Change password
                </h5>
                {validation.isValid ?
                    <div>
                        <br />
                        <br />
                    </div>
                    : <br />
                }

                {this.state.isPasswordChanged ?
                    <div>
                        Password changed successfully.
                    </div>

                    : <div>
                        <div className="form-group">
                            <input
                                ref={this.oldPasswordRef}
                                onChange={this.handleInputChange}
                                className="form-control input--form"
                                type="password"
                                name="oldPassword"
                                id="oldPassword"
                                placeholder="Old Password"
                            />
                            <small className="form-text text-danger">
                                {validation.oldPassword.message}
                            </small>
                        </div>
                        <div className="form-group">
                            <input
                                onChange={this.handleInputChange}
                                className="form-control input--form"
                                type="password"
                                name="password"
                                id="password"
                                placeholder="New Password"
                            />
                            <small className="form-text text-danger">
                                {validation.password.message}
                            </small>
                        </div>
                        <div className="form-group">
                            <input
                                onChange={this.handleInputChange}
                                className="form-control input--form"
                                type="password"
                                name="passwordConfirmation"
                                id="passwordConfirmation"
                                placeholder="Repeat Password"
                            />
                            <small className="form-text text-danger">
                                {validation.passwordConfirmation.message}
                            </small>
                        </div>
                        {this.state.isError ?
                            <small className="form-text text-danger">
                                {this.state.errorMessage}
                            </small>
                            :
                            null
                        }
                        <SubmitButton handleClick={this.handleChangePasswordButtonClick} title='Change' />
                    </div>
                }

            </div>
        );
    }
}

export default ChangePassword;