import * as React from 'react';
import { EngagementTitle } from '../engagementTitle/EngagementTitle';
import { colors } from '../../../shared/shared'
import { getAll } from '../../services/dashboards';
import { DashboardTile } from '../../dashboards/dashboardTile/DashboardTile';
import * as strHelper from '../../../../common/stringHelper';
import { NoEntries } from '../../../../components/NoEntriesComponent';
import styles from './MasterBoardComponent.module.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';

class MasterBoardComponent extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

            isLoaded: false,
            engagements: this.props.engagements,
            dashboards: [],
            selectedEngagement: this.props.engagements[0]
        }
    }

    async componentDidMount() {

        try {

            let dashboards = await this.fetchDashboards(this.state.selectedEngagement.id);

            this.setState({
                isLoaded: true,
                dashboards: dashboards
            })
        }
        catch (ex) {

            this.setState({
                isLoaded: true
            })
        }
    }

    async fetchDashboards(engagementId) {

        let dashboardsFetch = await getAll(engagementId);
        return dashboardsFetch.data
    }

    selectEngagement = async (el) => {

        this.setState({
            isLoaded: false
        })
        try {

            let dashboards = await this.fetchDashboards(el.id);

            this.setState({
                dashboards: dashboards,
                selectedEngagement: el,
                isLoaded: true
            })
        }
        catch (ex) {

            this.setState({
                selectEngagement: el,
                isLoaded: true
            })
        }
    }

    navigateToCharts = (engagementId, dashboardId) => {

        this.props.history.push(`engagements/${engagementId}/dashboards/${dashboardId}`)
    }

    renderEngagements() {

        let data = [...this.state.engagements]

        if (this.props.searchText != '' && this.props.searchText != undefined) {
            data = data.filter(x => strHelper.containsText(x, this.props.searchText));
        }

        if (data.length === 0) {
            return <NoEntries />
        }

        return data.map((el, index) => {

            let color = colors[index % colors.length];

            return (
                <div
                    key={el.id}>
                    <EngagementTitle
                        id={el.id}
                        logoColor={color}
                        title={el.title}
                        dashboardsCount={el.dashboardsCount}
                        showOnly={true}
                        icon={el.iconName}
                        onClick={() => this.selectEngagement(el)}
                    />
                </div>)
        })
    }

    renderDashboards() {

        return this.state.dashboards.map((el, index) => {

            let color = colors[index % colors.length];

            return (
                <div>
                    <DashboardTile
                        key={el.id}
                        id={el.id}
                        rectColor={color}
                        title={el.displayName}
                        chartsCount={el.chartsCount}
                        onClick={() => this.navigateToCharts(el.engagementId, el.id)}
                    />
                </div>)
        })
    }

    render() {

        if (!this.state.isLoaded)
            return <LoadingIndicator />

        return (
            <div>
                <div id={styles['master-board-engagements']}>
                    <h4>ENGAGEMENTS</h4>
                    <br />
                    {this.renderEngagements()}
                </div>
                <div id={styles['master-board-dashboards']}>
                    <h4>ENGAGEMENT BOARDS</h4>
                    <br />
                    {this.renderDashboards()}
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        searchText: state.searchEngagement.searchText,
    }
}

export default withRouter(connect(mapStateToProps)(MasterBoardComponent));