import { httpClient } from '../../../common/httpClient';


const uploadFile = (data) => httpClient({
    url: `api/files`,
    method: "post",
    data: data,
    headers: {
        "Content-Type": "multipart/form-data",
    }
})

const getUploadedFiles = () => httpClient({
    url: `api/files`,
    method: "get",
});

const notifyUsers = () => httpClient({
    url: `api/mobilenotifications/appupdate`,
    method: "post",
    headers: {
        "Content-Type": "application/json"
    }
});

const uploadEngagementIcon = (data) => httpClient({
    url: `api/files/engagementIcon`,
    method: "post",
    data: data,
    headers: {
        "Content-Type": "multipart/form-data",
    }    
})

export { uploadFile, getUploadedFiles, notifyUsers, uploadEngagementIcon };

