import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Button } from 'react-bootstrap';
import { updateMobileSettings, getMobileSettings } from "../services/mobileSettings";
import { toast } from "react-toastify";
import { getErrorMessage } from '../services/common';
import moment from 'moment';

class MobileSettingsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {},
            dataLoaded: false,
            blocking: true,
        }
    }

    async componentDidMount() {
        let settings = (await getMobileSettings()).data;
        this.setState({
            settings: settings,
            dataLoaded: true,
            blocking: false
        })
    }

    onChange = (e) => {
        let value = e.target.value

        let currentSettings = JSON.parse(
            JSON.stringify(this.state.settings)
        )

        currentSettings['version'] = value;

        this.setState({
            settings: currentSettings,
            dataLoaded: true,
        })
    }

    confirmUpdate = async () => {
        try {
            this.setState(this.setState({ blocking: true }));
            var result = await updateMobileSettings({ version: this.state.settings.version });

            setTimeout(() => {
                this.setState({
                    settings: result.data,
                    blocking: false
                });
                toast.info('Value updated sucessfully.')
            }, 1000)
        }
        catch (ex) {
            const message = getErrorMessage(ex);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    render() {

        if (!this.state.dataLoaded) {
            return <div>Loading</div>
        }

        let utcModifiedDate = moment.utc(this.state.settings.lastModifiedDate);
        const localDateTime = moment(utcModifiedDate).local().format("DD-MM-YYYY HH:mm:ss");
        return (
            <div>
                <h4>Mobile Settings</h4>
                <br />
                <div>
                    <Row>
                        <Col xs={12} lg={3} md={6}>
                            <Form.Group>
                                <Form.Label>Current Application Version</Form.Label>
                                <Form.Control
                                    disabled={this.state.blocking}
                                    name="version"
                                    onChange={this.onChange}
                                    value={this.state.settings.version}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={3} md={6}>
                            <Form.Group>
                                <Form.Label>Version Last Modified Date</Form.Label>
                                <Form.Control
                                    readOnly
                                    name="lastModifiedDate"
                                    value={localDateTime}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={3} md={6}>
                            <Form.Group>
                                <Button disabled={this.state.blocking}
                                    onClick={this.confirmUpdate}
                                >
                                    Update
                            </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export { MobileSettingsComponent }