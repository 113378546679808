import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

class Home extends Component {
  static displayName = Home.name;

  render() {

    return (
      <Switch>
        <Route path="/" render={() => { return <Redirect to="/engagements" />  }} />
      </Switch>

    )
  }
}
export { Home }
