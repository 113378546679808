import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { EngagementListComponent } from "./engagementList/EngagementListComponent";
import withRestrictedAccess from "../../../components/withRestrictedAccess";
import { Administrator, ROLES } from "../../../auth/userRoles";
import { EngagementDetailsComponent } from './engagementDetails/EngagementDetailsComponent';

class AdminEngagementsComponent extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <Switch>
                <Route exact path="/admin/engagements" render={({ match }) => <EngagementListComponent {...this.props} />} />
                <Route path="/admin/engagements/:engagementId" render={({ match }) => <EngagementDetailsComponent {...this.props} params={{ engagementId: match.params.engagementId }} />} />
            </Switch>
        )
    }
}

export default withRestrictedAccess(ROLES[Administrator])(AdminEngagementsComponent)
