import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BreadcrumbItem extends Component {

    constructor(props) {
        super(props);
    }

    onClick = () => {
        if (this.props.isActive) {
            return;
        }
        this.props.onClick(this.props.url);
    }

    render() {
        let className = this.props.isActive ? `${this.props.activeClassName}` : null;
        return (
            <li onClick={this.onClick} key={this.props.url} className={className}>
                <i style={{ fontSize: '1.2vh', paddingLeft: '8px' }} className="icon-next"></i>
                <i style={{ fontSize: '1.2vh', paddingRight: '8px' }} className="icon-next"></i>
                {
                    this.props.iconClass
                        ? <i className={`${this.props.iconClass}`}></i>
                        : null
                }
                <span>{this.props.title}</span>
            </li>
        );
    }

}

BreadcrumbItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    iconClass: PropTypes.string,
    activeClassName: PropTypes.string,
    isActive: PropTypes.bool.isRequired
}

export { BreadcrumbItem }