import { SELECT_LAYOUT_MODE_REQUEST } from '../constants/ActionTypes'

const initState = 'normal';

const selectLayoutModeReducer = (state = initState, action) => {

    switch(action.type){
        case SELECT_LAYOUT_MODE_REQUEST:
            return action.mode;  
        default:
            return state;
    }

}

export default selectLayoutModeReducer;