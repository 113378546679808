import {httpClient} from '../../../common/httpClient';

const getAll = (engagementId) => httpClient({
    url: `api/admindashboards/${engagementId}/items`,
    method: "get",
});


const getById = (id) => httpClient({
    url: `api/admindashboards/${id}`,
    method: "get",
});

const saveNew = (engagementId, displayName, isLivePreviewEnabled, isSummaryEnabled, summaryLink) => httpClient({
    url: `api/admindashboards/`,
    method: "post",
    data: {
        engagementId: engagementId,
        displayName: displayName,
        isLivePreviewEnabled: isLivePreviewEnabled,
        isSummaryEnabled: isSummaryEnabled,
        summaryLink: summaryLink

    },
    headers: {
        "Content-Type": "application/json",
    }
});

const updateDashboard = (engagementId, id, displayName, isLivePreviewEnabled, isSummaryEnabled, summaryLink) => httpClient({
    url: `api/admindashboards/` + id,
    method: "put",
    data: {
        id: id,
        engagementId: engagementId,
        displayName: displayName,
        isLivePreviewEnabled: isLivePreviewEnabled,
        isSummaryEnabled: isSummaryEnabled,
        summaryLink: summaryLink
    },
    headers: {
        "Content-Type": "application/json",
    }
});


const deleteDashboard = (id) => httpClient({
    url: `api/admindashboards/` + id,
    method: "delete",
    data: {
        Id: id
    },
    headers: {
        "Content-Type": "application/json",
    }
});


const updateOrder = (engagementId, newOrder) => httpClient({
    url: `api/admindashboards/updateOrder`,
    method: "put",
    data: {
        engagementId: engagementId,
        dashboardsOrder: newOrder
    },
    headers: {
        "Content-Type": "application/json",
    }
})

export {getAll, getById, saveNew, updateDashboard, deleteDashboard, updateOrder};

