import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { BreadcrumbItem } from './BreadcrumbItem';
import styles from './BreadcrumbsComponent.module.scss';


class BreadcrumbsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    goToUrl = (url) => {
        this.props.history.push(url);
    }

    renderItems() {
        return this.props.items.map((item, index) =>
            <BreadcrumbItem
                key={item.url}
                url={item.url}
                title={item.title}
                iconClass={item.iconClass}
                isActive={item.isActive}
                baseClass={styles['breadcrumb-item']}
                activeClassName={styles['active']}
                onClick={this.goToUrl}
            />
        );
    }

    renderHomeButton() {
        return this.props.history.location.pathname != '/engagements'
            ? <div onClick={() => this.props.history.push('/')} className={styles['home-icon']}>
                <span className="icon-outline-home-24px"></span>
            </div>
            : null
    }

    render() {
        return (
            <div className={styles['breadcrumb']}>
                {this.renderHomeButton()}
                <ol>
                    {this.renderItems()}
                </ol>
            </div>
        );
    }
}

export const Breadcrumbs = withRouter(BreadcrumbsComponent);