import * as React from 'react';

import styles from './EngagementTitle.module.scss';
import PropTypes from 'prop-types';

class EngagementTitle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            logoColor: this.props.logoColor,
            title: this.props.title,
            dashboardsCount: this.props.dashboardsCount
        }
    }

    render() {
        const CircleImage = ({ imageUrl }) => {
            return (
              <div className={styles['circle-image']} style={{backgroundColor: this.state.logoColor}}>
                <div className={styles['circle-squer']} >
                    <img src={imageUrl} alt="Icon" className={styles['circle-img']} />
                </div>
              </div>
            );
          };
        let title = this.state.title;
        const icon = this.props.icon ?
        <CircleImage imageUrl={`/api/staticresources/icons/${this.props.icon}/EngagementIcons`} />
        : 
        <div className={styles['single-engagement-logo']} style={{backgroundColor: this.state.logoColor}}>
            <p className={styles['single-engagement-logo-text']}>
                {this.state.title.slice(0,2).toUpperCase()}
            </p>
        </div>

        return <div className={styles['single-engagement-container']} onClick={this.props.onClick}>
            <div className={styles['single-engagement-inner']}>
                {icon}
                <div className={styles['single-engagement-title']}>
                    <p>{title}</p>
                </div>
                <div className={styles['single-engagement-subtext']}>
                    Dashboards: {this.state.dashboardsCount}
                </div>
            </div>
        </div>
    }
}

EngagementTitle.propTypes = {
    onClick: PropTypes.func.isRequired,
    logoColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    dashboardsCount: PropTypes.number.isRequired
}

export { EngagementTitle }