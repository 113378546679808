import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PeriodMapping, PeriodConstants } from './helpers';
import moment from 'moment';
import './custom.datatable.css';
import { getAnalyticEntriesForExport } from '../../services/analytics';
import * as customAuthService from '../../../../auth/customAuthService';
import { OAuthService } from '../../../../auth/OAuthService';

const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
const jszip = require('jszip');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js')();
window.JSZip = jszip;

const dateColumnIndex = 7;

let componentInstance = null;
class Table extends Component {

    constructor(props) {
        super(props);
        componentInstance = this;

        this.authService = new OAuthService();
        this.mainRef = React.createRef();
        this.state = {
            date: PeriodMapping[PeriodConstants.LastDay],
            ajaxParams: null,
            entriesToExport: [],
        }
    }

    componentDidMount() {
        var self = this;

        const table = $(this.mainRef.current).DataTable({
            ...this.getDatatableConfig(),
            ajax: {
                ...this.getAjaxConfig(),
                data: this.transformData
            },
        });

        
        $('#report-table thead th').each(function (i) {
            const title = $(this).text();

            if (title !== "Date") {
                $(this).append('<input style="margin-top: 10px;" class="form-control form-control-sm" type="text" placeholder="Search ' + title + '" /></div>');
            }

            $('input', this).on('click', function () {
                return false;
            });

            // console.log(table.column(i).data());
            // // CATCHY PART!!! Searching external users for case of drilldown external option
            // if($(this).text() == "UserName" && self.props.useDrilldownFilters == true){
            //     console.log(self.props.drilldownUsersExternal);
            //     if(self.props.drilldownUsersExternal){
            //         table.column(i).search("X-");
            //     } else{
            //         table.column(i).search("SVC-IN-VVMADMINUSER3", true);
            //         // table.column(i).search("^[^Xx].*", true, true);
            //         // table.column(i).search("^[S].*", true, true);

            //     }
            //     console.log(table);
            // }

            $('input', this).on('keyup change', function () {
                if (table.column(i).search() !== this.value) {
                    table
                        .column(i)
                        .search(this.value)
                        .draw();
                }
            });
        });

        table.on('xhr', () => {
            const params = table.ajax.params();
            this.setState({ ajaxParams: params })
        })
    }

    componentWillUnmount() {
        $(this.mainRef.current).DataTable().destroy(true);
    }

    transformData = (d) => {
        let startDate = this.state.date;
        let endDate = new Date();
        endDate.setHours(23,59,59,999);

        if(this.props.period == "custom"){
            startDate = new Date(this.state.startDate);
            endDate = new Date(this.state.endDate)
            endDate.setHours(23,59,59,999);
        }
        let engagementIds = this.props.engagementId? [this.props.engagementId]: [];

        let data = {
            ...d,
            dateFrom: startDate,
            dateTill: endDate,
            externalOrInternal: this.props.drilldownUsersExternalOrInternal,
            engagementIds: engagementIds,
            unique: this.props.unique
        }

        return JSON.stringify(data);
    }

    shouldComponentUpdate(nextProps) {

        let periodChanged = nextProps.period !== this.props.period;
        let customPeriod = nextProps.period =="custom";
        let dateChanged = nextProps.startDate != this.props.startDate || nextProps.endDate != this.props.endDate;

        if (periodChanged || (customPeriod && dateChanged)) {
            this.reloadTableData(nextProps.period, nextProps.startDate, nextProps.endDate );
        }
        return false;
    }

    reloadTableData(period, startDate, endDate) {
        const table = $(this.mainRef.current).DataTable();

        this.setState({ date: PeriodMapping[period], startDate : startDate, endDate : endDate }, () => {
            table.draw();
        });
    }

    getAllEntriesForExport = () => {
        return new Promise((resolve, reject) => {
            const params = JSON.parse(this.state.ajaxParams);
            getAnalyticEntriesForExport(params).then((response) => {
                const entries = response.data.data;
                const formatedEntries = entries.map(x => Object.values(x));
                this.setState({ entriesToExport: formatedEntries });
                resolve(formatedEntries)
            });
        })
    }

    render() {
        return (
            <div>
                <table id="report-table" ref={this.mainRef} className="table table-striped" style={{ width: '100%' }}>
                </table>
            </div>);
    }


    //Datatable setup
    getDataTableColumns = () => [
        {
            title: 'Application Path',
            data: null,
            name: 'ApplicationRequestPath',
            width: 230,
            render: data => {
                const value = (data.applicationRequestPath === '' && (data.deviceType.includes('Phone') || data.deviceType.includes('Tablet')))
                    ? `Mobile App start-up`
                    : `<a href="${data.applicationRequestPath}">${data.applicationRequestPath}</a>`;

                return value;
            }

        },
        {
            title: 'Engagement Name',
            data: 'engagementName',
            name: 'EngagementName'
        },
        {
            title: 'Dashboard Name',
            data: 'dashboardName',
            name: 'DashboardName'
        },
        {
            title: 'Chart Name',
            data: 'chartName',
            name: 'ChartName'
        },
        {
            title: 'UserName',
            data: 'userName',
            name: 'UserName'
        },
        {
            title: 'Device Type',
            data: 'deviceType',
            name: 'DeviceType'
        },
        {
            title: 'Device OS / App version',
            data: 'deviceOsType',
            name: 'DeviceOsType'
        },
        {
            title: 'Date',
            data: 'raiseDate',
            name: 'RaiseDate',
            width: 180,
            render: (data, type, row) => {
                if (type === "sort" || type === "type") {
                    return data;
                }
                const utc = moment.utc(data);
                return moment(utc).local().format("DD-MM-YYYY HH:mm:ss");
            }
        },
    ];

    getDatatableConfig = () => {
        const columns = this.getDataTableColumns();

        return {
            destroy: true,
            dom: 'flrt<ip>B',
            buttons: {
                dom: {
                    container: {
                        tag: 'div',
                        className: 'dt-buttons'
                    },
                    button: {
                        className: 'btn',
                    }
                },
                buttons: [
                    {
                        extend: 'excelHtml5',
                        className: 'btn-outline-primary',
                        text: 'Excel',
                        action: function (e, dt, node, config) {
                            const context = this;
                            componentInstance.getAllEntriesForExport().then(result => {
                                $.fn.dataTable.ext.buttons.excelHtml5.action.call(context, e, dt, node, config);
                            });
                        },
                        exportOptions: {
                            customizeData: (excel) => {
                                excel.body = this.state.entriesToExport;
                            },
                        }
                    }
                ]
            },
            columns,
            order: [[dateColumnIndex, 'desc']],
            ordering: true,
            serverSide: true,
            processing: true,
            pageLength: 100
        }
    };


    getAjaxConfig = () => {

        return {
            url: "/api/analytics",
            type: "POST",
            contentType: "application/json",
            ...(customAuthService.tokenExists()) && {
                headers: {
                    "Authorization": customAuthService.getAccessToken()
                }
            },
            ...(this.authService.tokenExists()) && {
                headers: {
                    "Authorization": this.authService.getAuthorizationHeaderValue()
                }
            },
            dataType: "json",
            error: (xhr, error, code) => {
                if (code === 403) {
                    window.location.reload();
                }
            }
        }
    }
}

// eslint-disable-next-line react/no-typos
Table.propTypes = {
    period: PropTypes.string
};

export default Table;