import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { registerUser, getSecurityQuestions } from '../services/registerUserService';
import FormValidator from '../../../common/formValidator';
import webAppConfig from '../../../config';
import authenticationTypes from '../../../common/const/authenticationTypes';
import { tokenExpired } from '../../../auth/customAuthService';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

class RegisterWithoutRouter extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'username',
                method: 'isEmpty',
                validWhen: false,
                message: 'Username is required.'
            },
            {
                field: 'username',
                method: this.usernameDifferentThanEmail,
                validWhen: true,
                message: 'Username must be different than email.'
            },
            {
                field: 'username',
                method: this.usernameMustMeetRequirements,
                validWhen: true,
                message: 'Username must be at least 4 lowercase and/or alphanumeric characters'
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required.'
            },
            {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'That is not a valid email.'
            },
            {
                field: 'password',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password is required.'
            },
            {
                field: 'password',
                method: this.passwordMustMeetRequirements,
                validWhen: true,
                message: 'Password must contains at least 6 characters, one uppercase and one digit.'
            },
            {
                field: 'passwordConfirmation',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password confirmation is required.'
            },
            {
                field: 'passwordConfirmation',
                method: this.passwordMatch,   // notice that we are passing a custom function here
                validWhen: true,
                message: 'Password and password confirmation do not match.'
            },
            {
                field: 'firstSecurityQuestionAnswer',
                method: 'isEmpty',
                validWhen: false,
                message: 'Answer is required.'
            },
            {
                field: 'secondSecurityQuestionAnswer',
                method: 'isEmpty',
                validWhen: false,
                message: 'Answer is required.'
            },
            {
                field: 'thirdSecurityQuestionAnswer',
                method: 'isEmpty',
                validWhen: false,
                message: 'Answer is required.'
            },
        ]);

        this.state = {
            username: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            firstSecurityQuestion: '',
            secondSecurityQuestion: '',
            thirdSecurityQuestion: '',
            firstSecurityQuestionAnswer: '',
            secondSecurityQuestionAnswer: '',
            thirdSecurityQuestionAnswer: '',
            availableSecurityQuestions: [],
            securityQuestions: [{ id: '', question: '' }, { id: '', question: '' }, { id: '', question: '' }],
            validation: this.validator.valid(),
            isError: false,
            errorMessage: 'An error occured. Please try again.',
            isSignedUp: false,
            isLoading: false
        };

        this.usernameRef = React.createRef();

        this.submitted = false;
    }

    componentWillMount() {
        if (webAppConfig.authenticationType === authenticationTypes.Custom && !tokenExpired()) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        setTimeout(() => { if (this.usernameRef.current != null) this.usernameRef.current.focus() }, 500)

        getSecurityQuestions().then(result => {

            const selectedQuestionsIds = [result.data[0].id, result.data[1].id, result.data[2].id]

            const availableQuestions = result.data.filter(value => !selectedQuestionsIds.includes(value.id))

            this.setState({
                securityQuestions: result.data,
                availableSecurityQuestions: availableQuestions,
                firstSecurityQuestion: selectedQuestionsIds[0],
                secondSecurityQuestion: selectedQuestionsIds[1],
                thirdSecurityQuestion: selectedQuestionsIds[2],
                selectedQuestionsIds: selectedQuestionsIds
            });
        })
    }

    handleFormSubmit = event => {
        event.preventDefault();

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            this.register();
        }
    }

    register = () => {
        this.setState({ isLoading: true })

        const registerViewModel = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            passwordConfirmation: this.state.passwordConfirmation,
            securityQuestionAnswers: [
                { answer: this.state.firstSecurityQuestionAnswer, securityQuestionId: this.state.firstSecurityQuestion },
                { answer: this.state.secondSecurityQuestionAnswer, securityQuestionId: this.state.secondSecurityQuestion },
                { answer: this.state.thirdSecurityQuestionAnswer, securityQuestionId: this.state.thirdSecurityQuestion },
            ]
        };

        registerUser(registerViewModel)
            .then((result) => {
                this.setState({ isSignedUp: true, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isError: true, errorMessage: err.response.data, isLoading: false });
            });
    }

    handleInputChange = event => {
        event.preventDefault();

        this.refreshAvailableSecurityQuestions(event);

        this.setState({
            [event.target.name]: event.target.value,
        });
    }


    refreshAvailableSecurityQuestions(changeEvent) {
        if (changeEvent.target.name === 'firstSecurityQuestion' ||
            changeEvent.target.name === 'secondSecurityQuestion' ||
            changeEvent.target.name === 'thirdSecurityQuestion') {

            const { selectedQuestionsIds, securityQuestions } = this.state;
            const question = securityQuestions.find(x => x.id === changeEvent.target.value);

            let s = selectedQuestionsIds.filter(x => x !== this.state[changeEvent.target.name]);
            s.push(question.id);

            const availableQuestions = securityQuestions.filter(value => !s.includes(value.id))

            this.setState({
                selectedQuestionsIds: s,
                availableSecurityQuestions: availableQuestions
            });
        }
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation)

    passwordMustMeetRequirements = (password, state) => {
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
        return regex.test(password);
    }

    usernameMustMeetRequirements = (username, state) => {
        const regex = new RegExp("^(?=.*[a-z])[a-z0-9]{4,20}$");
        return regex.test(username);
    }

    usernameDifferentThanEmail = (username, state) => (state.email !== username)

    renderAvailableSecurityQuestions() {
        const { availableSecurityQuestions } = this.state;

        let questions = availableSecurityQuestions.length > 0
            && availableSecurityQuestions.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.question}</option>
                )
            }, this);

        return questions;
    }

    renderSelectedOption(value) {
        let question = this.state.securityQuestions.find(x => x.id === value).question;

        return (
            <option key={value} value={value}>{question}</option>
        )
    }

    render() {
        if (this.state.isLoading)
            return <LoadingIndicator />

        let validation = this.submitted ?                         // if the form has been submitted at least once
            this.validator.validate(this.state) :   // then check validity every time we render
            this.state.validation                   // otherwise just use what's in state

        return (
            <div>
                <div className="signup__container">
                    <div className="row row-container">
                        <div className="col-md-4 bootstrap-col-nopadding">
                            <div className="container__child signup__thumbnail">
                                <div className="thumbnail__content text-center">
                                    <h1 className="heading--primary">Welcome to Rapport</h1>
                                    <h2 className="heading--secondary">Create an account</h2>
                                </div>
                                <div className="signup__overlay"></div>
                            </div>
                        </div>
                        <div className="col-md-8 bootstrap-col-nopadding">
                            <div className="container__child signup__form">
                                <form style={{ width: '100%' }} action="#">
                                    {this.state.isSignedUp
                                        ?
                                        <div>
                                            <h2 className="text-muted">Success!</h2>
                                            <hr />
                                            <div className="alert alert-info">
                                                Thank you for requesting an account. Your account needs to be validated and activated by the administrator. Once activated, you will get a notification in the system or email from administrator.
                                            </div>
                                            <div className="alert alert-secondary">
                                                Please remember the answers to security questions provided during registration. You will need it in case you forgot the password.
                                            </div>
                                            <br />
                                            <a href="/account/login" className="btn btn-block btn-outline-primary">Go back</a>
                                        </div>
                                        : null
                                    }
                                    {!this.state.isSignedUp
                                        ?
                                        <div>
                                            <div>
                                                <h4 className="roboto-dark-gray">Account details</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            tabIndex="1"
                                                            ref={this.usernameRef}
                                                            onChange={this.handleInputChange}
                                                            className="form-control input--form"
                                                            autoComplete="off"
                                                            type="text"
                                                            name="username"
                                                            id="username"
                                                            placeholder="Username"
                                                        />
                                                        <small className="form-text text-danger">
                                                            {validation.username.message}
                                                        </small>
                                                    </div>

                                                    <div className="form-group">

                                                        <input
                                                            tabIndex="2"

                                                            onChange={this.handleInputChange}
                                                            className="form-control input--form"
                                                            autoComplete="off"
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Password"
                                                        />
                                                        <small className="form-text text-danger">
                                                            {validation.password.message}
                                                        </small>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">

                                                        <input
                                                            tabIndex="1"
                                                            onChange={this.handleInputChange}
                                                            className="form-control input--form"
                                                            autoComplete="off"
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                        />
                                                        <small className="form-text text-danger">
                                                            {validation.email.message}
                                                        </small>
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            tabIndex="3"
                                                            onChange={this.handleInputChange}
                                                            className="form-control input--form"
                                                            autoComplete="off"
                                                            type="password"
                                                            name="passwordConfirmation"
                                                            id="passwordConfirmation"
                                                            placeholder="Repeat Password"
                                                        />
                                                        <small className="form-text text-danger">
                                                            {validation.passwordConfirmation.message}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 className="roboto-dark-gray"> Security questions</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12" style={{ paddingTop: '10px' }}>
                                                    <div className="form-group">
                                                        <select tabIndex="4" className="form-control input--form" type="text" placeholder="Answer..." name="firstSecurityQuestion" onChange={this.handleInputChange} value={this.state.firstSecurityQuestion}>
                                                            {this.renderSelectedOption(this.state.firstSecurityQuestion)}
                                                            {this.renderAvailableSecurityQuestions()}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            tabIndex="5"
                                                            onChange={this.handleInputChange}
                                                            className="form-control input--form"
                                                            autoComplete="off"
                                                            placeholder="Answer"
                                                            name="firstSecurityQuestionAnswer"
                                                        />
                                                        <small className="form-text text-danger">
                                                            {validation.firstSecurityQuestionAnswer.message}
                                                        </small>
                                                    </div>
                                                    <div className="form-group">
                                                        <select tabIndex="6" className="form-control input--form" type="text" placeholder="Answer..." name="secondSecurityQuestion" onChange={this.handleInputChange} value={this.state.secondSecurityQuestion}>
                                                            {this.renderSelectedOption(this.state.secondSecurityQuestion)}
                                                            {this.renderAvailableSecurityQuestions()}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            tabIndex="7"
                                                            onChange={this.handleInputChange}
                                                            className="form-control input--form"
                                                            autoComplete="off"
                                                            placeholder="Answer"
                                                            name="secondSecurityQuestionAnswer"
                                                        />
                                                        <small className="form-text text-danger">
                                                            {validation.secondSecurityQuestionAnswer.message}
                                                        </small>
                                                    </div>
                                                    <div className="form-group">
                                                        <select tabIndex="8" className="form-control input--form" type="text" placeholder="Answer..." name="thirdSecurityQuestion" onChange={this.handleInputChange} value={this.state.thirdSecurityQuestion}>
                                                            {this.renderSelectedOption(this.state.thirdSecurityQuestion)}
                                                            {this.renderAvailableSecurityQuestions()}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            tabIndex="9"
                                                            onChange={this.handleInputChange}
                                                            className="form-control input--form"
                                                            autoComplete="off"
                                                            placeholder="Answer"
                                                            name="thirdSecurityQuestionAnswer"
                                                        />
                                                        <small className="form-text text-danger">
                                                            {validation.thirdSecurityQuestionAnswer.message}
                                                        </small>
                                                    </div>
                                                    <div className="m-t-lg">
                                                        <ul className="list-inline">
                                                            {this.state.isError ?
                                                                <li>
                                                                    <small className="form-text text-danger">
                                                                        {this.state.errorMessage}
                                                                    </small>
                                                                    <br />
                                                                </li>
                                                                : null}
                                                            <li>
                                                                <input
                                                                    className="btn btn--form"
                                                                    type="button"
                                                                    onClick={this.handleFormSubmit}
                                                                    value="Register"
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        );
    }
}

export const Register = withRouter(RegisterWithoutRouter);
