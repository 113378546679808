import { httpClient } from '../../../common/httpClient';

const makeScreenShot = (data) => httpClient({
    url: `api/export/makeScreenShot`,
    method: "post",
    data: {
        PageUrl: data.PageUrl
    },
    headers: {
        "Content-Type": "application/json"
    }   
});

export { makeScreenShot };