import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

class NoAccess extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>

          <Alert variant="light">
            {/* <Alert.Heading>Unauthorized</Alert.Heading> */}
            <Alert.Heading>! Restricted Access !</Alert.Heading>
            {/* <p>
            You do not have access to this resource. 
            </p> */}
            <p>
            To get VVM Rapport Application Access, please Click on “Contact Us” button on left side menu and mention following Details.
            </p>          
            <br />
            <p><b>Name:</b></p>
            <p><b>Email ID:</b></p>
            <p><b>Engagement Name:</b></p>
            <p><b>Message:</b></p>
          </Alert>
      </div>
    );
  }
}
export { NoAccess }