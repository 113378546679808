import React, { Component } from 'react';
import { getAll, assignToDashboard, unassignFromDashboard } from "../../services/dashboardUsers";
import { ReadonlyUser, ROLES } from '../../../../auth/userRoles';
import { Button, Table, Form } from 'react-bootstrap';
import { MyTooltip } from '../../../../components/MyTooltip';
import { faSave, faPlusCircle, faWindowClose, faTrashAlt, faFileImport } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { toast } from "react-toastify";
import { getErrorMessage } from '../../services/common';
import { UploadUsersFromCsvComponent } from '../../common/UploadUsersFromCsvComponent';
import moment from 'moment';
import { defaultsDeep } from 'lodash';

const PAGE_SIZE = 10;

class AccessToDashboardComponent extends Component {

    constructor(props) {

        super(props);
        this.state = {
            blocking: false,
            isAdding: false,
            newItem: {
                userLogin: '',
                dashboardId: 0,
                roleId: ROLES[ReadonlyUser]
            },
            users: [],
            pageOfItems: [],
            showImportFromCsv: false,
            hideImportButton: false
        };
    }

    async componentDidMount() {

        await this.refreshData();
    }

    assignNewUser = async () => {

        let newItem = this.state.newItem;
        newItem.dashboardId = this.props.dashboardId;

        let userAlreadyExists = this.state.users.some(x => x.userLogin.toUpperCase() == newItem.userLogin.toUpperCase());
        if (userAlreadyExists) {
            toast.warn("User already exists");
            return;
        }

        try {

            await assignToDashboard(newItem.dashboardId, newItem)
            const users = [...this.state.users, newItem];
            this.setState({
                blocking: false,
                isAdding: false,
                users: users,
                newItem: {
                    userLogin: '',
                    dashboardId: 0,
                    roleId: ROLES[ReadonlyUser]
                },
            });

            toast.info("User assigned successfully.");
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    unassignUser = async (userLogin) => {

        this.setState({ blocking: true });

        const toDelete = this.state.users.find(x => x.userLogin === userLogin);
        if (toDelete === null)
            return;

        try {

            await unassignFromDashboard(toDelete.dashboardId, toDelete);
            const newData = [...this.state.users.filter(x => x !== toDelete)];

            this.setState({
                users: newData,
                blocking: false
            })

            toast.info("User unassigned successfully.");
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    newItemOnUserLoginChange = (userLogin) => {

        let newItem = this.state.newItem;
        newItem.userLogin = userLogin.toUpperCase();
        this.setState({ newItem: newItem });
    }

    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }

    refreshData = async () => {
        try {
            this.setState({ blocking: true });
            let result = await getAll(this.props.dashboardId);
            this.setState({ users: result.data, blocking: false, showImportFromCsv: false });
        }
        catch (ex) { }
    }

    fileSelected = () => {
        this.setState({ hideImportButton: true })
    }

    uploadDone = async () => {
        await this.refreshData();
        this.setState({ showImportFromCsv: false, hideImportButton: false })
    }

    uploadFailed = async () => {
        this.setState({ showImportFromCsv: false, hideImportButton: false })
    }

    renderAddNewItem() {

        if (!this.state.isAdding) {
            return null;
        }

        return (
            <tr>
                <td></td>
                <td>
                    <Form.Control type="text" placeholder="Type a username..." size="sm"
                        onChange={e => { this.newItemOnUserLoginChange(e.target.value); }}
                        value={this.state.newItem.userLogin}>
                    </Form.Control>
                </td>
                <td></td>
                <td>
                    <Button size="sm" disabled={this.state.newItem.userLogin == ''} onClick={() => this.assignNewUser()} variant="outline-success">
                        <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button size="sm" onClick={() => this.setState({
                        isAdding: false, newItem: {
                            userLogin: '',
                            engagementId: 0,
                            roleId: ROLES[ReadonlyUser]
                        },
                    })} variant="outline-primary">
                        <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                </td>
            </tr>
        );
    }

    renderBodyTable() {

        return (
            this.state.users.map((listValue, index) => {
            let createdDate = this.checkDefaultDate(listValue.createdDate);
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{listValue.userLogin}</td>
                        <td>{createdDate}</td>
                        <td>
                            <Button size="sm" onClick={() => this.unassignUser(listValue.userLogin)} variant="outline-danger">
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </td>
                    </tr>
                );
            })
        );
    }

    checkDefaultDate(createdDate) {
        return createdDate !== "0001-01-01T00:00:00" ? moment.utc(createdDate).local().format("DD-MM-YYYY HH:mm:ss") : "";
    }

    render() {
        return (
            <BlockUIContainer blocking={this.state.blocking}>
                <br />

                <div className="d-flex">
                    <div>
                        <Button variant="outline-primary mr-2" size="sm" onClick={() => this.setState({ isAdding: true })}>
                            <FontAwesomeIcon icon={faPlusCircle} /> Add new user
                        </Button>
                        <MyTooltip tooltip="CSV Template: User,Role USER_ID,ReadonlyUser ">
                            <Button variant="outline-success" size="sm" hidden={this.state.hideImportButton}
                                onClick={() => this.setState({ showImportFromCsv: true })}>
                                <FontAwesomeIcon icon={faFileImport} /> Import from CSV
                            </Button>
                        </MyTooltip>

                        {this.state.showImportFromCsv ?
                            <UploadUsersFromCsvComponent
                                dashboardId={this.props.dashboardId}
                                fileSelected={this.fileSelected}
                                uploadDone={this.uploadDone}
                                uploadFailed={this.uploadFailed}
                                chooseFile={this.state.showImportFromCsv}
                            />
                            : null
                        }
                    </div>
                    <div className="ml-auto">
                        <p>Total Count: <b>{this.state.users.length}</b></p>
                    </div>
                </div>
                <br />
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>User</th>
                            <th>Created date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderAddNewItem()}
                        {this.renderBodyTable()}
                    </tbody>
                </Table>
            </BlockUIContainer>
        );
    }
}

export { AccessToDashboardComponent };
