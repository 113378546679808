import React, { Component } from 'react';
import { getById, getAll } from "../../services/charts";
import { connect } from 'react-redux';
import { selectLayoutModeRequest } from '../../../../redux/actions/selectLayoutMode'
import { withRouter } from "react-router-dom";
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { makeScreenShot } from "../../services/export";
import { selectChartRequest } from '../../../../redux/actions/selectChart';
import { breadcrumbsRequest } from '../../../../redux/actions/breadcrumbs';
import { searchChartRequest } from "../../../../redux/actions/search";

class ChartDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            data: {},
            width: window.innerWidth - 80,
            height: window.innerHeigh - 7,
            breadCrumbsItems: [],
            chartId: this.props.match.params.chartId,
            dashboardId: this.props.match.params.dashboardId
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.chartId === this.state.chartId)
            return;

        this.setState({ chartId: nextProps.match.params.chartId })
        this.fetchChart(nextProps.match.params.dashboardId, nextProps.match.params.chartId);
    }

    componentDidMount() {
        this.props.selectLayoutMode('full')
        this.fetchChart(this.state.dashboardId, this.state.chartId);

    }

    componentWillUnmount() {
        this.props.selectLayoutMode('normal')
        this.props.selectChart(null);
    }

    getPrevNextIds(charts, currentChart) {
        let prevId, nextId;

        const chartArrayLenght = charts.length;
        const selectedChartIndex = charts.findIndex(x => x.id === currentChart.id);

        if (chartArrayLenght === 1) {
            nextId = null;
            prevId = null;
        }
        else if (selectedChartIndex === 0) {
            nextId = charts[selectedChartIndex + 1].id
            prevId = charts[chartArrayLenght - 1].id;
        }
        else if (selectedChartIndex === chartArrayLenght - 1) {
            nextId = charts[0].id;
            prevId = charts[selectedChartIndex - 1].id
        }
        else {
            nextId = charts[selectedChartIndex + 1].id
            prevId = charts[selectedChartIndex - 1].id
        }

        return { prevId, nextId }
    }

    async fetchChart(dashboardId, chartId) {
        this.setState({
            isLoaded: false
        })

        try {
            const chartArrayFetch = await getAll(dashboardId);
            const chartFetch = await getById(chartId);

            const chartArray = chartArrayFetch.data;
            const chart = chartFetch.data;

            if (chart.openInNewTab) {
                window.open(chart.url, "_blank", 'noopener,noreferrer');
            }

            const prevAndNextIds = this.getPrevNextIds(chartArray, chart);

            this.props.changeBreadcrumbs({
                engagementName: chart.engagementName,
                dashboardName: chart.dashboardName,
                chartName: chart.displayName
            })

            chart.prevId = prevAndNextIds.prevId
            chart.nextId = prevAndNextIds.nextId

            this.props.selectChart(chart);

            this.setState({
                data: chart
            })
        }
        catch (ex) {

            this.setState({
                isLoaded: true
            })
        }
    }

    hideSpinner = () => {
        setTimeout(() =>
            this.setState({
                isLoaded: true
            }), 500);
    };

    exportClicked = (e) => {
        e.preventDefault();
        makeScreenShot({
            PageUrl: this.state.data.url
        })
    };

    render() {

        if (this.state.data.openInNewTab) {
            return (
                <div style={{ height: '100%', width: '100%', textAlign: 'center', verticalAlign: 'center' }}>
                    <p>The content of the chart could not be displayed under Rapport and has been opened in a new tab.</p>
                    <p>URL: <a href={this.state.data.url}>{this.state.data.url}</a></p>
                </div>
            );
        }

        return (
            <div>
                <LoadingIndicator hidden={this.state.isLoaded} />
                <iframe onLoad={this.hideSpinner} id="chart-iframe" title="chart-details"
                    style={{
                        width: 'calc(100vw - 85px)', height: 'calc(100vh - 76px)', border: 'none',
                        display: this.state.isLoaded ? 'visible' : 'hidden'
                    }}
                    src={this.state.data.url}>
                </iframe>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectLayoutMode: (mode) => {
            dispatch(selectLayoutModeRequest(mode))
        },
        resetSearchChartValue: () => {
            dispatch(searchChartRequest(""))
        },
        selectChart: (chart) => {
            dispatch(selectChartRequest(chart))
        },
        changeBreadcrumbs: (breadcrumbs) => {
            dispatch(breadcrumbsRequest(breadcrumbs))
        }
    }
}

export default withRouter(connect(null, mapDispatchToProps)(ChartDetailsComponent))
