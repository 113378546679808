import merge from 'lodash/merge';
import authenticationTypes from './common/const/authenticationTypes'

const webAppMainConfig = {
  authenticationType: authenticationTypes.CAMAuth,
};

/**
 * Provides configuration for whole web app.
 * Provides dynamic config per environment by using dynamic config file.
 * Each change in dynamic config after deployment requires dynamic config [UniqueId] to be changed
 * and update of corresponding script tag in index.html or set "no-cache" header for this file
 * 
 * NOTE: Unless values in Dynamic config are set to udefined - our config will not be overridden i.e webAppMainConfig value will be used.
 * 
 */
const webAppConfig = merge(webAppMainConfig, window.webAppDynamicConfig);
export default webAppConfig;