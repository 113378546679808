import { SELECT_TOGGLE_MODE_REQUEST } from '../constants/ActionTypes'

const initState = false;

const selectToggleReducer = (state = initState, action) => {

    switch(action.type){
        case SELECT_TOGGLE_MODE_REQUEST:
            return action.mode;  
        default:
            return state;
    }

}

export default selectToggleReducer;