import React, { Component } from 'react';
import { getAll, remove, activate, suspend } from "../../services/userAccounts";
import { Button, Table, Modal } from 'react-bootstrap';
import { faTrashAlt, faCheckCircle, faTimesCircle, faPaperPlane, faCopy } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { toast } from "react-toastify";
import { MyTooltip } from '../../../../components/MyTooltip';
import { DeleteModal } from '../../../../components/DeleteModal';


class UserAccountsListComponent extends Component {

    constructor(props) {

        super(props);
        this.state = {
            blocking: false,
            isAdding: false,
            users: [],
            deleting: {},
            lastActivatedUser: {},
            showModal: false,
            showSendNotificationModal: false
        };

        this.sampleEmailTextArea = React.createRef();

    }

    async componentDidMount() {

        this.setState({ blocking: true });
        try {

            let result = await getAll();

            this.setState({
                users: result.data,
                blocking: false
            })
        }
        catch (ex) { }
    }

    confirmationModalShow(id) {

        let data = this.state.users.find(x => x.id === id);
        const deleting = {
            category: 'user account',
            title: data.username,
            id: data.id
        };
        this.setState({ showModal: true, deleting: deleting })
    }

    removeUserAccount = async () => {

        this.setState({ blocking: true });

        const id = this.state.deleting.id;
        const toDelete = this.state.users.find(x => x.id === id);
        if (toDelete === null)
            return;

        try {
            await remove(toDelete.id);
            const newData = this.state.users.filter(x => x.id != id);

            this.setState({
                users: newData,
                blocking: false,
                showModal: false
            })

            toast.info("User account removed successfully.");
        }
        catch (ex) {

            this.setState({ blocking: false, showModal: false });
        }
    }

    activateUser = async (id) => {

        this.setState({ blocking: true });

        try {
            await activate(id);

            let users = [...this.state.users];
            let user = users.find(x => x.id == id);
            user.isActive = true;

            setTimeout(() => this.setState({ showSendNotificationModal: true }), 1000)

            this.setState({
                users: users,
                blocking: false,
                lastActivatedUser: user,
            })
            toast.info("User activated.");
        }
        catch (ex) {

            this.setState({ blocking: false });
        }
    }

    suspendUser = async (id) => {

        this.setState({ blocking: true });

        try {
            await suspend(id);

            let users = [...this.state.users];
            let user = users.find(x => x.id == id);
            user.isActive = false;

            this.setState({
                users: users,
                blocking: false
            })
            toast.info("User suspended.");
        }
        catch (ex) {

            this.setState({ blocking: false });
        }
    }

    renderBodyTable() {
        
        return (
            this.state.users.map((listValue, index) => {
                return (
                    <tr key={index}>
                        <td>{listValue.username}</td>
                        <td>{listValue.email}</td>
                        <td>{listValue.isActive === true
                            ? <div className="text-success"><FontAwesomeIcon icon={faCheckCircle} /></div>
                            : <div>
                                {listValue.reachedMaximumPasswordResetAttempts === true
                                    ? <div className="text-danger">The user reached the maximum password reset attempts. Click 'Activate' to reset attempts counter.</div>
                                    : null
                                }
                                <div className="text-secondary"><FontAwesomeIcon icon={faTimesCircle} /></div>
                            </div>
                        }</td>
                        <td>
                            {listValue.username.toUpperCase() !== this.props.user.toUpperCase() ?
                                `${new Date(listValue.signedUpAt).toLocaleDateString()} ${new Date(listValue.signedUpAt).toLocaleTimeString()}`
                                : ''
                            }
                        </td>
                        <td>
                            <div>
                                <MyTooltip tooltip="Delete">
                                    <Button size="sm" disabled={listValue.username.toUpperCase() == this.props.user.toUpperCase()} onClick={() => this.confirmationModalShow(listValue.id)} variant="outline-danger">
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </MyTooltip>
                                {listValue.isActive ?
                                    <MyTooltip tooltip="Suspend">
                                        <Button size="sm" disabled={listValue.username.toUpperCase() == this.props.user.toUpperCase()} onClick={() => this.suspendUser(listValue.id)} variant="outline-secondary">
                                            <FontAwesomeIcon icon={faTimesCircle} />
                                        </Button>
                                    </MyTooltip>
                                    :
                                    <MyTooltip tooltip="Activate">
                                        <Button size="sm" disabled={listValue.username.toUpperCase() == this.props.user.toUpperCase()} onClick={() => this.activateUser(listValue.id)} variant="outline-success">
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                        </Button>
                                    </MyTooltip>
                                }
                                {listValue.isActive ?
                                    <MyTooltip tooltip="Send notification">
                                        <Button size="sm" disabled={listValue.username.toUpperCase() == this.props.user.toUpperCase()} onClick={() => this.setState({ showSendNotificationModal: true, lastActivatedUser: listValue })} variant="outline-primary">
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                        </Button>
                                    </MyTooltip>
                                    : null
                                }
                            </div>
                        </td>                        
                    </tr>
                );
            })
        );
    }


    hideSendActivationEmailModal = () => {
        this.setState({ showSendNotificationModal: false });
    }


    copyEmailTemplateToClipboard = () => {
        this.sampleEmailTextArea.current.select();
        document.execCommand('copy');

        toast.info("Text copied");
    }

    renderSendActivationEmailModal() {

        const port = window.location.port === 80 || window.location.port === 443 ? '' : `:${window.location.port}`;
        const baseURL = `${window.location.protocol}//${window.location.hostname}${port}/account/login`;
        const message = `Hi, \n\nYour account has been validated and activated.\nType the following URL in your browser in order to use the Rapport application. Use credentials provided during sign up. \n \n${baseURL}\n\nRegards,\nSystem Administrator`
        const userEmailTemplate = '<user_email>'
        let href = `mailto:${userEmailTemplate}?subject=Account activated by System Administrator`;

        if (this.state.lastActivatedUser !== undefined) {
            href = href.replace(userEmailTemplate, this.state.lastActivatedUser.email)
        }


        return (
            <Modal
                show={this.state.showSendNotificationModal}
                onHide={() => this.setState({ showSendNotificationModal: false })}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Notify user about account activation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>
                            Send an email manually to <strong>{this.state.lastActivatedUser.email}</strong> whose account has been activated. You can use below template.
                        </p>
                        <textarea ref={this.sampleEmailTextArea} defaultValue={message} readOnly style={{ width: '100%', height: 260, resize: 'none' }}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <MyTooltip tooltip="Copy text to clipboard">
                        <Button size="sm" onClick={this.copyEmailTemplateToClipboard} variant="outline-primary">
                            <span style={{ paddingRight: 7 }}> Copy text</span>
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </MyTooltip>
                    <a className="btn btn-sm btn-outline-primary" href={href}>
                        <span style={{ paddingRight: 7 }}>Send an email</span>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </a>
                </Modal.Footer>
            </Modal>
        )
    }

    renderDeleteModal() {
        return (
            <DeleteModal show={this.state.showModal}
                deleting={this.state.deleting}
                onconfirmdelete={(this.removeUserAccount)}
                onHide={() => this.setState({ showModal: false })} />
        )
    }
    render() {

        return (
            <div>
                <h4 style={{ color: 'gray', fontSize: 18 }}>User accounts</h4>
                <BlockUIContainer blocking={this.state.blocking}>
                    {this.renderDeleteModal()}
                    {this.renderSendActivationEmailModal()}
                    <br />
                    <br />
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>IsActive</th>
                                <th>SignedUp</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderBodyTable()}
                        </tbody>
                    </Table>
                </BlockUIContainer>
            </div>
        );
    }
}

export { UserAccountsListComponent };
