import React from 'react'
import { uploadFile, getUploadedFiles } from '../../services/upload';
import { Form, Button, Table, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { UploadFilesList } from './UploadedFilesListComponent';

class UploadFileComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            blocking: false,
            uploadedFilesList: [],
            shouldRefreshList: true,
            version: '',
            description: ''
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }

    componentDidMount() {

    }



    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    onFormSubmit(e) {
        e.preventDefault() // Stop form submit

        this.toggleBlocking();

        const file = this.state.file;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', this.state.description);
        formData.append('version', this.state.version);

        uploadFile(formData).then((response) => {
            toast.info("File uploaded successfully");
            this.setState({ shouldRefreshList: true, blocking: false, file: null });
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error[0]);
            }
            this.setState({ blocking: false, file: null });
        });
    }

    onFileChange(e) {
        this.setState({ file: e.target.files[0] })
    }

    onVersionChange(value) {
        this.setState({ version: value });
    }

    onDescriptionChange(value) {
        this.setState({ description: value });
    }

    fileUpload(file) {
        const url = '/api/files';
        const formData = new FormData();
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return uploadFile;
    }

    render() {
        return (
            <BlockUIContainer blocking={this.state.blocking}>
                <br />
                <Form onSubmit={this.onFormSubmit}>
                    <Form.Control style={{ width: '100%' }} type="file" accept=".apk,.ipa" onChange={this.onFileChange} />
                    {this.state.file != null ?
                        <Form.Group style={{ paddingTop: 20 }}>
                            <Form.Control onChange={e => { this.onVersionChange(e.target.value) }} type="text" placeholder="Enter app version..." required />
                            <Form.Control onChange={e => { this.onDescriptionChange(e.target.value) }} as="textarea" placeholder="Enter description..." required />
                            <Button type="submit" variant="primary" size="sm">Upload</Button>
                        </Form.Group>
                        : null
                    }
                </Form>
                <br />
                <UploadFilesList refresh={this.state.shouldRefreshList} />
            </BlockUIContainer>

        )
    }
}

export { UploadFileComponent }