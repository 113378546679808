const getErrorMessage = (exception) => {
    let message = exception.data;
    if (typeof exception.data === 'object') {
        if (Array.isArray(exception.data[Object.keys(exception.data)[0]])) {
            message = exception.data[Object.keys(exception.data)[0]][0];
        }
        else {
            message = exception.data[Object.keys(exception.data)[0]];
        }
    }
    return message;
}

export { getErrorMessage }