import { ROLES, Administrator } from './userRoles';

const isAdminOfEngagement = (user, engagementId) => {
    let hasAccess = false;
    if (user.isSystemAdmin) {
        hasAccess = true;
    }

    const permissions = Object.entries(user.accessToEngagements);
    const isAdmin = permissions.find(x => x[0] == engagementId && x[1] == ROLES[Administrator]);
    if (isAdmin) {
        hasAccess = true;
    }
    return hasAccess;
}

const isEngagementAdmin = (user) =>{
    let hasAccess = false;
    if (user.isSystemAdmin) {
        hasAccess = true;
    }

    const permissions = Object.entries(user.accessToEngagements);
    const isAdmin = permissions.length > 0;

    if (isAdmin) {
        hasAccess = true;
    }
    return hasAccess;
}


export { isAdminOfEngagement, isEngagementAdmin }