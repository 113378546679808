import React, { Component } from 'react';
import { CAMAuthService } from '../../auth/CAMAuthService';
import { connect } from 'react-redux';
import { authenticateUserRequest } from '../../redux/actions/auth'

class CAMAuthCallbackComponent extends Component {

    constructor() {
        super();
        this.authService = new CAMAuthService();
    }

    async componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code_verifier = urlParams.get("token");
        // console.log(token);
        try {
            let authResult = await this.authService.completeAuthentication(code_verifier);
            if (authResult && authResult.data && authResult.data.access_token) {

                await this.authService.setSession(authResult.data);

                let pathToRedirect = localStorage.getItem(CAMAuthService.pathToRedirect)
                if (pathToRedirect === '/')
                    pathToRedirect = '/engagements';

                this.props.markAsAuthenticated();
                this.props.history.push(pathToRedirect);
            }
        }
        catch (err) {
            console.log(err);
            this.props.history.push("/");
        }
        // try {
        //     let authResult = await this.authService.completeAuthentication();
        //     if (authResult && authResult.data && authResult.data.access_token) {

        //         await this.authService.setSession(authResult.data);

        //         let pathToRedirect = localStorage.getItem(OAuthService.pathToRedirect)
        //         if (pathToRedirect === '/')
        //             pathToRedirect = '/engagements';

        //         this.props.markAsAuthenticated();
        //         this.props.history.push(pathToRedirect);
        //     }
        // }
        // catch (err) {
        //     console.log(err);
        //     this.props.history.push("/");
        // }
    }

    render() {

        return (
            <div>
                <p>Authentification callback processing..</p>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        markAsAuthenticated: () => {
            dispatch(authenticateUserRequest(true))
        }
    }
}


export const CAMAuthCallback = connect(mapStateToProps, mapDispatchToProps)(CAMAuthCallbackComponent);
