import customAuthClient from "../auth/axiosAuthClient";

const httpClient = (config) => customAuthClient(config)
    .then(e => {
        return e;
    })
    .catch(e => {
        if(e.response !== undefined) {
            throw e.response;
        }

        throw e;
    });

export { httpClient };