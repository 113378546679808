import React, { Component } from 'react';
import { OAuthService } from '../../auth/OAuthService';
import { connect } from 'react-redux';
import { authenticateUserRequest } from '../../redux/actions/auth'

class AuthCallbackComponent extends Component {

    constructor() {
        super();
        this.authService = new OAuthService();
    }

    async componentDidMount() {
        console.log('calling did mount Auth Callback');
        try {
            let authResult = await this.authService.completeAuthentication();
            if (authResult && authResult.data && authResult.data.access_token) {

                await this.authService.setSession(authResult.data);

                let pathToRedirect = localStorage.getItem(OAuthService.pathToRedirect)
                if (pathToRedirect === '/')
                    pathToRedirect = '/engagements';

                this.props.markAsAuthenticated();
                this.props.history.push(pathToRedirect);
            }
        }
        catch (err) {
            console.log(err);
            this.props.history.push("/");
        }
    }

    render() {

        return (
            <div>
                <p>Authentification callback processing..</p>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        markAsAuthenticated: () => {
            dispatch(authenticateUserRequest(true))
        }
    }
}


export const AuthCallback = connect(mapStateToProps, mapDispatchToProps)(AuthCallbackComponent);
