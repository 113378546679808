import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class BlockUIContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
        };
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.props.blocking} renderChildren={false} keepInView>
                    {this.props.children}
                </BlockUi>
            </div>
        );
    }
}

export { BlockUIContainer };