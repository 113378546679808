import React, { Component } from 'react';
import { AccessToEngagementComponent } from "../accessToEngagement/AccessToEngagementComponent";
import { DashboardList } from '../../dashboards/dashboardList/DashboardListComponent';
import { NoAccess } from '../../../../pages/NoAccess';
import { ROLES, Administrator } from '../../../../auth/userRoles';
import { isAdminOfEngagement } from '../../../../auth/authorizationService';
import { Tabs, Tab } from 'react-bootstrap';
import { getById } from '../../services/engagements';

class EngagementDetailsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            blocking: false,
            hasAccess: false,
            isDataLoaded: false,
            engagement: {}
        };
    }

    async componentDidMount() {

        await this.fetchEngagement();
    }

    fetchEngagement = async () => {

        const hasAccess = isAdminOfEngagement(this.props.user, this.props.params.engagementId);

        if(!hasAccess) {

            this.setState({
                hasAccess: false,
                isDataLoaded: true
            })
            return;
        }

        try {

            const { engagementId } = this.props.params;
            let result = await getById(engagementId);

            this.setState({
                hasAccess: true,
                engagement: result.data,
                isDataLoaded: true
            })
        }
        catch(e) {

            this.setState({
                hasAccess: true,
                isDataLoaded: true
            })
        }
    }
    
    render() {

        if (!this.state.isDataLoaded) {
            return null
        }

        if (!this.state.hasAccess) {
            return <NoAccess />
        }

        const engagement = this.state.engagement;
        return (
            <div>
                <h4 style={{ color: 'gray', fontSize: 18 }}>Details of {engagement.displayName}</h4>
                <br />
                <Tabs defaultActiveKey="dashboards" id="uncontrolled-tab-example">
                    <Tab eventKey="dashboards" title="Dashboards List">
                        <DashboardList engagementId={engagement.id} />
                    </Tab>
                    <Tab eventKey="users" title="Engagement Permissions">
                        <AccessToEngagementComponent engagementId={engagement.id} />
                    </Tab>
                </Tabs>
            </div>

        );
    }
}

export { EngagementDetailsComponent };
