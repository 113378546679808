import { httpClient } from '../../../common/httpClient';

export const updateMobileSettings = (data) => httpClient({
    url: `api/mobileSettings`,
    method: "put",
    data: data
});

export const getMobileSettings = () => httpClient({
    url: `api/mobileSettings`,
    method: "get",
});