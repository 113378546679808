import { httpClient } from '../../../common/httpClient';

const getById = (id) => httpClient({
    url: `api/engagements/${id}`,
    method: "get",
});


const getAll = () => httpClient({
    url: `api/engagements`,
    method: "get",
});

export { getAll, getById }