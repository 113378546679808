import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import FormValidator from '../../../common/formValidator';
import webAppConfig from '../../../config';
import authenticationTypes from '../../../common/const/authenticationTypes';
import { tokenExpired } from '../../../auth/customAuthService';
import { getRandomSecurityQuestion, resetPassword } from '../services/resetPasswordService';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

class ResetPasswordWithoutRouter extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.firstStepValidator = new FormValidator([
            {
                field: 'username',
                method: 'isEmpty',
                validWhen: false,
                message: 'Username is required.'
            },
            {
                field: 'username',
                method: this.usernameDifferentThanEmail,
                validWhen: true,
                message: 'Username must be different than email.'
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required.'
            },
            {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'That is not a valid email.'
            },

        ]);

        this.secondStepValidator = new FormValidator([
            {
                field: 'password',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password is required.'
            },
            {
                field: 'password',
                method: this.passwordMustMeetRequirements,
                validWhen: true,
                message: 'Password must contains at least 6 characters, one uppercase and one digit.'
            },
            {
                field: 'passwordConfirmation',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password confirmation is required.'
            },
            {
                field: 'passwordConfirmation',
                method: this.passwordMatch,   // notice that we are passing a custom function here
                validWhen: true,
                message: 'Password and password confirmation do not match.'
            },
            {
                field: 'securityQuestionAnswer',
                method: 'isEmpty',
                validWhen: false,
                message: 'Answer is required.'
            },
        ]);

        this.state = {
            username: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            securityQuestion: {},
            securityQuestionAnswer: '',
            firstStepValidation: this.firstStepValidator.valid(),
            secondStepValidation: this.secondStepValidator.valid(),
            isError: false,
            errorMessage: 'An error occured. Please try again.',
            isSecondStep: false,
            isPasswordReset: false,
            isLoading: false
        };

        this.usernameRef = React.createRef();

        this.submitted = false;
        this.secondStepSubmitted = false;
    }

    componentWillMount() {
        if (webAppConfig.authenticationType === authenticationTypes.Custom && !tokenExpired()) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.usernameRef.current != null)
                this.usernameRef.current.focus()
        }, 500)
    }

    handleFormSubmit = event => {
        event.preventDefault();

        const validation = this.secondStepValidator.validate(this.state);
        this.setState({ validation });
        this.secondStepSubmitted = true;

        if (validation.isValid) {
            this.resetPassword();
        }
    }

    handleNextButton = event => {
        event.preventDefault();

        const validation = this.firstStepValidator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            getRandomSecurityQuestion(this.state.username, this.state.email)
                .then((result) => {
                    this.setState({ isSecondStep: true, securityQuestion: result.data });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ isError: true, errorMessage: err.response.data });
                });
        }

    }

    resetPassword = () => {
        this.setState({ isLoading: true });

        const resetPasswordViewModel = {
            username: this.state.username,
            email: this.state.email,
            newPassword: this.state.password,
            newPassswordConfirmation: this.state.passwordConfirmation,
            securityQuestionId: this.state.securityQuestion.id,
            securityQuestionAnswer: this.state.securityQuestionAnswer
        };

        resetPassword(resetPasswordViewModel)
            .then((result) => {
                this.setState({ isPasswordReset: true, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isError: true, isLoading: false, errorMessage: err.response.data });
            });
    }

    handleInputChange = event => {
        event.preventDefault();

        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation)

    passwordMustMeetRequirements = (password, state) => {
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
        return regex.test(password);
    }

    usernameDifferentThanEmail = (username, state) => (state.email !== username)


    render() {
        if (this.state.isLoading)
            return <LoadingIndicator />

        let firstStepValidation = this.submitted ?           // if the form has been submitted at least once
            this.firstStepValidator.validate(this.state) :   // then check validity every time we render
            this.state.firstStepValidation                   // otherwise just use what's in state

        let secondStepValidation = this.secondStepSubmitted ?           // if the form has been submitted at least once
            this.secondStepValidator.validate(this.state) :   // then check validity every time we render
            this.state.secondStepValidation                   // otherwise just use what's in state

        return (
            <div>
                <div className="signup__container">
                    <div className="row row-container">
                        <div className="col-md-4 bootstrap-col-nopadding">
                            <div className="container__child signup__thumbnail">
                                <div className="thumbnail__content text-center">
                                    <h1 className="heading--primary">Welcome to Rapport</h1>
                                    <h2 className="heading--secondary">Reset your password</h2>
                                </div>
                                <div className="signup__overlay"></div>
                            </div>
                        </div>
                        <div className="col-md-8 bootstrap-col-nopadding">
                            <div className="container__child signup__form">
                                <form action="#">
                                    <div>
                                        {this.state.isPasswordReset
                                            ?
                                            <div>
                                                <h2 className="text-muted">Success!</h2>
                                                <hr />
                                                <div class="alert alert-info">
                                                    Congratulations. Your password has been changed successfully.
                                                </div>
                                                <br />
                                                <a href="/account/login" class="btn btn-block btn-outline-primary">Sign in</a>
                                            </div>
                                            :
                                            <div>
                                                {!this.state.isSecondStep
                                                    ?
                                                    <div>
                                                        <div>
                                                            <p className="roboto-dark-gray">Provide Your Username and Email</p>
                                                        </div>
                                                        <div className="form-group">

                                                            <input
                                                                ref={this.usernameRef}
                                                                onChange={this.handleInputChange}
                                                                className="form-control input--form"
                                                                autoComplete="off"
                                                                type="text"
                                                                name="username"
                                                                id="username"
                                                                placeholder="Username"
                                                            />
                                                            <small className="form-text text-danger">
                                                                {firstStepValidation.username.message}
                                                            </small>
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                className="form-control input--form"
                                                                autoComplete="off"
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                placeholder="Email"
                                                            />
                                                            <small className="form-text text-danger">
                                                                {firstStepValidation.email.message}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                                {this.state.isSecondStep
                                                    ?
                                                    <div>
                                                        <div>
                                                            <p className="roboto-dark-gray">Provide New Password And Answer To Security Question</p>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="label--form" htmlFor="password">New Password</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                className="form-control input--form"
                                                                type="password"
                                                                name="password"
                                                                id="password"
                                                                placeholder="Password"
                                                            />
                                                            <small className="form-text text-danger">
                                                                {secondStepValidation.password.message}
                                                            </small>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="label--form" htmlFor="passwordConfirmation">Repeat Password</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                className="form-control input--form"
                                                                type="password"
                                                                name="passwordConfirmation"
                                                                id="passwordConfirmation"
                                                                placeholder="Repeat Password"
                                                            />
                                                            <small className="form-text text-danger">
                                                                {secondStepValidation.passwordConfirmation.message}
                                                            </small>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="label--form" htmlFor="securityQuestionAnswer">{this.state.securityQuestion.question}</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                className="form-control input--form"
                                                                autoComplete="off"
                                                                placeholder={this.state.securityQuestion.question}
                                                                name="securityQuestionAnswer"
                                                            />
                                                            <small className="form-text text-danger">
                                                                {secondStepValidation.securityQuestionAnswer.message}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                                <div className="m-t-lg">
                                                    <ul className="list-inline">
                                                        {this.state.isError ?
                                                            <li>
                                                                <small className="form-text text-danger">
                                                                    {this.state.errorMessage}
                                                                </small>
                                                                <br />
                                                            </li>
                                                            :
                                                            null}
                                                        <li>
                                                            {!this.state.isSecondStep
                                                                ?
                                                                <input
                                                                    className="btn btn--form"
                                                                    type="button"
                                                                    onClick={this.handleNextButton}
                                                                    value="Next"
                                                                />
                                                                :
                                                                <input
                                                                    className="btn btn--form"
                                                                    type="button"
                                                                    onClick={this.handleFormSubmit}
                                                                    value="Reset password"
                                                                />
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        );
    }
}

export const ResetPassword = withRouter(ResetPasswordWithoutRouter);
