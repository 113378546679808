import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import axios from 'axios';

export class OAuthService {

    static accessToken = 'access_token';
    static codeVerifier = 'code_verifier';
    static expiresAt = 'expires_at';
    static pathToRedirect = 'path_to_redirect';

    login() {
        const codeVerifier = generateRandomString(43);
        const codeChallenge = generateCodeChallenge(codeVerifier);
        const state = generateRandomString(12);

        localStorage.setItem(OAuthService.codeVerifier, codeVerifier);
        localStorage.setItem(OAuthService.pathToRedirect, window.location.pathname)

        const url = `${window.location.origin}/oauth/redirect?codeChallenge=${codeChallenge}&state=${state}`;

        window.location.href = url;
    }

    renewToken() {
        // To implement.
    }

    logout() {
        localStorage.removeItem(OAuthService.accessToken);
        localStorage.removeItem(OAuthService.expiresAt);
        localStorage.remove('scopes');
    }

    isAuthenticated() {
        if (this.isDev()) {
            return true;
        }

        const accessToken = localStorage.getItem(OAuthService.accessToken);
        if (!accessToken)
            return false;

        return this.tokenExpired();
    }

    setSession(authResult) {
        return Promise.resolve().then(function () {
            let expiresAt = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());
            localStorage.setItem(OAuthService.accessToken, authResult.access_token);
            localStorage.setItem(OAuthService.expiresAt, expiresAt);
            localStorage.setItem('scopes', JSON.stringify(authResult.scope || ""));
        });
    }

    tokenExists() {
        return localStorage.getItem(OAuthService.accessToken) != null && localStorage.getItem(OAuthService.expiresAt) != null;
    }

    tokenExpired() {
        if (!this.tokenExists()) {
            return true;
        }

        let expiresAt = JSON.parse(localStorage.getItem(OAuthService.expiresAt));
        return new Date().getTime() < expiresAt;
    }

    getAuthorizationHeaderValue() {
        const accessToken = localStorage.getItem(OAuthService.accessToken);
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return `Bearer ${accessToken}`;
    }

    completeAuthentication() {
        const code = getAuthorizationCode();
        const codeVerifier = localStorage.getItem(OAuthService.codeVerifier);

        return axios.post('/oauth/token',
            {
                code,
                codeVerifier
            })
    }

    isDev() {
        if (process.env.NODE_ENV === 'development') {
            if (this.tokenExpired()) {
                localStorage.setItem(OAuthService.accessToken, generateRandomString(35));
                let expiresAt = JSON.stringify((3599 * 1000) + new Date(2040, 12, 1).getTime());
                localStorage.setItem(OAuthService.expiresAt, expiresAt);
            }
            return true;
        }
        return false;
    }
}

function getAuthorizationCode() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('code');
}

function generateRandomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

function generateCodeChallenge(code_verifier) {
    return base64URL(sha256(code_verifier))
}

function base64URL(string) {
    return string.toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}