import { httpClient } from '../../../common/httpClient';

const getAll = () => httpClient({
    url: `api/useraccounts`,
    method: "get",
});

const remove = (id) => httpClient({
    url: `api/useraccounts/${id}`,
    method: "delete",
    headers: {
        "Content-Type": "application/json",
    }
})

const activate = (id) => httpClient({
    url: `api/useraccounts/activate/${id}`,
    method: "patch",
    headers: {
        "Content-Type": "application/json",
    }
})

const suspend = (id) => httpClient({
    url: `api/useraccounts/suspend/${id}`,
    method: "patch",
    headers: {
        "Content-Type": "application/json",
    }
})

export {
    getAll,
    remove,
    activate,
    suspend
};

