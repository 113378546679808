
const tagColors = [
    "#FF6327",
    '#00C37B',
    "#CB2980",
    "#80B8D6"
]

const colors = [
    "#FF6327",
    '#00C37B',
    "#CB2980",
    "#80B8D6",
    "#15596B",
    "#7E39BA"
]

export { colors, tagColors }