import React, { Component } from 'react';

import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";

import { ContactUs } from './contactUs/ContactUs';
import { About } from './about/About'
import { ReportIssue } from './reportIssue/ReportIssue';
import styles from './SideBarComponent.module.css';
import '../../scss/_icons.scss';
import { getCurrentUser } from "../../auth/userManager";
import { Administrator, ROLES } from "../../auth/userRoles";
import { Notifications } from './notifications/Notifications';
import ChangePassword from './changePassword/ChangePassword';
import { connect } from 'react-redux';
import { showNotificationsRequest } from '../../redux/actions/showNotifications';
import { toggleSidebarOptionRequest } from '../../redux/actions/toggleSidebarOption';
import { getVersion } from '../../common/versionService';
import * as authService from '../../auth/customAuthService';
import webAppConfig from '../../config';
import authenticationTypes from '../../common/const/authenticationTypes';
import { getMailConfig } from '../../common/mailConfigService';
import { MyTooltip } from '../../components/MyTooltip';

class SideBarComponent extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            navigation: 'home',
            sideOpened: false,
            sideOptionComponent: null,
            isAdmin: false,
            version: "",
            mailConfig: {}
        };
    }

    async componentDidMount() {
        this.onRouteChanged();
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && this.props.sideBarOptionState === prevProps.sideBarOptionState) {
            this.onRouteChanged();
        }

        if (this.props.sideBarOptionState !== prevProps.sideBarOptionState && this.props.sideBarOptionState === 'close') {
            this.setCurrentNavigation('none');
        }
    }

    async fetchData() {

        try {
            let allResponses = await Promise.all([getCurrentUser(), getVersion(), getMailConfig()]);

            let currentUserResponse = allResponses[0];
            let versionResponse = allResponses[1];
            let mailConfigResponse = allResponses[2];

            const username = currentUserResponse.data.login;
            if (currentUserResponse.data.isSystemAdmin) {
                this.setState({
                    isAdmin: true,
                    version: versionResponse.data,
                    username: username,
                    mailConfig: mailConfigResponse.data
                })
            }
            else {
                let isAdmin = false;
                const engagements = currentUserResponse.data.accessToEngagements;
                Object.entries(engagements).forEach(([key, value]) => {
                    if (value === ROLES[Administrator]) {
                        isAdmin = true;
                    }
                });
                this.setState({
                    isAdmin: isAdmin,
                    version: versionResponse.data,
                    username: username,
                    mailConfig: mailConfigResponse.data
                })
            }
        }
        catch (ex) {
            this.setState({
                isAdmin: false
            })
        }
    }

    onRouteChanged() {
        if (this.isAdminRoute()) {
            this.setCurrentNavigation('admin');
        } else if (this.props.location.pathname === '/') {
            this.setCurrentNavigation('home');
        } else {
            this.setCurrentNavigation('none');
        }
    }

    homeClicked = (e) => {
        this.setCurrentNavigation('home');
        this.props.history.push('/');
    }

    contactUsClicked = (e) => {
        e.stopPropagation();
        this.setCurrentNavigation('contact');
        this.setState({
            sideOptionComponent: <ContactUs username={this.state.username} mailConfig={this.state.mailConfig} onFinish={this.closeSideOption} />
        })
        this.props.toggleSidebarOption('open');
    };

    aboutClicked = (e) => {
        e.stopPropagation();
        this.setCurrentNavigation('about');
        this.setState({
            sideOptionComponent: <About mailConfig={this.state.mailConfig} />
        })
        this.props.toggleSidebarOption('open');

    }

    homeClicked = (e) => {
        this.setCurrentNavigation('home');
        this.props.history.push('/');
        this.setState({
            sideOpened: false
        })
    };

    notificationsClicked = (e) => {
        e.stopPropagation();
        this.setCurrentNavigation('notifications');
        this.setState({
            sideOptionComponent: <Notifications data={this.props.notifications.list} />
        })
        this.props.toggleSidebarOption('open');

        localStorage.setItem('last-visit-date', new Date().toISOString())
        this.props.clearNotSeen(this.props.notifications.list, 0);
    }

    reportAnIssueClicked = (e) => {
        e.stopPropagation();
        this.setCurrentNavigation('report');
        this.props.toggleSidebarOption('open');
        this.setState({
            sideOptionComponent: <ReportIssue username={this.state.username} mailConfig={this.state.mailConfig} onFinish={this.closeSideOption} />
        })
    }

    adminPanelClicked = (e) => {
        this.setCurrentNavigation('admin');
        this.props.history.push('/admin');
        this.props.toggleSidebarOption('close');
    }


    changePasswordClicked = (e) => {
        e.stopPropagation();
        this.setCurrentNavigation('changePassword');
        this.setState({
            sideOptionComponent: <ChangePassword />
        })
        this.props.toggleSidebarOption('open');


    }

    closeSideOption = () => {
        if (!this.isSidebarOptionOpen())
            return;

        this.props.toggleSidebarOption('close');
        this.setState({
            sideOptionComponent: null
        })
        this.onRouteChanged();
    }

    setCurrentNavigation(nav) {
        this.setState({ navigation: nav })
    }

    currentNavigation() {
        return this.state.navigation;
    }

    isAdminRoute() {
        return this.props.location.pathname.indexOf('admin') !== -1
    }

    isSidebarOptionOpen() {
        return this.props.sideBarOptionState !== 'close';
    }

    doNothing = (e) => {
        e.preventDefault();
    }

    renderLogoutButton() {
        if (webAppConfig.authenticationType === authenticationTypes.Custom) {
            return (
                <li onClick={this.logout}>
                    <a onClick={this.doNothing} href="">
                        <i className="icon-logout">
                        </i>
                        <span className={styles['nav-text']}>
                            Logout
                        </span>
                    </a>
                </li>
            );

        };
        return <></>;
    }

    logout = () => {
        authService.logout();
    }

    render() {

        return (
            <nav className={styles["main-menu"]}>
                <div id={styles["sidebar-option-container"]} className={this.isSidebarOptionOpen() ? styles['sidebar-option-open'] : null}>
                    {this.isSidebarOptionOpen() ? this.state.sideOptionComponent : null}
                </div>
                <ul className={styles['logo-container']}>
                    <li onClick={this.homeClicked}>
                        <MyTooltip tooltip='Rapport Application' placement='right'>
                            <svg className={styles['logo']}
                                viewBox="0 0 233.95 233.95"><g id="Group_3" data-name="Group 3"><g id="Group_1" data-name="Group 1"><path id="Path_1" data-name="Path 1" className="cls-1" d="M244.37,124.76a32.87,32.87,0,1,0-18.74-18.21,44.82,44.82,0,0,1-12.72,26.19,45.56,45.56,0,0,1-32.22,13.63,32.84,32.84,0,1,0,22.75,22,44.46,44.46,0,0,1,13-30.15A45.85,45.85,0,0,1,244.37,124.76Z" transform="translate(-55.02 -61.11)" /><circle id="Ellipse_1" data-name="Ellipse 1" className="cls-1" cx="201.04" cy="116.98" r="32.91" /><circle id="Ellipse_2" data-name="Ellipse 2" className="cls-1" cx="32.92" cy="32.92" r="32.91" /><circle id="Ellipse_3" data-name="Ellipse 3" className="cls-1" cx="116.98" cy="32.92" r="32.91" /><circle id="Ellipse_4" data-name="Ellipse 4" className="cls-1" cx="32.92" cy="201.02" r="32.9" /><circle id="Ellipse_5" data-name="Ellipse 5" className="cls-1" cx="32.92" cy="116.98" r="32.91" /><circle id="Ellipse_6" data-name="Ellipse 6" className="cls-1" cx="116.98" cy="201.04" r="32.91" /><circle id="Ellipse_7" data-name="Ellipse 7" className="cls-1" cx="201.04" cy="201.04" r="32.91" /></g></g>
                            </svg>
                        </MyTooltip>
                    </li>
                </ul>
                <ul className={styles['top-list']} onClick={this.closeSideOption}>
                    {this.isAdminRoute() ?
                        <li onClick={this.homeClicked} className={this.currentNavigation() === 'home' ? styles['active'] : null}>
                            <a onClick={this.doNothing} href="">
                                <i className="icon-outline-home-24px">
                                </i>
                                <span className={styles['nav-text']}>
                                    Home
                            </span>
                            </a>
                        </li>
                        : null
                    }
                    <li onClick={this.notificationsClicked} className={this.currentNavigation() === 'notifications' ? styles['active'] : null}>
                        <a href="" onClick={this.doNothing}>
                            <i className="icon-outline-notifications-24px">
                            </i>
                            <span className={styles['nav-text']}>
                                Notifications
                            </span>
                            {this.props.notifications.notSeenCount > 0
                                ?
                                <span className={styles['nav-notifications-count']}>
                                    {this.props.notifications.notSeenCount > 99 ? '99' : this.props.notifications.notSeenCount}
                                </span>
                                :
                                null
                            }
                        </a>
                    </li>
                    <li onClick={this.aboutClicked} className={this.currentNavigation() === 'about' ? styles['active'] : null}>
                        <a onClick={this.doNothing} href="">
                            <i className="icon-outline-perm_identity-24px">
                            </i>
                            <span className={styles["nav-text"]}>
                                About SMaaS
                        </span>
                        </a>
                    </li>
                    <li onClick={this.contactUsClicked} className={this.currentNavigation() === 'contact' ? styles['active'] : null}>
                        <a onClick={this.doNothing} href="">
                            <i className="icon-baseline-mail_outline-24px">
                            </i>
                            <span className={styles['nav-text']}>
                                Contact us
                        </span>
                        </a>
                    </li>
                    <li onClick={this.reportAnIssueClicked} className={this.currentNavigation() === 'report' ? styles['active'] : null}>
                        <a onClick={this.doNothing} href="">
                            <i className="icon-outline-feedback-24px">
                            </i>
                            <span className={styles['nav-text']}>
                                Report an Issue/Improvement
                            </span>
                        </a>
                    </li>
                    {webAppConfig.authenticationType === authenticationTypes.Custom ?
                        <li onClick={this.changePasswordClicked} className={this.currentNavigation() === 'changePassword' ? styles['active'] : null}>
                            <a onClick={this.doNothing} href="">
                                <i className="icon-lock">
                                </i>
                                <span className={styles['nav-text']}>
                                    Change password
                                </span>
                            </a>
                        </li>
                        : null
                    }
                    {this.state.isAdmin ?
                        <li onClick={this.adminPanelClicked} className={this.currentNavigation() === 'admin' ? styles['active'] : null}>
                            <a onClick={this.doNothing} href="">
                                <i className="icon-outline-build-24px">
                                </i>
                                <span className={styles['nav-text']}>
                                    Admin Panel
                            </span>
                            </a>
                        </li>
                        : null
                    }
                </ul>
                <div className={styles['version-container']}>
                    <div>
                        Rapport
                    </div>
                    <div>
                        {this.state.version}
                    </div>
                </div>
                <ul className={styles['logout-container']}>
                    {this.renderLogoutButton()}
                </ul>

            </nav >
        );
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications,
        sideBarOptionState: state.sideBarOptionState
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clearNotSeen: (notification, count) => {
            dispatch(showNotificationsRequest(notification, count))
        },
        toggleSidebarOption: (mode) => {
            dispatch(toggleSidebarOptionRequest(mode))
        }
    }
}

export const SideBar = connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBarComponent));
