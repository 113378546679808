import { httpClient } from '../../../common/httpClient';
const DEFAULT_CONTENT_TYPE = "application/json";

export const getList = () => httpClient({
    url: `api/report`,
    method: "get",
});

export const getById = (id) => httpClient({
    url: `api/report/${id}`,
    method: "get",
});

export const exportReportIssuesToCsv = (filters) => httpClient({
    url: `api/report/export`,
    method: "post",
    data: filters,
    responseType: 'blob',
});

export const exportReportIssuesToExcel = (filters) => httpClient({
    url: `api/report/export`,
    method: "post",
    data: filters,
    // headers: {
    //     'Content-Type': DEFAULT_CONTENT_TYPE,
    //     'Accepts': DEFAULT_CONTENT_TYPE,
    // }
});