import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { getCurrentUser } from "../../../auth/userManager";
import { NoAccess } from '../../../pages/NoAccess';
import { UploadFileComponent } from './uploadFile/UploadFileComponent';

class UploadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accessToComponent: false,
            user: '',
            dataLoaded: false,
        }
    }

    async componentDidMount() {
        let userInfo = await getCurrentUser();

        if (userInfo.data.isSystemAdmin) {
            this.setState({ accessToComponent: true, user: userInfo.data.login, dataLoaded: true, });
        }
        else {
            this.setState({ dataLoaded: true });
        }

    }

    render() {

        if (!this.state.dataLoaded) {
            return <div></div>
        }

        if (!this.state.accessToComponent) {
            return <NoAccess />
        }

        return (
            <Switch>
                <Route path="/admin/upload" render={({ match }) => <UploadFileComponent {...this.props} user={this.state.user} />} />
            </Switch>
        )
    }
}

export { UploadComponent }