import {httpClient} from '../../../common/httpClient';

const getById = (chartId) => httpClient({
    url: `api/charts/${chartId}`,
    method: "get"
});


const getAll = (dashboardId) => httpClient({
    url: `api/charts/${dashboardId}/items`,
    method: "get"
});


const getAllTags = (dashboardId) => httpClient({
    url: `api/tags/${dashboardId}/items`,
    method: "get"
});

const setUseful = (chartId) => httpClient({
    url: `api/charts/${chartId}/useful`,
    method: "post"
});

const setNotUseful = (chartId => httpClient({
    url: `api/charts/${chartId}/not-useful`,
    method: "post"
}));

export {getAll, getAllTags, getById, setUseful, setNotUseful}
