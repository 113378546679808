import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { ChartDetailsComponent } from "./chartDetails/ChartDetailsComponent"
import { Administrator, ROLES } from "../../../auth/userRoles";
import { getById } from "../services/charts";
import { isAdminOfEngagement } from "../../../auth/authorizationService";
import { NoAccess } from '../../../pages/NoAccess';


class AdminChartsComponent extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <Switch>
                <Route path="/admin/charts/:dashboardId/create" render={({ match }) => <ChartDetailsComponent {...this.props}
                    params={{ dashboardId: match.params.dashboardId }}
                    isEditing={false} />}
                />
                <Route path="/admin/charts/edit/:chartId" render={({ match }) => <ChartDetailsComponent {...this.props}
                    params={{ chartId: match.params.chartId }}
                    isEditing={true} />}
                />
            </Switch>
        )
    }
}

export default (AdminChartsComponent)