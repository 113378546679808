import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import ChartUsefulStatisticsTableComponent from './chartUsefulStatisticsTable/ChartUsefulStatisticsTableComponent';

class ChartUsefulStatisticsMasterComponent extends Component {
    render() {
        return (
            <Switch>
                <Route path="/admin/chartUsefulStatistics/table"
                    render={({ match }) => <ChartUsefulStatisticsTableComponent {...this.props} />}
                />
            </Switch>
        )
    }
}

export default (ChartUsefulStatisticsMasterComponent);