import { SELECT_CHART_REQUEST } from '../constants/ActionTypes'

const initState = {
    payload: null
}

const selectChartReducer = (state = initState, action) => {
    switch (action.type) {
        case SELECT_CHART_REQUEST:
            return {...state, payload: action.payload}
        default:
            return state;
    }
}

export default selectChartReducer;