import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import AnalyticsTableComponent from "./analyticsTable/AnalyticsTableComponent";
import AnalyticsDasboardComponent from "./analyticsDashboard/AnalyticsDashboardComponent";
import MonthlyUsageTrendMaster from "./analyticsDashboard/MonthlyUsageTrendMaster";

class AnalyticsMasterComponent extends Component {

    render() {

        return (
            <Switch>
                <Route path="/admin/analytics/table/:drilldownFilters?"
                    render={({ match }) => <AnalyticsTableComponent {...this.props} 
                    params={{ drilldownFilters: match.params.drilldownFilters }}/>}
                />
                <Route path="/admin/analytics/dashboard/daily"
                    render={({ match }) => <AnalyticsDasboardComponent {...this.props} />}
                />
                <Route path="/admin/analytics/dashboard/monthly"
                    render={({ match }) => <MonthlyUsageTrendMaster {...this.props} />}
                />
            </Switch>
        )
    }
}

export default (AnalyticsMasterComponent);