import * as React from 'react';
import { connect } from 'react-redux';
import { EngagementTitle } from '../engagementTitle/EngagementTitle'
import styles from './EngagementsListComponent.module.scss'
import { colors } from '../../../shared/shared';
import { getAll } from '../../../portal/services/engagements';
import * as strHelper from '../../../../common/stringHelper';
import { Redirect } from 'react-router-dom';
import MasterBoardComponent from '../masterBoard/MasterBoardComponent';
import { NoEntries } from '../../../../components/NoEntriesComponent';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { searchEngagementRequest } from '../../../../redux/actions/search';
import { setEngagementIconRequest } from '../../../../redux/actions/setEngagementIcon';

class EngagementsListComponent extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            isLoaded: false,
            data: []
        }
    }

    componentDidMount() {
        this.fetchEngagements();
    }

    async fetchEngagements() {

        try {

            let engagementsFetch = await getAll();
            this.setState({
                isLoaded: true,
                data: engagementsFetch.data
            })
        }
        catch (ex) {

            this.setState({
                isLoaded: true
            })
        }
    }

    componentWillUnmount() {
        this.props.resetSearchEngagementValue();
    }

    navigateToEngagement = (engagementId) => {

        const iconName = this.state.data.find(e => e.id == engagementId).iconName;
        this.props.setEngagementIcon(iconName);
        this.props.history.push(`engagements/${engagementId}`)
    }

    renderEngagements() {

        let data = [...this.state.data];
        if (this.props.searchText != '' && this.props.searchText != undefined) {
            data = data.filter(x => strHelper.containsText(x, this.props.searchText));
        }

        if (data.length === 0) {
            return <NoEntries />
        }

        return data.map((el, index) => {

            let color = colors[index % colors.length];

            return <EngagementTitle
                key={el.id}
                id={el.id}
                logoColor={color}
                title={el.title}
                dashboardsCount={el.dashboardsCount}
                icon={el.iconName}
                onClick={() => this.navigateToEngagement(el.id)}
            />
        })
    }

    render() {

        if (!this.state.isLoaded)
            return <LoadingIndicator />

        if(this.state.data.length == 1)
            return <Redirect to={`/engagements/${this.state.data[0].id}`} />

        if(this.state.data.length <= 4)
            return <MasterBoardComponent 
                        engagements={this.state.data} 
                    />
        return (
            <div id={styles['engagements-container']}>
                <h4>ENGAGEMENTS</h4>
                <br />
                <div id={styles['engagements-list-container']}>
                    {this.renderEngagements()}
                </div>
            </div>)
    }
}

const mapStateToProps = state => {
    return {
        searchText: state.searchEngagement.searchText,        
        iconName: state.engagementIcon
    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetSearchEngagementValue: () => {
            dispatch(searchEngagementRequest(""))
        },
        setEngagementIcon: (iconName) => {
            dispatch(setEngagementIconRequest(iconName))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementsListComponent);