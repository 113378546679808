import React, { Component } from 'react';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select'
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../services/common';
import { getAll as getAllEngagement, copyEngagement } from '../../services/engagements';
import { getAll as getDashboardsForEngagement } from '../../services/dashboards';
import { getChartsForDashboardIds } from '../../services/charts';

class CopyEngagementComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            blocking: false,
            hasAccess: false,
            engagement: {},
            selectedEngagementId: 0,
            engagementsToChoose: [],
            dashboardsToChoose: [],
            chartsToChoose: [],
            selectedDashboardIds: [],
            selectedChartIds: [],
            chartsSelectionValue: [],
            dashboardsSelectionValue: []
        };
    }

    async componentDidMount() {
        try {
            this.setState({ blocking: true })
            let engagementsFetch = await getAllEngagement();
            this.setState({
                engagementsToChoose: engagementsFetch.data,
                blocking: false
            });
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }

    submitCopyEngagement = async () => {
        try {
            this.setState({ blocking: true })

            const newEngagement = await copyEngagement({
                engagementId: this.state.selectedEngagementId,
                dashboardIds: this.state.selectedDashboardIds,
                chartIds: this.state.selectedChartIds
            });

            toast.info(`Engagement copied successfully.`);
            this.props.history.push(`/admin/engagements/${newEngagement.data.id}`);
        }
        catch (error) {
            const message = getErrorMessage(error);
            toast.error(message);
            this.setState({ blocking: false });
        }
    }


    async fetchDashboards(engagementId) {
        const dashboardsToChoose = await getDashboardsForEngagement(engagementId);
        const selectedDashboardIds = dashboardsToChoose.data.map(x => x.id)
        const chartsToChoose = await getChartsForDashboardIds(selectedDashboardIds);
        const dashboardsSelectionValue = this.getDashboardOptions(dashboardsToChoose.data);
        const chartsSelectionValue = this.getChartOptions(chartsToChoose.data);
        const selectedChartIds = chartsSelectionValue.map(x => x.value);

        this.setState({
            selectedEngagementId: engagementId,
            selectedDashboardIds,
            selectedChartIds,
            dashboardsToChoose: dashboardsToChoose.data,
            chartsToChoose: chartsToChoose.data,
            dashboardsSelectionValue,
            chartsSelectionValue
        });
    }

    onEngagementChange = async (e) => {
        this.setState({ dashboardsToChoose: [], chartsToChoose: [] });
        const engagementId = e.target.value;
        await this.fetchDashboards(engagementId);
    }


    onChangeDashboardSelect = async (e) => {
        try {
            const selectedDashboardIds = e.map(x => x.value);
            const chartsToChoose = await getChartsForDashboardIds(selectedDashboardIds);
            const chartsSelectionValue = this.getChartOptions(chartsToChoose.data);
            const selectedChartIds = chartsSelectionValue.map(x => x.value);

            this.setState({
                selectedDashboardIds,
                selectedChartIds,
                chartsToChoose: chartsToChoose.data,
                dashboardsSelectionValue: e,
                chartsSelectionValue
            });
        }
        catch (error) {
            this.setState({ blocking: false });
        }
    }

    onChangeChartsSelect = async (e) => {

        try {
            let selectedChartIds = e.map(x => x.value);
            this.setState({
                selectedChartIds: selectedChartIds,
                chartsSelectionValue: e
            });
        }
        catch (error) {
            this.setState({ blocking: false });
        }
    }

    renderEngagementOptions() {

        return this.state.engagementsToChoose.map((el) => {
            return <option key={el.id} value={el.id}> {el.displayName} </option>
        })
    }

    getDashboardOptions(dashboardsToChoose) {
        const dashboardOptions = [];
        dashboardsToChoose.map((dashboard, index) => {
            dashboardOptions[index] = {
                value: dashboard.id,
                label: dashboard.displayName
            }
        });
        return dashboardOptions;
    }

    getChartOptions(chartsToChoose) {
        const chartOptions = [];
        chartsToChoose.map((chart, index) => {
            chartOptions[index] = {
                value: chart.id,
                label: `${chart.dashboardName} ${chart.displayName}`
            }
        });
        return chartOptions;
    }

    render() {
        const dashboardOptions = this.getDashboardOptions(this.state.dashboardsToChoose);
        const chartOptions = this.getChartOptions(this.state.chartsToChoose);

        return (
            <BlockUIContainer blocking={this.state.blocking}>
                <div>
                    <Form.Label>Choose Engagement to Copy from</Form.Label>
                    <Form.Control as="select"
                        name="engagement"
                        onChange={this.onEngagementChange}
                        value={this.state.selectedEngagementId}
                    >
                        <option value={0}></option>
                        {this.renderEngagementOptions()}
                    </Form.Control>

                    {this.state.selectedEngagementId !== 0 ?
                        <div>
                            <Form.Label>Dashboards to copy</Form.Label>
                            <Select isMulti
                                name="dashboards"
                                onChange={e => {
                                    this.onChangeDashboardSelect(e);
                                }}
                                value={this.state.dashboardsSelectionValue}
                                className="basic-multi-select"
                                options={dashboardOptions} />
                        </div>
                        : null
                    }
                    {this.state.selectedDashboardIds.length > 0 ?
                        <div>
                            <Form.Label>Charts to copy</Form.Label>
                            <Select isMulti
                                name="charts"
                                onChange={e => {
                                    this.onChangeChartsSelect(e);
                                }}
                                value={this.state.chartsSelectionValue}
                                className="basic-multi-select"
                                options={chartOptions} />
                        </div>
                        : null
                    }
                    {
                        this.state.selectedEngagementId !== 0 ?
                            <div>

                                <Button onClick={this.submitCopyEngagement}
                                    variant="outline-success mt-2 float-right">
                                    <FontAwesomeIcon icon={faSave} /> Submit copy
                                </Button>
                                <br />
                            </div>
                            : null
                    }
                </div>
            </BlockUIContainer>
        );
    }
}

export { CopyEngagementComponent };
