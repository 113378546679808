import { SELECT_DASHBOARD_REQUEST } from '../constants/ActionTypes';

const initState = {
    payload: null
}

const selectDashboardReducer = (state = initState, action) => {
    switch(action.type) {
        case SELECT_DASHBOARD_REQUEST:
            return {...state, payload: action.payload}
        default:
            return state;
    }
}

export default selectDashboardReducer;