import React, {Component} from 'react';
import IFrame from 'react-iframe';
import PropTypes from 'prop-types';
import styles from './IFrameComponent.module.css';


class IFrameComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            frameStyle: []
        }
    }


    render() {

        return (
            <div className={styles["wrap-" + this.props.size]}>
                <IFrame className={styles["frame-" + this.props.size]}
                        url={this.props.url}
                        id={this.props.id}
                        onLoad={this.props.onLoad}
                />
            </div>
        );
    }
}

IFrameComponent.propTypes = {
    url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    onLoad: PropTypes.func
};
export {IFrameComponent}