import { httpClient } from '../../../common/httpClient';
import { BrowserDetector } from '../../../common/browserDetector';

const browserDetector = new BrowserDetector();

const sendReportedMessage = (data) => httpClient({
    url: `api/report/contactUs`,
    method: "post",
    data: {
        message: data,
        webBrowserType: browserDetector.getBrowserType()
    },
    headers: {
        "Content-Type": "application/json"
    }
});

export { sendReportedMessage };