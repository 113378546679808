import { HubConnectionBuilder } from '@microsoft/signalr';

import { toast } from 'react-toastify';

import { getToken } from '../../auth/customAuthService';
import webAppConfig from '../../config';
import authenticationTypes from '../../common/const/authenticationTypes';

import { showNotificationsRequest } from '../actions/showNotifications';
import { USER_SIGNED_IN } from '../constants/ActionTypes';
import { getAll } from '../../common/notificationService';

const onNotificationReceived = (message, store) => {
    const state = store.getState();

    let notSeenCount = state.notifications.notSeenCount;
    let newArray = Object.assign([], state.notifications.list);
    newArray.unshift(message);

    store.dispatch(showNotificationsRequest(newArray, ++notSeenCount));

    toast.info('You have new notification(s)');
};

const fetchNotificationsFromAPI = async () => {
    try {

        let lastVisitDate = localStorage['last-visit-date'];
        let sinceDate = lastVisitDate == null
            ? new Date().setMonth(new Date().getMonth() - 1)
            : new Date(lastVisitDate);

        let notificationsFetch = await getAll(sinceDate);

        return notificationsFetch;
    }
    catch (ex) {
        return {
            data: []
        }
    }
}

const startSignalRConnection = connection => connection.start()
    .then(() => console.info('SignalR Connected'))
    .catch(err => {
        console.log('SignalR Connection Error: ');
        console.log(JSON.stringify(err));

        if (webAppConfig.authenticationType !== authenticationTypes.CapgeminiSso) {
            return;
        }

        if (err !== undefined && err.statusCode === 403) {
            toast.info("Session expired. Reloading...")

            const isReloadingString = 'is_reloading';
            let isReloadingValue = sessionStorage.getItem(isReloadingString);
            if (isReloadingValue !== undefined && isReloadingValue !== null) {
                return;
            }
            else {
                setInterval(() => {
                    sessionStorage.setItem(isReloadingString, 'true');
                    window.location.reload(true);
                }, 1500);
            }
        } else {
            console.log('The error condition is not met..')
        }
    });

const signalRMiddleware = store => next => async (action) => {

    if (action.type === USER_SIGNED_IN) {

        let connectionHub = '/notificationHub';

        const token = getToken();
        connectionHub += `?token=${token}`;

        const connection = new HubConnectionBuilder()
            .withUrl(connectionHub)
            .build();

        connection.on('SendNotification', message => {
            onNotificationReceived(message, store);
        });

        connection.onclose(() => setTimeout(startSignalRConnection(connection), 5000));

        let notificationsFetch = await fetchNotificationsFromAPI();
        store.dispatch(showNotificationsRequest(notificationsFetch.data, notificationsFetch.data.length));
        localStorage.setItem('last-visit-date', new Date().toISOString())

        startSignalRConnection(connection);
    }

    return next(action);
};

export default signalRMiddleware;