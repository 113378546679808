
const ReadonlyUser = "ReadonlyUser";
const Administrator = "Administrator";

const ROLES = {
    Administrator: 1,
    ReadonlyUser: 2
}

const getRoleById = (id) => {
    let roleName = '';
    Object.entries(ROLES).forEach(([key, value]) => {
        if (value == id) {
            roleName = key
        }
    });
    if (roleName === '') throw `Role with id ${id} does not exists`;

    return roleName;
}

export {
    ReadonlyUser,
    Administrator,
    ROLES,
    getRoleById
}