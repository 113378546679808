import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Register } from './Register/Register';
import { ResetPassword } from './ResetPassword/ResetPassword'
import Login from './Login/Login';

import './AccountComponent.scss';

class AccountComponent extends React.Component {

    componentWillMount() {
        if (this.props.location.pathname === '/account') {
            this.props.history.push('/account/login');
        }
    }

    render() {

        return (
            <Switch>
                <Route path="/account/resetPassword" render={(props) => <ResetPassword {...props} />} />
                <Route path='/account/register' render={(props) => <Register {...props} />} />
                <Route path='/account/login' render={(props) => <Login {...props} />} />
            </Switch>
        )
    }
}

export default AccountComponent;
