import React, { useState } from 'react';

const SubmitButton = ({ handleClick, title = 'Send it now' }) => {
    const [hover, setHover] = useState(false)

    const toggleHover = () => setHover(!hover);

    let style = baseStyle;
    if (hover) {
        style = { ...baseStyle, ...{ backgroundColor: '#80B8D6', cursor: 'pointer' } }
    }
    else {
        style = { ...baseStyle, ...{ backgroundColor: '#0070AD' } }
    }

    return (
        <button onClick={handleClick} onMouseEnter={toggleHover} onMouseLeave={toggleHover} style={style}>
            <span style={{ color: 'white' }}></span> {title}
        </button>
    );
}

const baseStyle = {
    width: '40%',
    border: 'none',
    float: 'right',
    marginRight: '20px',
    borderRadius: '0.2rem',
    color: 'white',
    fontFamily: 'Roboto-Regular',
    fontSize: '16px',
    padding: '5px'
}

export default SubmitButton 