import { applyMiddleware, createStore, combineReducers } from 'redux';
import signalRMiddleware from './middlewares/signalRMiddleware';

import { searchEngagementReducer, searchChartReducer, searchDashboardReducer } from './reducers/searchReducers';
import selectedDashboard from './reducers/selectDashboard';
import selectedPreviewMode from './reducers/selectPreviewMode';
import selectedLayoutMode from './reducers/selectLayoutMode';
import showNotifications from './reducers/showNotifications';
import switchSlideshowMode from './reducers/switchSlideshowMode';
import toggleSidebarOptionReducer from './reducers/toggleSidebarOptionReducer';
import authenticateUserReducer from './reducers/authReducer';
import selectedCharReducer from './reducers/selectChart';
import breadcrumbsReducer from './reducers/breadcrumbs';
import selectedDashboardTagsReducer from './reducers/selectDashboardTagsReducer';
import selectedToggleMode from './reducers/selectToggleMode';
import setEngagementIconReducer from './reducers/setEngagementIcon';

export const rootReducer = combineReducers({
    searchEngagement: searchEngagementReducer,
    searchDashboard: searchDashboardReducer,
    searchChart: searchChartReducer,
    selectedDashboard: selectedDashboard,
    selectedChart: selectedCharReducer,
    selectedPreviewMode: selectedPreviewMode,
    selectedLayoutMode: selectedLayoutMode,
    notifications: showNotifications,
    switchSlideshowMode: switchSlideshowMode,
    sideBarOptionState: toggleSidebarOptionReducer,
    isUserAuthenticated: authenticateUserReducer,
    selectedDashboardTags: selectedDashboardTagsReducer,
    breadcrumbs: breadcrumbsReducer,
    selectedToggleMode: selectedToggleMode,
    engagementIcon: setEngagementIconReducer
})

export const store = createStore(
    rootReducer,
    applyMiddleware(signalRMiddleware)
)