import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import * as customAuthService from './customAuthService';
import { OAuthService } from './OAuthService';
import { CAMAuthService } from './CAMAuthService';
import webAppConfig from '../config'
import authenticationTypes from '../common/const/authenticationTypes'
import { toast } from 'react-toastify';

const capgeminiSsoClient = axios.create({
    withCredentials: true
});


capgeminiSsoClient.interceptors.response.use((response) => {
    return response;
}, (err) => {
    // SSO Session Reloading. Disabled for now as SignalR Middleware handles it.
    // if (err.response === undefined) {
    //     setInterval(() => {
    //         window.location.reload(false);
    //     }, 2500);
    // }
    return Promise.reject(err);
});

const capgepminiOAuthClient = axios.create({
    withCredentials: true
});

capgepminiOAuthClient.interceptors.request.use((config) => {
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    const authSrv = new OAuthService();
    if (authSrv.isAuthenticated()) {
        config.headers.common.Authorization = `${authSrv.getAuthorizationHeaderValue()}`;
    }

    return config;
}, (err) => {
    return Promise.reject(err.response);
});


const CAMAuthClient = axios.create({
    withCredentials: true
});

CAMAuthClient.interceptors.request.use((config) => {
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    const authSrv = new CAMAuthService();
    if (authSrv.isAuthenticated()) {
        config.headers.common.Authorization = `${authSrv.getAuthorizationHeaderValue()}`;
    }

    return config;
}, (err) => {
    console.log(err.response);
    return Promise.reject(err.response);
});

const customAuthClient = axios.create({
    withCredentials: true
});

customAuthClient.interceptors.request.use((config) => {
    if (customAuthService.tokenExists()) {
        config.headers.common.Authorization = customAuthService.getAccessToken();
    }
    return config;
}, (err) => {
    console.log(err);
    return Promise.reject(err.response);
});

const refreshAuthLogic = failedRequest => {
    if (!customAuthService.tokenExists()) {
        if (window.location.href.indexOf('login') === -1) {
            window.location.replace('/account/login');
        }
        return Promise.reject();
    }

    return customAuthService.refreshToken().then(tokenRefreshResponse => {

        customAuthService.saveAccessToken(tokenRefreshResponse);
        failedRequest.response.config.headers['Authorization'] = customAuthService.getAccessToken();

        return Promise.resolve();
    }).catch(e => {
        customAuthService.logout();
    });
}

const tokenExpiredLogic = failedRequest => {
    const authSrv = new OAuthService();

    if (!authSrv.isAuthenticated()) {
        authSrv.login();
        return Promise.reject();
    }
}

const CAMtokenExpiredLogic = failedRequest => {
    const authSrv = new CAMAuthService();
    if (!authSrv.isAuthenticated()) {
        authSrv.login();
        return Promise.reject();
    }
}

createAuthRefreshInterceptor(customAuthClient, refreshAuthLogic);
createAuthRefreshInterceptor(capgepminiOAuthClient, tokenExpiredLogic);
createAuthRefreshInterceptor(CAMAuthClient, CAMtokenExpiredLogic);

let client = capgeminiSsoClient;
client = webAppConfig.authenticationType === authenticationTypes.Custom
    ? customAuthClient
    : client;
client = (webAppConfig.authenticationType === authenticationTypes.CapgeminiOAuth)
    ? capgepminiOAuthClient
    : client;
client = ( webAppConfig.authenticationType === authenticationTypes.CAMAuth)
    ? CAMAuthClient
    : client;

export default client;