import { SWITCH_SLIDESHOW_MODE } from '../constants/ActionTypes';

const initState = false;

const switchSlideshowModeReducer = (state = initState, action) => {

    switch(action.type){
        case SWITCH_SLIDESHOW_MODE:
            return action.isEnabled
        default:
            return state;
    }
}

export default switchSlideshowModeReducer;