import React from 'react'
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BlockUIContainer } from '../../../components/BlockUIContainer';
import { uploadUsersToEngagement } from '../services/engagementUsers';
import { uploadUsersToDashboard } from '../services/dashboardUsers';

class UploadUsersFromCsvComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            blocking: false,
            shouldRefreshList: true,
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.uploadRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.chooseFile) {
            this.uploadRef.current.click();
        }
    }

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    onFormSubmit(e) {
        this.toggleBlocking();

        const file = this.state.file;
        let uploadFunction = null;
        const formData = new FormData();
        formData.append('file', file);
        if (this.props.engagementId !== undefined) {
            formData.append('engagementId', this.props.engagementId);
            uploadFunction = uploadUsersToEngagement;
        }
        if (this.props.dashboardId !== undefined) {
            formData.append('dashboardId', this.props.dashboardId);
            uploadFunction = uploadUsersToDashboard;
        }

        uploadFunction(formData).then((response) => {
            toast.info("File uploaded successfully");
            this.props.uploadDone();
            this.setState({ shouldRefreshList: true, blocking: false, file: null });
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error[0]);
            }
            else {
                toast.error('File cannot be uploaded. Invalid data.');
            }
            this.props.uploadFailed();
            this.setState({ blocking: false, file: null, shouldRefreshList: true});
        });
    }

    onFileChange(e) {
        this.setState({ file: e.target.files[0] })
        this.props.fileSelected();
    }

    render() {
        return (
            <span>
                <input style={{ paddingTop: 2, display: 'inline', width: 250, cursor: 'pointer' }}
                    hidden={this.state.file == null ? true : false} ref={this.uploadRef}
                    className="form-control-file btn btn-outline-secondary btn-sm mr-2"
                    type="file" accept=".csv"
                    onChange={this.onFileChange}
                />
                {this.state.file != null ?
                    <Button
                        onClick={this.onFormSubmit}
                        style={{ paddingTop: 5 }}
                        type="submit" variant="primary"
                        size="sm"
                    >
                        Upload
                    </Button>
                    : null
                }
            </span>
        )
    }
}

export { UploadUsersFromCsvComponent }