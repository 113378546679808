import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { login, saveAccessToken, saveRefreshToken } from '../../../auth/customAuthService';
import { USERNAME, PASSWORD } from "../../../common/const/defaultUser";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { authenticateUserRequest } from '../../../redux/actions/auth';
import webAppConfig from '../../../config';
import authenticationTypes from '../../../common/const/authenticationTypes';
import { tokenExpired } from '../../../auth/customAuthService';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

class LoginPage extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
            rememberMe: false,
            isLoggedIn: false,
            isError: false,
            isLoading: false,
            errorMessage: false,
            isPasswordVisible: false,
            passwordInputType: 'password'
        };
        this.usernameRef = React.createRef();
    }

    componentWillMount() {
        if (webAppConfig.authenticationType === authenticationTypes.Custom && !tokenExpired()) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.usernameRef.current != null)
                this.usernameRef.current.focus()
        }, 500)
    }

    autoLogin() {
        login(USERNAME, PASSWORD)
            .then((result) => {
                saveAccessToken(result);
                saveRefreshToken(result);
                this.props.history.push('/');
            })
            .catch((err) => {
                this.setState({ isLoggedIn: false, isError: true });
            });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        console.log(this.state.rememberMe);
        login(this.state.user, this.state.password, this.state.rememberMe)
            .then((result) => {
                this.setState({ isLoading: false });
                saveAccessToken(result);
                saveRefreshToken(result);
                this.props.markAsAuthenticated();
                this.props.history.push('/');
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isLoggedIn: false,
                    isError: true,
                    isLoading: false,
                    errorMessage: err.response.data.error_description
                });
            });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    eyeIconClicked = event => {
        this.setState(prevState => ({
            isPasswordVisible: !prevState.isPasswordVisible,
            passwordInputType: !prevState.isPasswordVisible ? 'text' : 'password'
        }));
    }

    render() {
        if (this.state.isLoading)
            return <LoadingIndicator />

        let eyeIconStyle = { width: '25px', height: '25px', cursor: 'pointer' }
        return (
            <div>
                <div style={{ margin: '0 auto', width: '500px', minHeight: '200px' }}>
                    <form action="#" onSubmit={this.handleSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <div style={{ width: '70%' }}>
                            <p style={{ textAlign: 'center', paddingBottom: '5%' }}>
                                <svg style={{ width: '70px', fill: '#0070AD' }}
                                    viewBox="0 0 233.95 233.95"><g id="Group_3" data-name="Group 3"><g id="Group_1" data-name="Group 1"><path id="Path_1" data-name="Path 1" className="cls-1" d="M244.37,124.76a32.87,32.87,0,1,0-18.74-18.21,44.82,44.82,0,0,1-12.72,26.19,45.56,45.56,0,0,1-32.22,13.63,32.84,32.84,0,1,0,22.75,22,44.46,44.46,0,0,1,13-30.15A45.85,45.85,0,0,1,244.37,124.76Z" transform="translate(-55.02 -61.11)" /><circle id="Ellipse_1" data-name="Ellipse 1" className="cls-1" cx="201.04" cy="116.98" r="32.91" /><circle id="Ellipse_2" data-name="Ellipse 2" className="cls-1" cx="32.92" cy="32.92" r="32.91" /><circle id="Ellipse_3" data-name="Ellipse 3" className="cls-1" cx="116.98" cy="32.92" r="32.91" /><circle id="Ellipse_4" data-name="Ellipse 4" className="cls-1" cx="32.92" cy="201.02" r="32.9" /><circle id="Ellipse_5" data-name="Ellipse 5" className="cls-1" cx="32.92" cy="116.98" r="32.91" /><circle id="Ellipse_6" data-name="Ellipse 6" className="cls-1" cx="116.98" cy="201.04" r="32.91" /><circle id="Ellipse_7" data-name="Ellipse 7" className="cls-1" cx="201.04" cy="201.04" r="32.91" /></g></g>
                                </svg>
                                <p style={{ fontSize: '26px', marginBottom: 0, paddingTop: 10, fontFamily: 'Comic Sans MS'}}>
                                    Rapport
                                </p>
                            </p>
                            <input
                                style={{ width: '92%' }}
                                ref={this.usernameRef}
                                type="text"
                                id="defaultFormLoginEmailEx"
                                className="form-control input--form login-input--form"
                                /* autoComplete="off" */
                                placeholder="Username"
                                name="user"
                                value={this.state.user}
                                onChange={this.handleInputChange}
                            />
                            <div className="field-border"></div>
                            <br />
                            <div style={{ display: 'inline' }}>
                                <input
                                    style={{ width: '92%' }}
                                    type={this.state.passwordInputType}
                                    id="defaultFormLoginPasswordEx"
                                    className="input--form form-control-no-block form-control login-input--form"
                                    placeholder="Password"
                                    name="password"
                                    autoComplete="off"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                />
                                <span>
                                    {this.state.isPasswordVisible ?
                                        <svg onClick={this.eyeIconClicked} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" style={eyeIconStyle}><path fillRule="evenodd" clipRule="evenodd" d="M2 9C2 9 4 5 9 5C14 5 16 9 16 9C16 9 14 13 9 13C4 13 2 9 2 9ZM3 9C3 9 4.71429 6 9 6C13.2857 6 15 9 15 9C15 9 13.2857 12 9 12C4.71429 12 3 9 3 9ZM9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9C12 10.6569 10.6569 12 9 12ZM9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z" fill="#0070AD"></path></svg>
                                        : null}
                                    {!this.state.isPasswordVisible ?
                                        <svg onClick={this.eyeIconClicked} preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" style={eyeIconStyle}><path d="M15.7716 2.87887L14.9071 2L2 15.1211L2.86453 16L15.7716 2.87887ZM9 13C8.41803 13 7.8767 12.9458 7.37443 12.85L8.25562 11.9689C8.4946 11.9892 8.74267 12 9 12C13.2857 12 15 9 15 9C15 9 14.4195 7.98419 13.0897 7.13476L13.7366 6.48783C15.3127 7.6254 16 9 16 9C16 9 14 13 9 13ZM9 12C8.76087 12 8.52828 11.972 8.30531 11.9192L9.23855 10.9859C10.1524 10.8773 10.8773 10.1524 10.9859 9.23855L11.9192 8.30531C11.972 8.52828 12 8.76087 12 9C12 10.6569 10.6569 12 9 12ZM7.02672 8.67227L6.06674 9.63225C6.02302 9.42841 6 9.2169 6 9C6 7.34315 7.34315 6 9 6C9.2169 6 9.42841 6.02302 9.63225 6.06674L8.67227 7.02672C7.83025 7.16552 7.16552 7.83025 7.02672 8.67227ZM9.67357 6.02542C9.45649 6.00879 9.23201 6 9 6C4.71429 6 3 9 3 9C3 9 3.56691 9.9921 4.86374 10.8352L4.21904 11.48C2.67441 10.3488 2 9 2 9C2 9 4 5 9 5C9.55714 5 10.077 5.04967 10.5611 5.13793L9.67357 6.02542Z" fill="#70787D"></path></svg>
                                        : null}
                                </span>
                            </div>
                            <div className="field-border"></div>
                            {this.state.isError ?
                                <div className="form-text text-danger">
                                    <small>
                                        {this.state.errorMessage}
                                    </small>
                                </div>
                                : null}
                            <div className="form-check checkbox-login-container">
                                <input
                                    type="checkbox"
                                    className="form-check-input checkbox-login-input"
                                    name="rememberMe"
                                    id="rememberMe"
                                    checked={this.state.rememberMe}
                                    onChange={this.handleInputChange}
                                />
                                <label
                                    className="checkbox-login-label noselect"
                                    htmlFor="rememberMe">
                                    Remember me
                                </label>
                            </div>
                            <div className="text-center mt-3">
                                <input disabled={this.state.user === '' || this.state.password === ''} style={{ width: '75%' }} className="btn btn--form" type="submit" value="Login" />
                            </div>
                            <div style={{ paddingTop: 20, textAlign: 'center' }}>
                                <span>
                                    <a style={{ color: '#0070AD', fontWeight: '400', textDecoration: 'none', paddingRight: 15 }} href="/account/resetPassword">Forgot password?</a>
                                </span>
                                <span> | </span>
                                <span>
                                    <a style={{ color: '#0070AD', fontWeight: '400', textDecoration: 'none', paddingLeft: 15 }} href="/account/register">Sign up</a>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        markAsAuthenticated: () => {
            dispatch(authenticateUserRequest(true))
        }
    }
}

export default withRouter(connect(null, mapDispatchToProps)(LoginPage))
