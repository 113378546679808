import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap'

class DeleteModal extends Component {

    confirmDelete  = () => {

        this.props.onconfirmdelete();

    }

    render() {
        const  {onconfirmdelete,...props}=this.props;

        let category = this.props.deleting.category || null;
        
        if(category !== null)
           category = category.charAt(0).toUpperCase() + category.slice(1);

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Deleting "{this.props.deleting.title}"...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>
                        Are You sure You want to delete {category} called "{this.props.deleting.title}"?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>Cancel</Button>
                    <Button variant="danger" onClick={()=>this.confirmDelete()}>Delete</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export {DeleteModal};