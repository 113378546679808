import * as types from '../constants/ActionTypes';

export const showNotificationsRequest = (list, notSeenCount) => ({

    type: types.SHOW_NOTIFICATIONS_REQUEST,
    payload: {
        list: list,
        notSeenCount: notSeenCount
    }

})