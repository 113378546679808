import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { EngagementsComponent } from './engagements/EngagementsComponent';
import DashboardsListComponent from './dashboards/dashboardsList/DashboardsListComponent';
import { ChartsList } from './charts/chartsList/ChartsListComponent';
import ChartDetailsComponent from './charts/chartDetails/ChartDetailsComponent';
import EngagementsListComponent from './engagements/engagementsList/EngagementsListComponent';
import withRestrictedAccess from '../../components/withRestrictedAccess'
import { ReadonlyUser, ROLES, Administrator } from "../../auth/userRoles";

class PortalComponent extends React.Component {

    constructor(props) {

        super(props);
    }

    render() {

        return (
            <Switch>
                <Route path="/engagements/:engagementId/dashboards/:dashboardId/charts/:chartId" render={(props) => <ChartDetailsComponent {...props} />} />
                <Route path="/engagements/:engagementId/dashboards/:dashboardId" render={(props) => <ChartsList {...props} />} />
                <Route path='/engagements/:engagementId' render={(props) => <DashboardsListComponent {...props} />} />
                <Route exact path='/engagements' render={(props) => <EngagementsListComponent  {...props} />} />
            </Switch>

        )
    }
}

export const Portal = withRestrictedAccess(ROLES[ReadonlyUser], ROLES[Administrator])(PortalComponent)

