import * as React from 'react'

import styles from './ChartTile.module.scss'
import PropTypes from 'prop-types';

class ChartTile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            title: this.props.title,
            firstTagColor: this.props.firstTagColor,
            secondTagColor: this.props.secondTagColor,
            isPreview: this.props.isPreview || false,
            isSelected: this.props.isSelected || false
        }
    }

    render() {

        let title = this.state.title;

        let additionalStyle = this.props.isSelected
            ? {
                borderColor: this.props.firstTagColor,
                borderWidth: '1px',
                borderStyle: 'solid'
            }
            : {}

        return (
            <div className={`${styles['chart-tile-container']} ${this.state.isPreview ? styles['chart-tile-preview-container'] : null}`} onClick={this.props.onClick}>
                <div className={styles['chart-tile-first-tag-rect']} style={{ backgroundColor: this.props.firstTagColor }}></div>
                <div className={styles['chart-tile-second-tag-rect']} style={{ backgroundColor: this.props.secondTagColor }}></div>
                <div className={styles['chart-tile-content']} style={additionalStyle}>
                    <div className={styles['chart-tile-title']}>
                        {title}
                    </div>
                    <div className={styles['chart-tile-icon']}>
                        <img src={`/api/staticresources/icons/${this.props.icon}`} />
                        {this.props.isPreview ?
                            <i className={`${styles['chart-tile-fullview-icon']} icon-outline-remove_red_eye-24px`}></i>
                            : null}
                        <p className={styles['chart-tile-text']} onClick={(e) => { e.stopPropagation(); this.props.fullViewOnClick() }}>
                            Full view
                        </p>
                    </div>
                </div>
            </div>)
    }
}

ChartTile.propTypes = {
    onClick: PropTypes.func.isRequired,
    fullViewOnClick: PropTypes.func,
    firstTagColor: PropTypes.string.isRequired,
    secondTagColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    isPreview: PropTypes.bool,
    isSelected: PropTypes.bool
}

export { ChartTile }