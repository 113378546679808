import React, { Component } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';

class MyTooltip extends Component {


    render() {
        return (
            <OverlayTrigger placement={this.props.placement === undefined ? 'top' : this.props.placement}
                overlay={
                    <Tooltip>
                        {this.props.tooltip}
                    </Tooltip>
                }>
                <span style={{ paddingLeft: this.props.paddingLeft != null ? this.props.paddingLeft : 5 }}>
                    {this.props.children}
                </span>
            </OverlayTrigger>
        );
    }
}

MyTooltip.propTypes = {    
    tooltip: PropTypes.string.isRequired,
}

export { MyTooltip };