import * as React from 'react'
import * as lottie from 'lottie-web';
import styles from './LoadingIndicator.module.scss';
import { animationData } from '../assets/animations/loading_data';

class LoadingIndicator extends React.Component {

    componentDidMount() {

        let container = document.getElementById(styles['loading-indicator-wrapper']);
        lottie.loadAnimation({
            container: container,
            renderer: 'canvas',
            loop: true,
            autoplay: true,
            animationData: animationData
        })
    }

    render() {

        return (
            <div className={`${this.props.hidden ? styles['loading-indicator-hidden'] : styles['loading-indicator-visible']}`}
                id={styles['loading-indicator-wrapper']}>
            </div>
        );
    }
}

export { LoadingIndicator }