import { httpClient } from '../../../common/httpClient';

const DEFAULT_CONTENT_TYPE = "application/json";
const getUsefulStatisticsForExport = (viewModel) => httpClient({
    url: `api/chartusefulstatistics/export`,
    method: "post",
    data: viewModel,
    headers: {
        'Content-Type': DEFAULT_CONTENT_TYPE,
        'Accepts': DEFAULT_CONTENT_TYPE,
    }
});

export {
    getUsefulStatisticsForExport
}