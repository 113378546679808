import * as React from 'react'
import { sendReportedMessage } from '../../../modules/portal/services/contactUs';
import { toast } from 'react-toastify';
import SubmitButton from '../common/SubmitButton';
import CancelButton from '../common/CancelButton';
import PropTypes from 'prop-types'
import webAppConfig from '../../../config';
import { connect } from 'react-redux';
import { toggleSidebarOptionRequest } from '../../../redux/actions/toggleSidebarOption';
import authenticationTypes from '../../../common/const/authenticationTypes';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { getCurrentUser } from "../../../auth/userManager";
import { ROLES, ReadonlyUser } from '../../../auth/userRoles';
import styles from '../SideBarComponent.module.css';

class ContactUsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isBlocked: false,
            textareaMessage: 
            `Name: 
Email ID: 
Engagement Name: 
Message: `,
            userInfo: '',
            isAuthorized: false
        }
        let data = {};
    }


    async componentDidMount() {        
        this.fetchData();
    }

    // async fetchData(){
    //     this.setState({
    //         userInfo: await getCurrentUser()
    //     }, () => this.data = this.state.userInfo.data.accessToDashboards)
    //     console.log("component did mount user: ", this.data.length);        
    // }

    async fetchData() {

        try {
            let allResponses = await getCurrentUser();

            let currentUserResponse = allResponses;

            const username = allResponses.data.login;
            if (currentUserResponse.data.isSystemAdmin) {
                this.setState({
                    isAuthorized: true,
                    username: username
                })
            }
            else {
                let isAuthorized = false;
                const engagements = currentUserResponse.data.accessToEngagements;
                const dashboards = currentUserResponse.data.accessToDashboards;
                Object.entries(engagements).forEach(([key, value]) => {
                    if (value === ROLES[ReadonlyUser]) {
                        isAuthorized = true;
                    }
                });
                Object.entries(dashboards).forEach(([key, value]) => {
                    if (value === ROLES[ReadonlyUser]) {
                        isAuthorized = true;
                    }
                });
                this.setState({
                    isAuthorized: isAuthorized,
                    username: username
                })
            }
        }
        catch (ex) {
            this.setState({
                isAuthorized: false
            })
        }
    }

    handleTextareaChange = (e) => {
        const value = e.target.value
        this.setState({
            textareaMessage: value
        })
    }

    sendMessage = async () => {

        if (this.state.isBlocked)
            return;

        this.setState({ isBlocked: true })

        try {

            await sendReportedMessage(this.state.textareaMessage)
            toast.info("Message sent");
            this.props.onFinish()
        }
        catch (error) {

            if (error && error.data && error.data.error) {
                toast.error(error.data.error);
            }
        }
        finally {
            this.setState({ isBlocked: false })
        }
    }

    closeSideBar = async () => {
        this.props.toggleSidebarOption('close');
    }

    renderManualEmailSending() {
        const href = `mailto:${this.props.mailConfig.contactUsMailbox}?subject=Mail from user: ${this.props.username}`

        return (
            <div>
                <h5>
                    Do you want to contact us?
                </h5>
                <p>Please reachout to:</p>
                <a href={href}>{this.props.mailConfig.contactUsMailbox}</a>
            </div>
        );
    }

    renderRestrictedTextArea() {
        return (
            <div>
                <textarea id={styles["restricted"]}
                    placeholder=' Name:
                        Email ID:
                        Engagement Name:
                        Message:'
                    value={this.state.textareaMessage}
                    onChange={(e) => this.handleTextareaChange(e)}
                />
            </div>                
        );
    }

    renderTextArea() {
        return (
            <div>                
                <textarea id={styles["restricted"]}
                    placeholder=' Name:
                    Email ID:
                    Engagement Name:
                    Message:'
                    value={this.state.textareaMessage}
                    onChange={(e) => this.handleTextareaChange(e)}
                />
            </div>                
        );
    }

    render() {
        if (webAppConfig.authenticationType === authenticationTypes.Custom)
            return this.renderManualEmailSending();

        return (
            <div>
                <h5>
                    Do you want to contact us?
                </h5>
                {
                    this.state.isAuthorized
                    ? this.renderTextArea()
                    : this.renderRestrictedTextArea()
                }                            
                {
                    this.state.isBlocked
                        ? <LoadingIndicator />
                        : <div style={{ marginTop: '30px' }}>
                            <div style={{ display: 'inline' }}>
                                <CancelButton handleClick={this.closeSideBar} />
                                <SubmitButton handleClick={this.sendMessage} />
                            </div>
                        </div>
                }
            </div >
        )
    }
}

ContactUsComponent.propTypes = {
    onFinish: PropTypes.func,
    mailConfig: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        sideBarOptionState: state.sideBarOptionState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSidebarOption: (mode) => {
            dispatch(toggleSidebarOptionRequest(mode))
        }
    }
}

export const ContactUs = connect(mapStateToProps, mapDispatchToProps)(ContactUsComponent);
