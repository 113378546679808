import React, { useState } from 'react';

const CancelButton = ({ handleClick, title = 'Cancel', isCentered = false }) => {
    const [hover, setHover] = useState(false)

    const toggleHover = () => setHover(!hover);

    let mainStyle = baseStyle;
    let centered = centeredStyle
    if (hover) {
        mainStyle = { ...baseStyle, ...{ backgroundColor: '#0070AD', cursor: 'pointer' } };
        centered = { ...centeredStyle, ...{ backgroundColor: '#80B8D6', cursor: 'pointer' } };
    }
    else {
        mainStyle = { ...baseStyle, ...{ backgroundColor: '#80B8D6' } };
        centered = { ...centeredStyle, ...{ backgroundColor: '#0070AD' } };
    }

    mainStyle = isCentered ? centered : mainStyle

    return (
        <button onClick={handleClick} onMouseEnter={toggleHover} onMouseLeave={toggleHover} style={mainStyle}>
            <span style={{ color: 'white' }}></span> {title}
        </button>
    );
}

const baseStyle = {
    width: '40%',
    marginLeft: '20px',
    border: 'none',
    borderRadius: '0.2rem',
    color: 'white',
    fontFamily: 'Roboto-Regular',
    fontSize: '16px',
    padding: '5px'
}

const centeredStyle = {
    backgroundColor: '#0070AD',
    border: 'none',
    borderRadius: '0.2rem',
    color: 'white',
    fontFamily: 'Roboto-Regular',
    fontSize: '16px',
    padding: '5px',
    display: 'block',
    margin: '0 auto',
    width: '120px'
}

export default CancelButton 