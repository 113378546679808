import * as React from 'react'
import PropTypes from 'prop-types'
import CancelButton from '../common/CancelButton';
import { connect } from 'react-redux';
import { toggleSidebarOptionRequest } from '../../../redux/actions/toggleSidebarOption';

class AboutComponent extends React.Component {


    closeSideBar = async () => {
        this.props.toggleSidebarOption('close');
    }

    render() {
        const href = `mailto:${this.props.mailConfig.aboutUsMailbox}`;
        return (
            <div>
                <h5>
                    Want to know more about SMaaS?
                </h5>
                <p>
                    The Service Management as a Service (SMaaS) Group is part of Capgemini’s Application Services Practice in North America.
                <br /> <br />
                    SMaaS offers Process Consulting & Transformation capabilities and an AI-driven Enterprise Analytics toolset to help customers transform IT & Business operations. The key focus areas for SMaaS include Digital Service Management, Business Analytics, and Lean IT.
                <br /> <br />
                    If you are interested in modernizing operations and using analytics to migrate from measurements and metrics to business-focused KPIs, please reach out to <a href={href}>{this.props.mailConfig.aboutUsMailbox}</a>
                </p>
                <div style={{ marginTop: '30px' }}>
                    <CancelButton handleClick={this.closeSideBar} isCentered={true} />
                </div>
            </div>
        );
    }
}

AboutComponent.propTypes = {
    mailConfig: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        sideBarOptionState: state.sideBarOptionState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSidebarOption: (mode) => {
            dispatch(toggleSidebarOptionRequest(mode))
        }
    }
}

export const About = connect(mapStateToProps, mapDispatchToProps)(AboutComponent);