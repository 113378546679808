import { httpClient } from '../../../common/httpClient';

const getAll = (engagementId) => httpClient({
    url: `api/users/engagement/${engagementId}`,
    method: "get",
});

const assignToEngagement = (engagementId, userEngagement) => httpClient({
    url: `api/users/engagement/${engagementId}/assign`,
    method: "post",
    data: userEngagement,
    headers: {
        "Content-Type": "application/json",
    }
})

const unassignFromEngagement = (engagementId, userEngagement) => httpClient({
    url: `api/users/engagement/${engagementId}/unassign`,
    method: "delete",
    data: userEngagement,
    headers: {
        "Content-Type": "application/json",
    }
})

const uploadUsersToEngagement = (data) => httpClient({
    url: `api/users/engagement/uploadUsers`,
    method: "post",
    data: data,
    headers: {
        "Content-Type": "multipart/form-data",
    }
})

export { getAll, assignToEngagement, unassignFromEngagement, uploadUsersToEngagement };

