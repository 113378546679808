import React, { Component } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import styles from '../EventTrackerComponent.module.css';

const permissionsToEngagementFilterId = 5;
class EventsListComponent extends Component {

    constructor(props) {

        super(props)
        this.messageGeneratorDictionary = {

            "EngagementCreated": {
                type: "create",
                messageGenerator: this.getEventMessageCreate
            },
            "EngagementUpdated": {
                type: "update",
                messageGenerator: this.getEventMessageUpdate
            },
            "EngagementDeleted": {
                type: "delete",
                messageGenerator: this.getEventMessageDeleted
            },
            "EngagementCopied": {
                type: "create",
                messageGenerator: this.getEventMessageCreate
            },
            "AssignedUserToEngagement": {
                type: "assign",
                messageGenerator: this.getEventMessageUserAssigment
            },
            "UnassignedUserFromEngagement": {
                type: "unassign",
                messageGenerator: this.getEventMessageUserAssigment
            },
            "CreatedAdminForEngagement": {
                type: "create",
                messageGenerator: this.getEventMessageEngagementAdmin
            },
            "DeletedAdminFromEngagement": {
                type: "delete",
                messageGenerator: this.getEventMessageEngagementAdmin
            },
            "DashboardCreated": {
                type: "create",
                messageGenerator: this.getEventMessageCreate
            },
            "DashboardUpdated": {
                type: "update",
                messageGenerator: this.getEventMessageUpdate
            },
            "DashboardDeleted": {
                type: "delete",
                messageGenerator: this.getEventMessageDeleted
            },
            "AssignedUserToDashboard": {
                type: "assign",
                messageGenerator: this.getEventMessageUserAssigment
            },
            "UnassignedUserFromDashboard": {
                type: "unassign",
                messageGenerator: this.getEventMessageUserAssigment
            },
            "ChartCreated": {
                type: "create",
                messageGenerator: this.getEventMessageCreate
            },
            "ChartUpdated": {
                type: "update",
                messageGenerator: this.getEventMessageUpdate
            },
            "ChartDeleted": {
                type: "delete",
                messageGenerator: this.getEventMessageDeleted
            },
            "CreatedSystemAdmin": {
                type: "create",
                messageGenerator: this.getEventMessageSystemAdmin
            },
            "DeletedSystemAdmin": {
                type: "delete",
                messageGenerator: this.getEventMessageSystemAdmin
            }
        }

        this.state = {
            selectedFilter: this.props.selectedFilter,
            entityType: this.props.entityType,
            parentEntityType: this.props.parentEntityType,
            events: this.props.events,
            selectedEventPayload: null
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            selectedFilter: nextProps.selectedFilter,
            entityType: nextProps.entityType,
            events: nextProps.events,
            parentEntityType: nextProps.parentEntityType
        })
    }

    openModalWithEvent = (selectedEvent) => {

        this.setState({
            showEventPayload: true,
            selectedEventPayload: selectedEvent.payload
        })
    }

    closeModalWithEvent = () => {

        this.setState({
            showEventPayload: false,
            selectedEventPayload: null
        })
    }

    getEventMessageUpdate = (type, eventEntity, entityType) => {

        let message = null;
        let changes = [];

        let payload = JSON.parse(eventEntity.payload);

        let oldValue = payload["Old" + entityType]
        let newValue = payload["New" + entityType]

        let keys = Object.keys(oldValue);
        keys.map((index) => {

            if (oldValue[index] !== newValue[index]) {

                changes.push({ key: index, old: oldValue[index].toString(), new: newValue[index].toString() })
            }
        });

        message = <div>
            Changed values: <br />
            {changes.map((el, index) => {
                return <div key={index}><b>[{el.key}]</b> from <span className="text-primary">[{el.old}]</span> to <span className="text-success">[{el.new}]</span></div>
            })}
        </div>

        return message
    }

    getEventMessageCreate = (type, eventEntity, entityType) => {

        let message = <div>
            Created <b><span className="text-success">[{eventEntity.referenceId} - {eventEntity.name}]</span></b> Entity
            </div>

        return message;
    }

    getEventMessageDeleted = (type, eventEntity, entityType) => {

        let message = <div>
            Deleted <b><span className="text-danger">[{eventEntity.referenceId} - {eventEntity.name}]</span></b> Entity
            </div>

        return message;
    }

    getEventMessageSystemAdmin = (type, eventEntity, entityType) => {

        let message = null;
        let payload = JSON.parse(eventEntity.payload);

        if (type == 'create') {
            message = <div>
                System Admin Role assigned to: <b><span className="text-success">[{payload.UserLogin}]</span></b>
            </div>
        }
        if (type == 'delete') {
            message = <div>
                System Admin Role unassigned from: <b><span className="text-danger">[{payload.UserLogin}]</span></b>
            </div>
        }

        return message;
    }

    getEventMessageEngagementAdmin = (type, eventEntity, entityType) => {

        let message = null;
        let payload = JSON.parse(eventEntity.payload);

        if (type == 'create') {
            message = <div>
                User <b><span className="text-success">[{payload.UserLogin}]</span></b> assigned to Engagement Admin Role
            </div>
        }
        if (type == 'delete') {
            message = <div>
                User <b><span className="text-danger">[{payload.UserLogin}]</span></b> unassigned from Engagement Admin Role
            </div>
        }

        return message;
    }

    getEventMessageUserAssigment = (type, eventEntity, entityType) => {

        let message = null;
        let payload = JSON.parse(eventEntity.payload);

        let entityIdAccessor = eventEntity.referenceEntityType + "Id";

        if (type == 'assign') {
            message = <div>
                User <b><span className="text-success">[{payload.UserLogin}]</span></b> assigned to: <b><span className="text-info">{eventEntity.referenceEntityType} - {payload[entityIdAccessor]}</span></b>
            </div>
        }
        if (type == 'unassign') {
            message = <div>
                User <b><span className="text-warning">[{payload.UserLogin}]</span></b> unassigned from: <b><span className="text-info">{eventEntity.referenceEntityType} - {payload[entityIdAccessor]}</span></b>
            </div>
        }

        return message;
    }

    renderEventMessage = (eventEntity) => {

        try {

            let generator = this.messageGeneratorDictionary[eventEntity.eventType]
            return generator.messageGenerator(generator.type, eventEntity, this.state.entityType);
        }
        catch (ex) {
            return null;
        }
    }

    renderTableHeader() {

        let entityHeader = this.state.entityType !== null
            ?
            <th>{this.state.entityType + " Name"}</th>
            :
            null

        let parentHeader = this.state.parentEntityType !== null
            ?
            <th>{this.state.parentEntityType + " Name"} </th>
            :
            null

        if (this.state.selectedFilter == permissionsToEngagementFilterId) {
            entityHeader = <th>Engagement</th>
            parentHeader = <th>Dashboard</th>
        }

        return (
            <tr>
                <th>#</th>
                {entityHeader}
                {parentHeader}
                <th>Event type</th>
                <th>Raised By</th>
                <th>Raised At</th>
                <th>Event Overview</th>
                <th></th>
            </tr>
        )
    }

    renderTableBody() {

        return this.state.events.map((el, index) => {

            const utc = moment.utc(el.raisedAt);
            const localDateTime = moment(utc).local().format("DD-MM-YYYY HH:mm:ss");

            let entityProperty = this.state.entityType !== null
                ?
                <td>
                    <NavLink to={`/admin/${this.state.entityType.toLowerCase() + "s"}/${el.referenceId}`}>
                        <b>{el.referenceId}</b> - {el.name}
                    </NavLink>
                </td>
                :
                null

            let parentProperty = this.state.parentEntityType !== null
                ?
                <td>
                    <NavLink to={`/admin/${this.state.parentEntityType.toLowerCase() + "s"}/${el.parentEntityId}`}>
                        <b>{el.parentEntityId}</b> - {el.parentEntityName}
                    </NavLink>
                </td>
                :
                null;

            if (this.state.selectedFilter == permissionsToEngagementFilterId) {
                entityProperty = el.parentEntityId == null ?
                    <td>
                        <NavLink to={`/admin/engagements/${el.referenceId}`}>
                            <b>{el.referenceId}</b> - {el.name}
                        </NavLink>
                    </td>
                    :
                    <td>
                        <NavLink to={`/admin/engagements/${el.parentEntityId}`}>
                            <b>{el.parentEntityId}</b> - {el.parentEntityName}
                        </NavLink>
                    </td>

                parentProperty = el.parentEntityId != null ?
                    <td>
                        <NavLink to={`/admin/dashboards/${el.referenceId}`}>
                            <b>{el.referenceId}</b> - {el.name}
                        </NavLink>
                    </td>
                    :
                    <td></td>;
            }

            return <tr key={el.id}>
                <td>{index + 1}</td>
                {entityProperty}
                {parentProperty}
                <td>{el.eventType}</td>
                <td>{el.raisedBy}</td>
                <td>
                    {localDateTime}
                </td>
                <td>
                    {this.renderEventMessage(el)}
                </td>
                <td>
                    <div className={styles["event-tracker-icons-container"]}>
                        <div onClick={() => this.openModalWithEvent(el)}>
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                    </div>
                </td>
            </tr>
        })
    }

    renderModal() {


        let eventPayload = this.state.selectedEventPayload !== null
            ?
            <pre>
                {JSON.stringify(JSON.parse(this.state.selectedEventPayload), 0, 2)}
            </pre>
            :
            ""

        return (
            <Modal show={this.state.showEventPayload} onHide={this.closeModalWithEvent}>
                <Modal.Header>
                    <Modal.Title>Event Overview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {eventPayload}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.closeModalWithEvent}>
                        Close
                    </Button>
                </Modal.Footer>

            </Modal>
        )

    }

    render() {

        return (
            <div>

                <br />
                <Table id={styles["event-tracker-table"]}
                    bordered hover striped style={styles.eventTrackerTable}>
                    <thead>
                        {this.renderTableHeader()}
                    </thead>
                    <tbody>
                        {this.renderTableBody()}
                    </tbody>
                </Table>
                {this.renderModal()}
            </div>
        )
    }
}

export { EventsListComponent }