import React, { Component } from 'react';
import { getAll, add, remove } from "../../services/systemAdministrators";
import { Button, Table, Form } from 'react-bootstrap';
import { faSave, faPlusCircle, faWindowClose, faTrashAlt } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlockUIContainer } from '../../../../components/BlockUIContainer';
import { toast } from "react-toastify";
import moment from 'moment';


class SystemAdministratorListComponent extends Component {

    constructor(props) {

        super(props);
        this.state = {
            blocking: false,
            isAdding: false,
            newItem: {
                user: '',
            },
            users: [],
            pageOfItems: []
        };
    }

    async componentDidMount() {

        this.setState({ blocking: true });
        try {

            let result = await getAll(this.props.dashboardId);
            this.setState({
                users: result.data,
                blocking: false
            })
        }
        catch (ex) { }
    }

    addSystemAdmin = async () => {

        let newItem = this.state.newItem;

        let userAlreadyExists = this.state.users.some(x => x.user.toUpperCase() == newItem.user.toUpperCase());
        if (userAlreadyExists) {
            toast.warn("User already exists");
            return;
        }

        try {

            await add(newItem.user);
            const users = [...this.state.users, newItem];
            this.setState({
                blocking: false,
                isAdding: false,
                users: users,
                newItem: { user: '' }
            })

            toast.info("System admin created successfully.");
        }
        catch (ex) {
            this.setState({ blocking: false });
        }
    }

    removeSystemAdmin = async (user) => {

        this.setState({ blocking: true });

        const toDelete = this.state.users.find(x => x.user === user);
        if (toDelete === null)
            return;

        try {
            await remove(toDelete.user);
            const newData = this.state.users.filter(x => x.user != user);

            this.setState({
                users: newData,
                blocking: false
            })

            toast.info("System admin removed successfully.");
        }
        catch (ex) {

            this.setState({ blocking: false });
        }
    }

    newItemOnChange = (user) => {

        let newItem = this.state.newItem;
        newItem.user = user.toUpperCase();
        this.setState({ newItem: newItem });
    }

    renderAddNewItem() {

        if (!this.state.isAdding) {
            return null;
        }

        return (
            <tr>
                <td></td>
                <td>
                    <Form.Control type="text" placeholder="Type a username..." size="sm"
                        onChange={e => { this.newItemOnChange(e.target.value); }}
                        value={this.state.newItem.user}>
                    </Form.Control>
                </td>
                <td></td>
                <td>
                    <Button size="sm" onClick={() => this.addSystemAdmin()} variant="outline-success">
                        <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button size="sm" onClick={() => this.setState({
                        isAdding: false,
                        newItem: {
                            user: '',
                        },
                    })} variant="outline-primary">
                        <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                </td>
            </tr>
        );
    }

    checkDefaultDate(createdDate) {
        return createdDate !== "0001-01-01T00:00:00" ? moment.utc(createdDate).local().format("DD-MM-YYYY HH:mm:ss") : "";
    }

    renderBodyTable() {
        return (
            this.state.users.map((listValue, index) => {
                let createdDate = this.checkDefaultDate(listValue.createdDate);
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{listValue.user}</td>
                        <td>{createdDate}</td>
                        <td>
                            <Button size="sm" disabled={listValue.user.toUpperCase() == this.props.user.toUpperCase()} onClick={() => this.removeSystemAdmin(listValue.user)} variant="outline-danger">
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </td>
                    </tr>
                );
            })
        );
    }

    render() {

        return (
            <div>
                <h4 style={{ color: 'gray', fontSize: 18 }}>System administrators</h4>
                <BlockUIContainer blocking={this.state.blocking}>
                    <br />
                    <div class="d-flex">
                        <div>
                            <Button variant="outline-primary" size="sm" onClick={() => this.setState({ isAdding: true })}>
                                <FontAwesomeIcon icon={faPlusCircle} /> Add new system admin
                            </Button>
                        </div>
                        <div class="ml-auto">
                            <p>Total Count: <b>{this.state.users.length}</b></p>
                        </div>
                    </div>
                    <br />
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>User</th>
                                <th>Created date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderAddNewItem()}
                            {this.renderBodyTable()}
                        </tbody>
                    </Table>
                </BlockUIContainer>
            </div>
        );
    }
}

export { SystemAdministratorListComponent };
