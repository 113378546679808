import { SEARCH_ENGAGEMENTS_REQUEST, SEARCH_DASHBOARDS_REQUEST, SEARCH_CHARTS_REQUEST } from '../constants/ActionTypes';

const initialState = {
    searchText: ''
}

const searchEngagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_ENGAGEMENTS_REQUEST:
            return { ...state, searchText: action.searchText }
        default:
            return state
    }
}


const searchDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_DASHBOARDS_REQUEST:
            return { ...state, searchText: action.searchText }
        default:
            return state
    }
}

const searchChartReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_CHARTS_REQUEST:
            return { ...state, searchText: action.searchText }
        default:
            return state
    }
}

export { searchEngagementReducer, searchDashboardReducer, searchChartReducer }