import React, { Component } from 'react';
import Table from './Table';

class ChartUsefulStatisticsTableComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            period: 'last-day',
        };
    }

    onChange = (value) => {
        this.setState({ period: value });
    }

    render() {
        return (
            <div style={{ marginBottom: 20 }}>
                <h4 style={{ color: 'gray', fontSize: 18 }}>Chart Useful/Not Useful Statistics</h4>
                <Table />
            </div>);
    }
}

export default ChartUsefulStatisticsTableComponent;