import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

class PageNotFound extends Component {
  componentDidMount() { }

  render() {
    return (
      <div>

          <Alert variant="light">
            <Alert.Heading>Not found</Alert.Heading>
            <p>
            The page you are trying to reach is unavailable (404).
            </p>
          </Alert>
      </div>
    );
  }
}
export { PageNotFound }

