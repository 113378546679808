export const SEARCH_ENGAGEMENTS_REQUEST = 'SEARCH_ENGAGEMENTS_REQUEST';
export const SEARCH_DASHBOARDS_REQUEST = 'SEARCH_DASHBOARDS_REQUEST';
export const SEARCH_CHARTS_REQUEST = 'SEARCH_CHARTS_REQUEST';

export const SELECT_DASHBOARD_REQUEST = "SELECT_DASHBOARD_REQUEST";
export const SELECT_PREVIEW_MODE_REQUEST = "SELECT_PREVIEW_MODE_REQUEST";
export const SELECT_LAYOUT_MODE_REQUEST = "SELECT_LAYOUT_MODE_REQUEST";
export const SELECT_DASHBOARD_TAGS = "SELECT_DASHBOARD_TAGS";
export const SELECT_CHART_REQUEST = 'SELECT_CHART_REQUEST';
export const BREADCRUMBS_REQEST = 'BREADCRUMBS_REQEST';

export const SWITCH_SLIDESHOW_MODE = "SWITCH_SLIDESHOW_MODE"
export const SHOW_NOTIFICATIONS_REQUEST = "SHOW_NOTIFICATIONS_REQUEST";

export const TOGGLE_SIDEBAR_OPTION = "TOGGLE_SIDEBAR_OPTION"; 

export const USER_SIGNED_IN = "USER_SIGNED_IN";

export const SELECT_TOGGLE_MODE_REQUEST = "SELECT_TOGGLE_MODE_REQUEST";

export const SET_ENGEGEMENT_ICON = "SET_ENGEGEMENT_ICON";
