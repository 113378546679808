import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from 'react-router-dom';
import Layout from './layout/Layout';
import { PageNotFound } from './pages/PageNotFound';
import { NoAccess } from './pages/NoAccess';
import { AdminPanel } from './modules/admin/AdminPanelComponent';
import { Portal } from './modules/portal/PortalComponent';
import { Home } from './modules/home/Home';
import { connect } from 'react-redux';
import webAppConfig from './config';
import authenticationTypes from './common/const/authenticationTypes';
import { tokenExists } from './auth/customAuthService';
import { authenticateUserRequest } from './redux/actions/auth';
import AccountComponent from './modules/account/AccountComponent';
import { AuthCallback } from './modules/auth/AuthCallbackComponent';
import { CAMAuthCallback } from './modules/auth/CAMAuthCallbackComponent';
import { OAuthService } from './auth/OAuthService';
import { CAMAuthService } from './auth/CAMAuthService';

class App extends Component {
    static displayName = App.name;

    constructor() {
        super();
        if(webAppConfig.authenticationType === authenticationTypes.CAMAuth){
            this.authService = new CAMAuthService();
        } else  {
            this.authService = new OAuthService();
        }
    }

    async componentDidMount() {

        console.log('calling did mount app component');

        this.isInternetExplorer();
    }

    isInternetExplorer() {
        setTimeout(() => {
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");

            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                alert("It seems like you are using Internet Explorer browser which is not supported. We recommend to use Chrome or Firefox.");
            }
        }, 3000);
    }

    renderComponent = (component) => {
        let resultComponent = component
        if (webAppConfig.authenticationType === authenticationTypes.Custom && !tokenExists()) {
            return resultComponent;
        }

        if (webAppConfig.authenticationType === authenticationTypes.CapgeminiSso) {
            this.props.markAsAuthenticated();
            return resultComponent;
        }

        try {
            if (this.authService.isAuthenticated()) {
                this.props.markAsAuthenticated();
            } else {
                this.authService.login();

                resultComponent = <div><p>Redirecting to the authentication service...</p></div>
            }
        } catch (err) {
            console.log(err);
        }
        return resultComponent;
    }

    render() {

        return (
            <Router>
                <Switch>
                    <Route exact path='/' render={props => this.renderComponent(<Layout><Home {...props} /></Layout>)} />
                    <Route path='/engagements' render={props => <Layout><Portal {...props} /></Layout>} />
                    <Route path='/admin' render={props => <Layout><AdminPanel {...props} /></Layout>} />
                    {webAppConfig.authenticationType === authenticationTypes.Custom &&
                        <Route path='/account' component={AccountComponent} />
                    }
                    <Route path='/auth-callback' render={props => <AuthCallback {...props} />} />
                    <Route path='/CAMAuth-callback' render={props => <CAMAuthCallback {...props} />} />
                    <Route path='/unauthorized' component={NoAccess} />
                    <Route component={PageNotFound} />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        markAsAuthenticated: () => {
            dispatch(authenticateUserRequest(true))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
