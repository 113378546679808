import { httpClient } from './httpClient';

const getAll = (sinceDate) => httpClient({
    url: 'api/notifications/all',
    method: 'get',
    params: {
        sinceUtc: sinceDate.toISOString()
    }

})

export { getAll }